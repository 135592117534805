import React from 'react';
import { createComponent, PropTypes } from 'wayin-react';
import moment from 'moment';

import { ConfigService } from 'services';
import assetRefService from 'helpers/asset';

import {
  positions,
  sizes,
  widths,
  documentTypes as DOCUMENT_TYPES,
  icons,
  assetServices,
  uploadAssetTypes,
} from 'enums';

import { UploaderMessages, prettyFileSize } from './file-utils';

import Button from 'components/core/button';
import Layout from 'components/core/layout';
import Icon from 'components/core/icon';
import ContentItem from 'components/abstractions/content-item';
import LibrarySelect from 'components/abstractions/library-select';

import AssetPreview from './asset-preview';
import AssetOverview from './asset-overview';
import ItemThumbnail from './item-thumbnail';

/**
 * displays thumbnail and meta info of an existing(already uploaded) file or video url
 */
const FileOverview = createComponent({
  displayName: 'FileOverview',

  state: {
    previewModalVisible: {
      value: false,
      updater(setPreviewModalVisible) {
        return {
          showPreviewModal: () => setPreviewModalVisible(true),
          hidePreviewModal: () => setPreviewModalVisible(false),
        };
      },
    },
  },
  propTypes: {
    file: PropTypes.object.isRequired,
    onReplaceVideo: PropTypes.func.isRequired,
    onRemoveFile: PropTypes.func.isRequired,

    //some props for the library select window
    assetCategories: PropTypes.arrayOf(PropTypes.shape({ text: PropTypes.string, value: PropTypes.string })).isRequired,
    showSelectButton: PropTypes.bool.isRequired,
    onSelectFromLibrary: PropTypes.func.isRequired,
    libraryRef: PropTypes.string.isRequired,
    containerRef: PropTypes.string.isRequired,
    documentTypes: PropTypes.array.isRequired,
    displayFilterScope: PropTypes.bool.isRequired,
  },

  render(p) {
    const ConsoleKitConfig = ConfigService.values();
    const { file, onReplaceVideo, onRemoveFile, displayFilterScope } = p,
      assetMeta = file.assetMeta,
      assetRefObj = assetRefService.deserialize(file.assetRef),
      isVideoAsset =
        assetRefObj.service === assetServices.WEB_URL && assetRefObj.assetType === uploadAssetTypes.VIDEO_URL;

    let previewHeader = '',
      previewByline = '',
      fileDetails = '',
      metaHeader = '',
      metaByline = '',
      metaResolution = '';

    if (isVideoAsset) {
      previewHeader = metaHeader = UploaderMessages.VIDEO_HEADLINE;
      previewByline = metaByline = assetRefObj.location;
    } else {
      const lastUpdated =
          assetMeta.lastUpdated && moment(assetMeta.lastUpdated).isBefore(moment() - 1000)
            ? moment(assetMeta.lastUpdated).fromNow()
            : 'a few seconds ago',
        modifiedBy =
          assetMeta.modifiedBy && assetMeta.modifiedBy.name
            ? 'Modified by ' + assetMeta.modifiedBy.name + ' ' + lastUpdated
            : '';

      const fileDetailsArr = [
        assetMeta.classRef && assetMeta.classRef.label,
        assetMeta.mimeType && assetMeta.mimeType.label,
        assetMeta.fileSize && prettyFileSize(assetMeta.fileSize),
        assetMeta.fileName,
        assetMeta.fileName,
        modifiedBy,
      ];
      fileDetails = _.filter(fileDetailsArr, (v) => !!v).join(' | ');
      previewHeader = assetMeta.name;
      previewByline = fileDetails;
      metaHeader = assetMeta.name || '';
      metaByline = fileDetails;
      metaResolution = assetMeta.height ? assetMeta.width + 'x' + assetMeta.height : '';
    }

    return (
      <div className="upload-view file-overview">
        {/* Asset Preview in Modal */}
        <AssetPreview
          previewModalVisible={p.previewModalVisible}
          hidePreviewModal={p.hidePreviewModal}
          headerText={previewHeader}
          bylineText={previewByline}
          assetPath={`${assetMeta.assetPath}?rnd=${assetMeta.lastUpdated}`}
          assetRefObj={assetRefObj}
        />

        <Choose>
          {/* image thumbnail and meta info overview */}
          <When condition={!isVideoAsset}>
            <AssetOverview
              thumbnailContent={
                <ItemThumbnail
                  metaWidth={(assetMeta && assetMeta.width) || (file.resolution && file.resolution.width) || 0}
                  metaHeight={(assetMeta && assetMeta.height) || (file.resolution && file.resolution.height) || 0}
                  imageSrc={`${assetMeta.thumbnailAssetPath}?rnd=${assetMeta.lastUpdated}`}
                  tooltip={assetMeta.name}
                />
              }
              metaContent={<ContentItem header={metaHeader} byline={metaByline} meta={metaResolution} />}
            />
          </When>

          {/* video url overview */}
          <When condition={isVideoAsset}>
            <AssetOverview
              thumbnailContent={
                <div className="video-url-icon">
                  <Icon name={icons.VIDEO} size={sizes.X3} />
                </div>
              }
              metaContent={<ContentItem header={metaHeader} byline={metaByline} meta={metaResolution} />}
            />
          </When>
        </Choose>

        {/* asset actions, shows when hovering over the 'file overview', uses css visibility */}
        <div className="file-actions-wrapper">
          <Layout
            isFluidHeight
            columns={widths.ONE}
            padding={null}
            columnStyles={{
              verticalAlign: positions.verticalAlign.MIDDLE,
            }}
          >
            <Button.Group>
              {/* replace asset */}
              <Choose>
                {/* replacing an exising video url should go directly to the video url input screen */}
                <When condition={isVideoAsset}>
                  <Button.Primary text={UploaderMessages.ACTION_REPLACE} onClick={onReplaceVideo} />
                </When>
                {/* the asset being replaced is an image, will only show the 'replace' when the 'library select button' is configured to show */}
                {/* LibrarySelectWindow that will output the trigger button here and will manage the modal closed/opened state internally */}
                <When condition={p.showSelectButton && !isVideoAsset}>
                  <LibrarySelect.Modal
                    onClick={_.noop}
                    trigger={<Button.Primary text={UploaderMessages.ACTION_REPLACE} />}
                    onAssetSelected={p.onSelectFromLibrary}
                    assetCategories={p.assetCategories}
                    libraryRef={p.libraryRef}
                    containerRef={p.containerRef}
                    documentTypes={p.documentTypes}
                    CKConfig={ConsoleKitConfig}
                    displayFilterScope={displayFilterScope}
                  />
                </When>
              </Choose>

              {/* remove asset */}
              <Button.Primary text={UploaderMessages.ACTION_REMOVE} onClick={onRemoveFile} />

              {/* preview image or video url */}
              <If condition={file.docType === DOCUMENT_TYPES.IMAGE || isVideoAsset}>
                <Button.Primary text={UploaderMessages.ACTION_PREVIEW} onClick={p.showPreviewModal} />
              </If>
            </Button.Group>
          </Layout>
        </div>
      </div>
    );
  },
});

export default FileOverview;
