import React from 'react';
import { createComponent, PropTypes } from 'wayin-react';

import { positions } from 'enums';

import ContentItem from 'components/abstractions/content-item';
import ProgressBar from 'components/core/progress-bar';

import AssetOverviewLayout from './asset-overview.js';
import ItemThumbnail from './item-thumbnail.js';

/**
 * displays the files upload 'progress' information
 */
const FileUploadProgress = createComponent({
  displayName: 'FileUploadProgress',

  propTypes: {
    file: PropTypes.object.isRequired,
  },

  render(props) {
    const { file } = props;
    return (
      <div className="upload-view">
        <AssetOverviewLayout
          thumbnailContent={
            <ItemThumbnail
              metaWidth={(file.assetMeta && file.assetMeta.width) || (file.resolution && file.resolution.width) || 0}
              metaHeight={(file.assetMeta && file.assetMeta.height) || (file.resolution && file.resolution.height) || 0}
              imageSrc={file.imageSrc}
              tooltip={file.fileName}
            />
          }
          metaContent={<ContentItem header={file.fileName} byline={file.fileDetails} meta={file.resolutionLabel} />}
        />

        <div className="progress-bar-holder">
          <ProgressBar percentComplete={file.uploadProgress} attached={positions.BOTTOM} />
        </div>
      </div>
    );
  },
});

export default FileUploadProgress;
