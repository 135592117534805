let positions = {
  LEFT:         'left',
  RIGHT:        'right',
  BOTTOM:       'bottom',
  TOP:          'top',
  TOP_RIGHT:    'top right',
  TOP_LEFT:     'top left',
  BOTTOM_LEFT:  'bottom left',
  BOTTOM_RIGHT: 'bottom right',
};
positions.ALL = _.values(positions);

//used only in the toolbar header arrangement - left or center
positions.CENTER = 'center';

positions.WITHOUT_SIDES = _.values(_.omit(positions, ['LEFT', 'RIGHT', 'ALL']));

positions.LEFT_RIGHT = 'left right';
positions.LRTB = [positions.LEFT, positions.RIGHT, positions.TOP, positions.BOTTOM];
positions.LR = [positions.LEFT, positions.RIGHT];
positions.LRLR = [positions.LEFT, positions.RIGHT, positions.LEFT_RIGHT];

positions.pointing = {
  ABOVE: 'above',
  BELOW: 'below',
};

positions.pointing.ALL = [...positions.LR, ..._.values(positions.pointing)];

positions.verticalAlign = {
  TOP:    'top',
  MIDDLE: 'middle',
  BOTTOM: 'bottom',
};
positions.alignColumns = {
  // TOP   : 'top',
  // MIDDLE: 'middle',
  // BOTTOM: 'bottom',
  // FLEX  : 'flex',
  ...positions.verticalAlign,
  FLEX: 'flex',
};

positions.inSquare = {
  TOP_LEFT:      'top left',
  TOP_CENTER:    'top center',
  TOP_RIGHT:     'top right',
  MIDDLE_LEFT:   'middle left',
  CENTERED:      'middle center',
  MIDDLE_RIGHT:  'middle right',
  BOTTOM_LEFT:   'bottom left',
  BOTTOM_CENTER: 'bottom center',
  BOTTOM_RIGHT:  'bottom right',
};
positions.inSquare.ALL = _.values(positions.inSquare);

positions.alignColumns.ALL = _.values(positions.alignColumns);
positions.verticalAlign.ALL = _.values(positions.verticalAlign);

export default positions;
