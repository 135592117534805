import React from 'react';
import { createComponent, PropTypes } from 'wayin-react';

import { positions, sizes, colors, widths, icons } from 'enums';

import { UploaderMessages, ErrorHandlingOptions } from './file-utils';

import Button from 'components/core/button';
import Layout from 'components/core/layout';

import AssetOverviewLayout from './asset-overview';
import ItemThumbnail from './item-thumbnail';

/**
 * displays the files upload error handling options
 */
const UploadErrorHandling = createComponent({
  displayName: 'UploadErrorHandling',

  propTypes: {
    file:              PropTypes.object.isRequired,
    handleUploadError: PropTypes.func.isRequired,
    hasFluidButtons:   PropTypes.bool,
    errorButtonLayout: PropTypes.oneOf(['horizontal', 'vertical']),
  },

  defaultProps: {
    hasFluidButtons:   false,
    errorButtonLayout: 'horizontal',
  },

  render(props) {
    const { file, handleUploadError } = props;
    return (
      <div className="upload-view upload-error--view">
        <AssetOverviewLayout
          thumbnailContent={
            <ItemThumbnail
              metaWidth={(file.assetMeta && file.assetMeta.width) || (file.resolution && file.resolution.width) || 0}
              metaHeight={(file.assetMeta && file.assetMeta.height) || (file.resolution && file.resolution.height) || 0}
              imageSrc={file.imageSrc}
              tooltip={file.fileName}
            />
          }
          metaContent={
            <div className="upload-error-info">
              <div className="file-error-title">{UploaderMessages.GENERIC_UPLOAD_ERROR_HEADER}</div>
              <div className="file-error-message">
                {(file.uploadResponse && file.uploadResponse.errorMessage) ||
                  (file.uploadResponse && file.uploadResponse.errorCode) ||
                  file.uploadResponse ||
                  'Unknown Error.'}
              </div>
            </div>
          }
        />

        <div className="file-actions-wrapper">
          <Layout
            isFluidHeight
            columns={widths.ONE}
            padding={null}
            columnStyles={{
              verticalAlign: positions.verticalAlign.MIDDLE,
            }}
          >
            <Button.Group isFluid={props.hasFluidButtons} layout={props.errorButtonLayout}>
              <If condition={file.errorHandlingOptions.cancel === true}>
                <Button.Primary
                  text={UploaderMessages.ERROR_HANDLING_BUTTON_REMOVE}
                  size={sizes.X1}
                  color={colors.WHITE}
                  value={ErrorHandlingOptions.REMOVE}
                  onClick={handleUploadError}
                  icon={icons.CANCEL}
                  isFluid={props.hasFluidButtons}
                />
              </If>
              <If condition={file.errorHandlingOptions.continue === true}>
                <Button.Primary
                  text={UploaderMessages.ERROR_HANDLING_BUTTON_CONTINUE_ANYWAY}
                  size={sizes.X1}
                  color={colors.WHITE}
                  value={ErrorHandlingOptions.CONTINUE_ANYWAY}
                  onClick={handleUploadError}
                  icon={icons.PUBLISH}
                  isFluid={props.hasFluidButtons}
                />
              </If>
              <If condition={file.errorHandlingOptions.retry === true}>
                <Button.Primary
                  text={UploaderMessages.ERROR_HANDLING_BUTTON_RETRY}
                  size={sizes.X1}
                  color={colors.WHITE}
                  value={ErrorHandlingOptions.RETRY}
                  onClick={handleUploadError}
                  icon={icons.PUBLISH}
                  isFluid={props.hasFluidButtons}
                />
              </If>
              <If condition={file.errorHandlingOptions.overwrite === true}>
                <Button.Primary
                  text={UploaderMessages.ERROR_HANDLING_BUTTON_OVERWRITE}
                  size={sizes.X1}
                  color={colors.WHITE}
                  value={ErrorHandlingOptions.OVERWRITE}
                  onClick={handleUploadError}
                  icon={icons.EDIT}
                  isFluid={props.hasFluidButtons}
                />
              </If>
            </Button.Group>
          </Layout>
        </div>
      </div>
    );
  },
});

export default UploadErrorHandling;
