import React from "react";
import { createComponent, PropTypes } from "wayin-react";
import { compose } from "recompose";
import { sizes, icons, colors } from "enums";
import { toUIProps } from "helpers";
import { removeProps, mapHandlers, whitelistStyles } from "components/core/hoc";
import Isvg from "react-inlinesvg";
import classnames from "classnames";

import { tooltipify } from "components/core/tooltip";

import { Icon as UIIcon } from "semantic-ui-react";

const ADDED_PROPS = [
  "handleMouseOut",
  "handleMouseOver",
  "handleSvgError",
  "hoverName",
  "id",
  "isCircular",
  "isCorner",
  "isDisabled",
  "isCompact",
  "isHovered",
  "isInverted",
  "isLoading",
  "isSolid",
  "isSvgError",
  "setIsHovered",
  "setIsSvgError",
  "src",
  "value",
];
const MODIFIED_PROPS = ["name", "size", "color", "style"];
const UNSUPPORTED_PROPS = [
  "as",
  "bordered",
  "className",
  "circular",
  "corner",
  "disabled",
  "fitted",
  "flipped",
  "inverted",
  "link",
  "loading",
  "rotated",
];

const propMap = {
  isCompact: "fitted",
  isCorner: "corner",
  isDisabled: "disabled",
  isInverted: "inverted",
  isLoading: "loading",
};

/*
  TODO
  - Do SVGs need support for their "natural" size?
  - Add color support
  - Verify what to display if SVG error... Kat says show an empty box
 */
const Icon = createComponent({
  displayName: "Icon",
  propTypes: {
    name: PropTypes.oneOf(icons.ALL),
    hoverName: PropTypes.oneOf(icons.ALL),
    src: PropTypes.string,
    onClick: PropTypes.func,
    size: PropTypes.oneOfType([PropTypes.oneOf(sizes.ALL), PropTypes.string]),
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    color: PropTypes.string,

    isSolid: PropTypes.bool,
    isCircular: PropTypes.bool,
    isCompact: PropTypes.bool,
    isCorner: PropTypes.bool,
    isDisabled: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
    isLoading: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  },
  defaultProps: {
    name: undefined,
    hoverName: undefined,
    src: undefined,
    onClick: undefined,
    size: undefined,
    value: undefined,
    color: undefined,

    isCircular: undefined,
    isCorner: undefined,
    isDisabled: undefined,
    isCompact: undefined,
    isLoading: undefined,
    isSolid: undefined,
  },
  handlers: {
    handleSvgError: (p) => ({
      isHttpError,
      isSupportedBrowser,
      isConfigurationError,
      statusCode,
    }) => {
      p.setIsSvgError(true);
    },
    handleMouseOver: (p) => (e) => {
      p.setIsHovered(true);
    },
    handleMouseOut: (p) => (e) => {
      p.setIsHovered(false);
    },
  },
  state: {
    isHovered: false,
    isSvgError: false,
  },
  contextTypes: {
    isInverted: PropTypes.bool,
  },
  render(p) {
    const props = _.defaults(p, defaultProps);
    const UIProps = toUIProps({
      props,
      propMap,
      UNSUPPORTED_PROPS,
      ADDED_PROPS,
      MODIFIED_PROPS,
    });

    logger.warn(
      "isCompact has no effect when isCircular is true.",
      props.isCompact && props.isCircular
    );

    logger.warn(
      "isCompact has no effect with an SVG",
      props.src !== undefined && props.isCompact
    );

    logger.warn(
      'Mutually Exclusive Violation: "name" and "src" cannot both be defined. Either pick an icon name or provide an SVG src.',
      props.name !== undefined && props.src !== undefined
    );

    logger.warn(
      "name must be defined for hoverName to be used.",
      props.hoverName !== undefined && props.name === undefined
    );

    logger.warn(
      "color is not currently supported for SVGs",
      props.src !== undefined && props.color !== undefined
    );

    if (
      props.src !== undefined &&
      !props.isSvgError &&
      !/.svg$/.test(props.src)
    ) {
      logger.warn(`Only ".svg" file types are supported: ${props.src}`);
      props.setIsSvgError(true);
    }

    /*
     The way Semantic React uses the "name" prop to create the className does not match the already established
     naming conventions in coreapp. In order to remain backwards compatible we build the className ourselves and
     do not pass the "name" prop to Semantic React.

     If there is an SVG error we want to show something instead of nothing as this will have less of a negative impact
      on the UI and keep click behavior available to the user. We default to the BUG icon.
     */

    // remap name if hoverName is specified and isHovered is true
    let hasHover = false;
    if (p.isHovered && !p.isDisabled) {
      if (props.name !== undefined && props.hoverName !== undefined) {
        props.name = props.hoverName;
        hasHover = true;
      }
      // Doesn't seem to work with the SVG component used
    }
    const isRelativeSize = _.includes(sizes.ALL, props.size);
    UIProps.className = classnames({
      [`icon-${icons.BUG}`]: props.isSvgError,
      [`icon-${reverseAliasMap[props.name] || props.name}`]: !props.isSvgError,
      [props.size]: isRelativeSize,
      [`color-${props.color}`]: !isCustomColor(props.color),
      hover: hasHover && p.isHovered,
      solid: props.isSolid,
      "is-circular": props.isCircular, // using custom classname rather than passing as a prop to UIIcon to avoid having to override
    });

    const svgClassName = classnames({
      "ck-icon__svg": true,
      [props.size]: isRelativeSize,
      "is-circular": props.isCircular,
      fitted: props.isCompact,
      disabled: props.isDisabled,
      loading: props.isLoading,
      inverted: props.isInverted,
      solid: props.isSolid,
    });

    if (!props.isDisabled && props.onClick !== undefined) {
      UIProps.link = true;
    }
    if (props.isDisabled){
      delete UIProps.onClick;
    }

    UIProps.style = !isCustomColor(props.color)
      ? undefined
      : { borderColor: props.color };

    if (!props.isSolid && isCustomColor(props.color)) {
      _.set(UIProps, "style.color", props.color);
    }
    if (props.isSolid && isCustomColor(props.color)) {
      _.set(UIProps, "style.backgroundColor", props.color);
    }

    const style = {
      ...p.style,
      ...(props.name && !isRelativeSize && { fontSize: p.size }),
    };

    return (
      <div
        className="ck-icon"
        style={style}
        onMouseOver={p.handleMouseOver}
        onMouseOut={p.handleMouseOut}
      >
        <Choose>
          <When condition={props.name !== undefined}>
            <UIIcon {...UIProps} />
          </When>
          <When condition={props.isSvgError}>
            <UIIcon {...UIProps} />
          </When>
          <Otherwise>
            <div className={svgClassName}>
              <Isvg
                src={props.src}
                cacheRequests
                onError={props.handleSvgError}
              />
            </div>
          </Otherwise>
        </Choose>
      </div>
    );
  },
});

function isCustomColor(color) {
  return !_.includes(colors.CORE, color);
}

const reverseAliasMap = {
  signup: "form",
};

const defaultProps = {
  name: undefined,
  src: undefined,
  onClick: undefined,
  size: sizes.X3,
  value: undefined,

  isCircular: false,
  isCorner: false,
  isDisabled: false,
  isCompact: false,
  isLoading: false,
  isSolid: false,
};

export const PrivateIcon = compose(
  tooltipify({ isInline: true }),
  mapHandlers({ click: [] })
)(Icon);

export default compose(
  removeProps(["isCorner", "isLoading"]),
  whitelistStyles()
)(PrivateIcon);
