import React from 'react';
import { toUIProps, cloneChildrenWithDefaultProps } from 'helpers';
import { whitelistStyles } from 'components/core/hoc';

import { Card as UICard } from 'semantic-ui-react';

import { createComponent, PropTypes } from 'wayin-react';
import { font, sizes, textOverflows } from 'enums';

const UNSUPPORTED_PROPS = [
  'as',
  'className',
  'doubling',
  'stackable',
  'items', // closing these off as they're never really an ideal choice for card
];

const ADDED_PROPS = ['isDoubling', 'isStackable', 'headerStyles', 'descriptionStyles', 'isInverted'];

const MODIFIED_PROPS = [];

const propMap = {
  isDoubling:  'doubling',
  isStackable: 'stackable',
};

const CardGroup = createComponent({
  displayName: 'CardGroup',
  propTypes:   {
    children:    PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.element), PropTypes.element]),
    isDoubling:  PropTypes.bool,
    isStackable: PropTypes.bool,
    itemsPerRow: PropTypes.number,

    onClick:      PropTypes.func,
    headerStyles: PropTypes.shape({
      size: PropTypes.oneOf(sizes.S_M_L),
      font: PropTypes.shape({
        weight:   PropTypes.oneOf(font.weights.ALL),
        family:   PropTypes.oneOf(font.families.ALL),
        emphasis: PropTypes.oneOf(font.emphasis.ALL),
      }),
      textOverflow: PropTypes.string,
    }),
    descriptionStyles: PropTypes.shape({
      textOverflow: PropTypes.string,
    }),
  },
  defaultProps: {
    children:    null,
    isDoubling:  false,
    isStackable: false,
    itemsPerRow: 4,

    onClick:      undefined,
    headerStyles: {
      size: sizes.X4,
      font: {
        weight:   font.weights.REGULAR,
        family:   font.families.CUSTOM,
        emphasis: font.emphasis.NORMAL,
      },
      textOverflow: textOverflows.WRAP,
    },
    descriptionStyles: {
      textOverflow: textOverflows.WRAP,
    },
  },
  render(props) {
    const UIProps = toUIProps({ props, propMap, UNSUPPORTED_PROPS, ADDED_PROPS, MODIFIED_PROPS });
    UIProps.children = cloneChildrenWithDefaultProps(props, childPropWhitelist);

    return <UICard.Group {...UIProps}>{UIProps.children}</UICard.Group>;
  },
});

const childPropWhitelist = ['onClick', 'size', 'headerStyles'];

export default whitelistStyles()(CardGroup);
