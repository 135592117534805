/**
 * contains functions to setup drag and drop on the asset uploader
 */

/**
 * will stop certain drag and drop events happening on the window.
 */
const stopWindowDragAndDrop = () => {
  //dragstart, drag, dragover, dragenter, dragleave, drop, and dragend.
  window.addEventListener(
    'dragover',
    function(e) {
      e = e || event;
      e.preventDefault();
    },
    false
  );
  window.addEventListener(
    'dragleave',
    function(e) {
      e = e || event;
      e.preventDefault();
    },
    false
  );
  window.addEventListener(
    'drop',
    function(e) {
      e = e || event;
      e.preventDefault();
    },
    false
  );
};

export const initDragAndDrop = (componentRef, onDragActionFn, onDropFilesFn) => {
  stopWindowDragAndDrop(); //without this the browser could view a dropped image

  componentRef.addEventListener(
    'dragenter',
    function(e) {
      e.preventDefault();
      e.dataTransfer.dropEffect = 'none';
      if (e.currentTarget.className.indexOf('upload-state-file-select') > -1) {
        onDragActionFn.call(this, true);
      }

      return false;
    },
    false
  );

  componentRef.addEventListener('dragover', function(e) {
    e.preventDefault();
    if (e.currentTarget.className.indexOf('upload-state-file-select') > -1) {
      onDragActionFn.call(this, true);
    }
    return false;
  });

  componentRef.addEventListener(
    'drop',
    function(e) {
      e.preventDefault();
      onDragActionFn.call(this, false);
      if (e.currentTarget.className.indexOf('upload-state-file-select') > -1 && e.currentTarget.className.indexOf('disabled') === -1){
        const files = e.dataTransfer.files && e.dataTransfer.files.length > 0
          ? e.dataTransfer.files
          : null;
        if (files) {
          onDropFilesFn.call(this, files);
        }
      }
    },
    false
  );

  componentRef.addEventListener(
    'dragleave',
    function(e) {
      e.preventDefault();
      if (e.target.className.indexOf('drag-and-drop-container') > -1){
        onDragActionFn.call(this, false);
      }
      return false;
    },
    false
  );
};
