import React from 'react';
import { createComponent, PropTypes } from 'wayin-react';
import cx from 'classnames';
import { extractElement, toUIProps } from 'helpers';
import { display, sizes, positions, colors, icons } from 'enums';

import { Dropdown as UIDropdown } from 'semantic-ui-react';
import { PrivateLabel } from 'components/core/label';
import Icon from 'components/core/icon';
import { mapHandlers, whitelistStyles } from 'components/core/hoc';
import { compose } from 'recompose';
import ErrorIcon from '../../abstractions/error-icon';

import evalPredicate from '../../../util/eval-predicate';

const UNSUPPORTED_PROPS = [
  'as',
  'open',
  'defaultValue',
  'defaultSelectedLabel',
  'defaultSearchQuery',
  'name',
  'trigger', //Add support when needed
  'button', //Add support when needed or perhaps trigger is sufficient

  'additionPosition',
  'selectOnBlur',

  'onBlur',
  'closeOnBlur',
  'closeOnChange',
  // 'onClick',
  'onFocus',
  'onMouseDown',
  'onLabelClick', // add support when/if needed

  'className',
  'button',
  'compact',
  'fluid',
  'floating',
  'header', //Use more verbose form
  'inline',
  'labeled', //Use more verbose form
  'linkItem',
  'multiple',
  'pointing',
  'renderLabel',
  'selectedLabel',

  'selection', //Since we aren't using HTML forms for submission, couldn't think of valid use case
  'simple',
  'loading',
  'error',
  'disabled',
  'scrolling', //I think we will want this, but might want to make dynamic by allowing the user to set max items or max height and applying scroll if overflow or something
];

const ADDED_PROPS = [
  'label',
  'isFluid',
  'isInline',
  'allowMultiple',
  'id',
  'isLoading',
  'hasError',
  'errorMessage',
  'errorMessagePosition',

  'isDisabled',
  'isInverted',
  'handleClear', // handler
  'handleRemoveLabel',
  'showClearIcon',
  'allowAdditionsWhiteSpace',
  'isUnderlined',
];

const MODIFIED_PROPS = ['options', 'style', 'searchQuery'];

const propMap = {
  isFluid:            'fluid',
  isInline:           'inline',
  allowMultiple:      'multiple',
  isLoading:          'loading',
  hasError:           'error',
  isDisabled:         'disabled',
  handleChange:       'onChange',
  handleSearchChange: 'onSearchChange',
};

/**
 * WARNING: DO NOT USE
 *  - Use one of these abstractions instead.
 *   - <SingleSelect />
 *   - <MultiSelect />
 * TODO: This is a WIP. Only supporting current use cases.
 *   - Support for more verbose form
 *   - There are a lot of possible props that can be used. Add them as needed.
 *   - Some props HAVE to be used with others and CANNOT be used with others which is confusing. Create abstractions for these use cases.
 */
const Dropdown = createComponent({
  displayName: 'Dropdown',
  propTypes:   {
    options: PropTypes.arrayOf(
      PropTypes.shape({
        text:  PropTypes.string,
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        icon:  PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
      })
    ).isRequired,
    // children TODO add in support for more verbose form
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
    ]),
    label:       PropTypes.string,
    placeholder: PropTypes.string,
    icon:        PropTypes.oneOfType([
      PropTypes.element, //Icon only
      PropTypes.string,
      PropTypes.shape({
        content:  PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
        position: PropTypes.oneOf(positions.LR),
      }),
    ]),

    isFluid:              PropTypes.bool,
    isInline:             PropTypes.bool,
    isLoading:            PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
    hasError:             PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
    errorMessage:         PropTypes.string,
    errorMessagePosition: PropTypes.oneOf(positions.ALL),

    isDisabled: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),

    allowAdditions:           PropTypes.bool, //Requires options, search, selection
    allowAdditionsWhiteSpace: PropTypes.bool,
    onAddItem:                PropTypes.func,
    additionLabel:            PropTypes.string,
    allowMultiple:            PropTypes.bool,
    // True- Enable searching with the default search function string.contains
    // False- Disable searching
    // ()- Enable searching but use provided function
    search:                   PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
    noResultsMessage:         PropTypes.string,

    onChange:       PropTypes.func.isRequired,
    onSearchChange: PropTypes.func,
    showClearIcon:  PropTypes.bool,
    defaultOpen:    PropTypes.bool,
  },
  defaultProps: {
    value:                    undefined, //null throws an error
    label:                    null,
    placeholder:              'Select an item...',
    icon:                     icons.DOWN,
    isFluid:                  true,
    isInline:                 false,
    isLoading:                false,
    hasError:                 false,
    errorMessage:             null,
    errorMessagePosition:     positions.TOP_LEFT,
    isDisabled:               false,
    allowAdditions:           false,
    allowAdditionsWhiteSpace: false,
    onAddItem:                null,
    additionLabel:            'Add:',
    allowMultiple:            false,
    search:                   false,
    onSearchChange:           null,
    noResultsMessage:         'No results found.',
    showClearIcon:            false,
    defaultOpen:              false,
  },
  state: {
    searchQuery: '',
  },
  handlers: {
    handleClear: p => e => {
      p.onChange(e, { value: p.allowMultiple ? [] : '' });
    },
    handleChange: p => (e, { value }) => {
      p.setSearchQuery('');
      p.onChange(e, { id: p.id, value });
    },
    // for multi-select
    handleRemoveLabel: p => (e, labelProps) => {
      const value = _.without(p.value, labelProps.value);
      p.onChange(e, { value });
    },

    handleSearchChange: p => (e, { searchQuery }) => {
      // on search change if we are allowing additions and not allowing white space
      // we add the input value to the multiselect or singleselect options when a space is entered in the input
      const shouldAddSearchValue = p.allowAdditions && isLastCharSpace(searchQuery) && !p.allowAdditionsWhiteSpace;
      if (!shouldAddSearchValue) {
        p.setSearchQuery(searchQuery);
      } else if (!p.allowMultiple && shouldAddSearchValue) {
        p.setSearchQuery('');
        p.onChange(e, { id: p.id, value: searchQuery.trim() });
        !!p.onAddItem && p.onAddItem(e, { id: p.id, value: searchQuery.trim() });
      } else if (shouldAddSearchValue) {
        const _value = !!p.value ? [...p.value, searchQuery.trim()] : [searchQuery.trim()];
        p.setSearchQuery('');
        p.onChange(e, { id: p.id, value: _value });
        !!p.onAddItem && p.onAddItem(e, { id: p.id, value: searchQuery.trim() });
      }
      !!p.onSearchChange && p.onSearchChange(e, { id: p.id, value: searchQuery });
    },
  },
  contextTypes: {
    isInverted: PropTypes.bool,
  },
  render(props) {
    /* TODO: if single-select, it would be nice (in the UI) to always have the currently
       selected option the first item in the list of options (top item of dropdown menu)
    */
    const UIProps = toUIProps({ props, propMap, UNSUPPORTED_PROPS, ADDED_PROPS, MODIFIED_PROPS });
    delete UIProps.fluid;
    delete UIProps.error;
    delete UIProps.setSearchQuery;

    UIProps.disabled = evalPredicate(props.isDisabled) || evalPredicate(props.isLoading);

    const icon = !evalPredicate(props.isLoading) ? extractElement(Icon, icons.DOWN, 'name') : undefined;

    UIProps.className = cx({ inverted: props.isInverted, underlined: props.isUnderlined });

    // Required by semantic-ui-react if options is defined
    UIProps.selection = true;
    UIProps.selectOnBlur = false;

    if (UIProps.multiple) {
      UIProps.renderLabel = label => {
        return (
          <PrivateLabel
            color={colors.SUPER}
            display={display.SOLID}
            isInverted={props.isInverted}
            isDisabled={UIProps.disabled}
            text={label.text}
            size={sizes.X2}
            value={label.value}
            onRemove={props.handleRemoveLabel}
          />
        );
      };
    }

    UIProps.icon = !!icon ? React.cloneElement(icon.element, { isDisabled: props.isDisabled }) : undefined;

    UIProps.options = _.map(props.options, (option, idx) => {
      if (!!option.icon) {
        option.icon = extractElement(Icon, option.icon, 'name').element;
      }
      if (!option.key) return Object.assign({}, option, { key: `${option.value}${idx}` });

      return option;
    });

    if (props.allowAdditions) {
      UIProps.search = true;

      //TODO Can/Should this be included as a PropType validation
      logger.warn('Dropdown: Missing PropType "onAddItem" required when "allowAdditions = true"', !props.onAddItem);
    }

    if (UIProps.search) {
      UIProps.searchQuery = props.searchQuery;
    }

    logger.warn(
      'Dropdown: propTypes "isDisabled" and "hasError" both set to true, this is invalid, please choose one or the other.',
      props.isDisabled && props.hasError
    );

    const wrapperClassNames = cx('ck dropdown', {
      error:    evalPredicate(props.hasError) || !!props.errorMessage,
      disabled: UIProps.disabled,
      fluid:    props.isFluid,
    });
    // disabled className needed on container for correct cursor styling
    return (
      <div className={wrapperClassNames} style={props.style}>
        <If condition={props.label}>
          <PrivateLabel text={props.label} isBasic isDisabled={UIProps.disabled}/>
        </If>
        <div className="error-wrapper">
          <div className="dropdown-positioner">
            <If condition={props.showClearIcon && (!_.isEmpty(props.value) || _.isNumber(props.value))}>
              <Icon name={icons.CANCEL} size={sizes.X5} onClick={props.handleClear} isDisabled={UIProps.disabled} />
            </If>
            <UIDropdown {...UIProps} />
          </div>
          <ErrorIcon errorMessagePosition={props.errorMessagePosition} errorMessage={props.errorMessage} />
        </div>
      </div>
    );
  },
});

function isLastCharSpace(value) {
  return value.charAt(value.length - 1) === ' ';
}

export default compose(mapHandlers({ change: [], addItem: [], searchChange: [] }), whitelistStyles())(Dropdown);
