import React from 'react';

import { createComponent, PropTypes } from 'wayin-react';

const VideoGif = createComponent({
  displayName: 'VideoGif',
  propTypes:   {
    src:   PropTypes.string.isRequired,
    width: PropTypes.number,
  },
  defaultProps: {
    width: undefined,
  },
  render({ src, width }) {
    return <video src={src} width={width} controls={false} autoPlay loop />;
  },
});

export default VideoGif;
