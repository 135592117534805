//modes
const cmModes = {
  XML:       'xml',
  CSS:       'css',
  JS:        'javascript',
  //this is a mix of JavaScript, CSS and HTML/XML all in one package - for example <script>// ... code </script> will be
  //parsed as Javascript just like in a normal .html page
  HTMLMIXED: 'htmlmixed',

  //similar to htmlmixed , but this one allows a templating engine to be used inside the page, this one uses ERB(Ruby)
  //for the templating engine syntax (add code between "${" and "}" to see results
  //as GSP (Grails templating engine) is not supported at the time of writing this by codemirror, ERB was the next best
  //thing - NOTE: Using other components such as the multiplex for example, you can change the tokens used for delimiting
  //code for the templating engine as well as create a unique language template by mixing 2 or more existing languages
  //supported by CodeMirror (for example change the templating to be "{{" to "}}" similar to Blade or Angular)
  ERB: 'application/x-erb',

  //more mods can be added on request
};
cmModes.ALL = _.values(cmModes);

//themes
const cmThemes = {
  LIGHT: 'code-mirror-light',
  DARK:  'code-mirror-dark',
};

cmThemes.ALL = _.values(cmThemes);

export { cmModes, cmThemes };
