import React from 'react';
import { createComponent, PropTypes } from 'wayin-react';
import { toUIProps } from 'helpers';

import { Header as UIHeader } from 'semantic-ui-react';

const UNSUPPORTED_PROPS = [
  'as',
  'className',
  'content', // mutually exclusive with children
];

const MAPPED_PROPS = [];
const ADDED_PROPS = [];
const MODIFIED_PROPS = [];

const HeaderByline = createComponent({
  displayName: 'HeaderByline',
  parent:      'Header',
  propTypes:   {
    children: PropTypes.node,
  },
  defaultProps: {
    children: null,
  },

  render(props) {
    const UIProps = toUIProps({
      props,
      propMap: MAPPED_PROPS,
      UNSUPPORTED_PROPS,
      ADDED_PROPS,
      MODIFIED_PROPS,
    });
    return (
      <UIHeader.Subheader {...UIProps}>
        {props.children}
      </UIHeader.Subheader>
    );
  },
});

export default HeaderByline;
