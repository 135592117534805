let bgImageRepeats = {
  NO_REPEAT: 'no-repeat',
  REPEAT_X:  'repeat-x',
  REPEAT_Y:  'repeat-y',
  REPEAT:    'repeat',
};

bgImageRepeats.ALL = _.values(bgImageRepeats);

export default bgImageRepeats;
