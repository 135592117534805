import React from 'react';
import { createComponent, PropTypes } from 'wayin-react';

import { sizes, textAlignments, assetServices, uploadAssetTypes } from 'enums';

import Modal from 'components/core/modal';
import Panel from 'components/abstractions/panel';
import Layout from 'components/core/layout';
import Video from 'components/core/video';
import CKImage from 'components/core/image';

/**
 * modal preview showing a larger image/video preview
 */
const AssetPreview = createComponent({
  displayName: 'AssetPreview',

  propTypes: {
    previewModalVisible: PropTypes.bool.isRequired,
    hidePreviewModal:    PropTypes.func.isRequired,
    headerText:          PropTypes.string,
    bylineText:          PropTypes.string,
    assetRefObj:         PropTypes.object.isRequired,
    assetPath:           PropTypes.string,
  },
  defaultProps: {
    assetPath:  '',
    headerText: '',
    bylineText: '',
  },

  render(props) {
    const { previewModalVisible, hidePreviewModal, headerText, bylineText, assetPath, assetRefObj } = props,
      useVideoPreview =
        assetRefObj.service === assetServices.WEB_URL && assetRefObj.assetType === uploadAssetTypes.VIDEO_URL;

    return (
      <Modal
        showModal={previewModalVisible}
        onClose={hidePreviewModal}
        hasCloseIcon={true}
        isSolid
        isInverted={false}
        style={{
          padding: '0',
        }}
      >
        <div>
          <Panel
            hasBorder={true}
            padding={{ vertical: sizes.X2, horizontal: sizes.X2 }}
            header={headerText}
            byline={bylineText}
          />
          <Layout
            columnStyles={{ textAlign: textAlignments.CENTER }}
            padding={{
              vertical:   sizes.SMALL,
              horizontal: sizes.SMALL,
            }}
          >
            <Choose>
              <When condition={!useVideoPreview}>
                <CKImage src={assetPath} />
              </When>
              <When condition={useVideoPreview}>
                <Video src={assetRefObj.location} />
              </When>
            </Choose>
          </Layout>
        </div>
      </Modal>
    );
  },
});

export default AssetPreview;
