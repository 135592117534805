import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import 'helpers/velocity';

import { VelocityTransitionGroup, VelocityComponent } from 'velocity-react';

function OnMountChange(props) {
  return (
    <VelocityTransitionGroup
      enter={props.onMountAnimation}
      leave={props.onUnmountAnimation}
      className={classnames({ [props.className]: props.className }, 'transition-group')}
      component="div"
      runOnMount={props.runOnMount}
    >
      {props.children}
    </VelocityTransitionGroup>
  );
}

OnMountChange.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  className: PropTypes.string,
  onMountAnimation: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  onUnmountAnimation: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  runOnMount: PropTypes.bool,
};

OnMountChange.defaultProps = {
  children: null,
  className: undefined,
  onMountAnimation: { animation: 'fadeIn' },
  onUnmountAnimation: { animation: 'fadeOut' },
  runOnMount: false,
};

function OnTransition(props) {
  return (
    <VelocityComponent
      animation={!!props.transitionCondition ? props.truthyAnimation : props.falsyAnimation}
      runOnMount={props.runOnMount}
      complete={props.onComplete}
      duration={props.duration}
      delay={props.delay}
    >
      {props.children}
    </VelocityComponent>
  );
}

OnTransition.propTypes = {
  children: PropTypes.node.isRequired,
  falsyAnimation: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  runOnMount: PropTypes.bool,
  truthyAnimation: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  transitionCondition: PropTypes.bool.isRequired,
  onComplete: PropTypes.func,
  duration: PropTypes.number,
  delay: PropTypes.number,
};

OnTransition.defaultProps = {
  falsyAnimation: 'fadeOut',
  runOnMount: false,
  truthyAnimation: 'fadeIn',
  onComplete: _.noop,
  duration: undefined,
  delay: undefined,
};

const Animate = {
  OnMountChange,
  OnTransition,
};
export default Animate;
