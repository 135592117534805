import React from 'react';
import { createComponent, PropTypes } from 'wayin-react';
import { positions, sizes, colors } from 'enums';
import { toUIProps, extractElement, html } from 'helpers';
import { removeProps, mapHandlers, whitelistStyles } from 'components/core/hoc';
import { compose } from 'recompose';
import cx from 'classnames';

import { Input as UIInput } from 'semantic-ui-react';

import Icon from 'components/core/icon';
import { PrivateLabel } from 'components/core/label';
import Button from 'components/core/button';
import Tooltip from 'components/core/tooltip';
import ErrorIcon from 'components/abstractions/error-icon';
import LoadingState from 'components/abstractions/loading-state';
import evalPredicate from '../../../util/eval-predicate';

const propMap = {
  hasFocus:      'focus',
  isDisabled:    'disabled',
  isFluid:       'fluid',
  isLoading:     'loading',
  isTransparent: 'transparent',
  isInverted:    'inverted',
  handleFocus:   'onFocus',
};
const ADDED_PROPS = [
  'errorMessage',
  'errorMessagePosition',
  'hasError',
  'hasErrorIcon',
  'handleChange',
  'handleEscape',
  'handleFocus',
  'hasFocus',
  'id',
  'isDisabled',
  'isDisabledAction',
  'isFluid',
  'isLoading',
  'isTransparent',
  'isInverted',
  'label',
  'innerLabel',
  'handleClear', // internal handler
  'maxChars',
  'setFocus',
  'isUnderlined',
  'escapeHTMLEntities',
  'loader',
];
const MODIFIED_PROPS = ['action', 'icon', 'style', 'error'];
const UNSUPPORTED_PROPS = [
  'as',
  'actionPosition',
  'children',
  'className',
  'disabled',
  'error',
  'focus',
  'fluid',
  'iconPosition',
  'inverted',
  'input',
  'labelPosition',
  'loading',
  'transparent',
  'iconSize',
];

const Input = createComponent({
  displayName: 'Input',
  propTypes:   {
    action: PropTypes.oneOfType([
      PropTypes.element, //Button, Dropdown, Select
      PropTypes.shape({
        content:  PropTypes.element,
        position: PropTypes.oneOf(positions.LR),
      }),
    ]),
    icon: PropTypes.oneOfType([
      PropTypes.element, //Icon
      PropTypes.string,
      PropTypes.shape({
        content:  PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
        position: PropTypes.oneOf(positions.LR),
      }),
    ]),
    innerLabel: PropTypes.oneOfType([
      PropTypes.element, //Label
      PropTypes.string,
      PropTypes.shape({
        content:  PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
        position: PropTypes.oneOf(positions.LR),
      }),
    ]),
    label:    PropTypes.string,
    maxChars: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.shape({
        content:  PropTypes.number,
        position: PropTypes.oneOf(positions.LR),
      }),
    ]),
    hasError:             PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
    hasFocus:             PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
    isDisabled:           PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
    isDisabledAction:     PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
    isFluid:              PropTypes.bool,
    isLoading:            PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
    isTransparent:        PropTypes.bool,
    errorMessage:         PropTypes.string,
    errorMessagePosition: PropTypes.oneOf(positions.ALL),
    size:                 PropTypes.oneOf(sizes.ALL),
    type:                 PropTypes.string,
    onChange:             PropTypes.func,
    onBlur:               PropTypes.func,
    value:                PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    innerRef:             PropTypes.func,
    isUnderlined:         PropTypes.bool,
    readOnly:             PropTypes.bool,
    escapeHTMLEntities:   PropTypes.bool,
    loader:               PropTypes.element,
  },
  defaultProps: {
    action:     null,
    icon:       null,
    label:      null,
    innerLabel: null,

    hasError:             false,
    hasErrorIcon:         true,
    hasFocus:             null,
    isDisabled:           false,
    isDisabledAction:     false,
    isFluid:              true,
    isLoading:            false,
    isTransparent:        false,
    errorMessage:         null,
    errorMessagePosition: positions.TOP_LEFT,
    size:                 sizes.X5,
    type:                 'text',
    onChange:             null,
    value:                '',
    maxChars:             null,
    onBlur:               undefined,
    onFocus:              undefined,
    innerRef:             undefined,
    isUnderlined:         false,
    readOnly:             false,
    escapeHTMLEntities:   false,
    loader:               null,
  },
  contextTypes: {
    isInverted: PropTypes.bool,
  },
  handlers: {
    handleClear: (p) => (e) => {
      p.onChange(e, { value: '' });
    },
    handleChange: (p) => (e, { value }) => {
      p.onChange(e, {
        id:    p.id,
        value: p.escapeHTMLEntities ? html.escapeHTML(value) : value,
      });
    },
    handleFocus: (p) => (e) => {
      p.setFocus(true);
      if (p.autoFocus) {
        // https://stackoverflow.com/questions/35951771/react-autofocus-sets-curser-to-beginning-of-input-value
        const val = e.target.value;
        e.target.value = '';
        e.target.value = val;
      }
      p.onFocus && p.onFocus(e, { value: e.target.value, id: p.id });
    },
    onBlur: (p) => (e) => {
      const value = e.target.value || '';
      p.onBlur && p.onBlur(e, { value, id: p.id });
      p.setFocus(false);
    },
    handleEscape: (p) => (e) => {
      // handles IE default behavior where ESC resets value of "form field"
      if (e.keyCode === 27) {
        e.preventDefault();
      }
    },
  },
  state: {
    focus: false,
  },
  render(props) {
    const UIProps = toUIProps({ props, propMap, MODIFIED_PROPS, UNSUPPORTED_PROPS, ADDED_PROPS });
    delete UIProps.error;
    delete UIProps.innerRef;
    UIProps.onChange = props.handleChange; //using an internal change handler, so we can handle html entity escaping when needed

    //builds out the object for 'remaining characters'
    let remainingCharCount, remainingCharCountPosition;
    if (
      // we need to perform a different check for the maxChars it's an object
      _.isObject(props.maxChars) ?
        props.maxChars.content !== null && props.maxChars.content !== undefined :
        props.maxChars !== null
    ) {
      const maxLength = _.isObject(props.maxChars) ? props.maxChars.content : props.maxChars;

      const remainingChars = maxLength - props.value.length;
      const remainingCharsLabel = extractElement(
        PrivateLabel,
        remainingChars.toString(),
        'text',
        props.maxChars.position
      );

      const remainingCharsClassNames = cx({
        'max-char':                     'max-char',
        'max-char-warning':             _.inRange(remainingChars, 4, 11),
        'max-char-error':               remainingChars <= 3,
        [remainingCharsLabel.position]: remainingCharsLabel.position,
      });
      remainingCharCount = <div className={remainingCharsClassNames}>{remainingCharsLabel.element}</div>;
      remainingCharCountPosition = remainingCharsLabel.position;
    }

    let action = extractElement(Button.Primary, props.action, 'text', positions.RIGHT);
    let icon = extractElement(Icon, props.icon, 'name', positions.RIGHT);
    const innerLabel = extractElement(PrivateLabel, props.innerLabel, 'text', positions.LEFT);

    // When both are defined and positioned to the same side we want to render the Icon as a prop on Label
    // This allows us to leverage semantic's built in styling
    if (!!innerLabel && !!icon && innerLabel.position === icon.position) {
      innerLabel.element = React.cloneElement(innerLabel.element, { icon: props.icon });
      icon = null;
    }
    let label, labelPosition, actionPosition, iconPosition;
    UIProps.value = props.escapeHTMLEntities ? html.unescapeHTML(props.value) : props.value;
    actionPosition = !!action && action.position;
    action = !!action && React.cloneElement(action.element, { isDisabled: props.isDisabled || props.isDisabledAction });
    iconPosition = !!icon && icon.position === positions.LEFT ? icon.position : null;
    icon = !!icon ? icon.element : null;
    labelPosition = !!innerLabel && innerLabel.position; //Due to Stardust propTypes
    label = !!innerLabel ? innerLabel.element : null;
    //UIProps.error = evalPredicate(props.hasError) || !!props.errorMessage;

    // loading icon replaces any existing icon
    if (!!props.isLoading) {
      icon = (
        <div className="ck input__loader">
          <Choose>
            <When condition={React.isValidElement(props.loader)}>{props.loader}</When>
            <Otherwise>
              <LoadingState spinnerSize={sizes.X1} height={sizes.X1} />
            </Otherwise>
          </Choose>
        </div>
      );

      iconPosition = positions.LEFT;
    }

    UIProps.className = cx({
      'labeled right': !!label && labelPosition !== positions.LEFT,
      'left labeled':  !!label && labelPosition === positions.LEFT,
      'action right':  !!action && actionPosition !== positions.LEFT,
      'left action':   !!action && actionPosition === positions.LEFT,
      'icon right':    !!icon && iconPosition !== positions.LEFT,
      'left icon':     !!icon && iconPosition === positions.LEFT,
      focus:           props.hasFocus === null && props.focus,
      underlined:      props.isUnderlined,
    });

    const _hasError = evalPredicate(props.hasError) || !!props.errorMessage;

    const wrapperClassName = cx('ck input', {
      fluid:            props.isFluid,
      'is-number':      props.type === 'number',
      error:            _hasError,
      'has-error-icon': props.hasErrorIcon,
      'read-only':      props.readOnly,
    });

    logger.warn(
      'Input: propTypes "isDisabled" and "hasError" both set to true, this is invalid, please choose one or the other.',
      props.isDisabled && props.hasError
    );

    const _input = (
      <UIInput {...UIProps} ref={props.innerRef}>
        {actionPosition === positions.LEFT && action}
        {iconPosition === positions.LEFT && icon}
        {labelPosition === positions.LEFT && label}
        {remainingCharCountPosition === positions.LEFT && remainingCharCount}
        {<input onKeyDown={props.handleEscape} />}
        {labelPosition !== positions.LEFT && label}
        {iconPosition !== positions.LEFT && icon}
        {remainingCharCountPosition !== positions.LEFT && remainingCharCount}
        {actionPosition !== positions.LEFT && action}
      </UIInput>
    );

    return (
      <div className={wrapperClassName} style={props.style}>
        <If condition={props.label}>
          <PrivateLabel text={props.label} />
        </If>
        <Choose>
          <When condition={props.hasErrorIcon}>
            <div className="error-wrapper">
              {_input}
              <ErrorIcon errorMessage={props.errorMessage} errorMessagePosition={props.errorMessagePosition} />
            </div>
          </When>
          <Otherwise>
            <div className="error-wrapper">
              <Tooltip tooltip={props.errorMessage} color={colors.ERROR} position={props.errorMessagePosition}>
                {_input}
              </Tooltip>
            </div>
          </Otherwise>
        </Choose>
      </div>
    );
    //TODO Leaving in case go with children approach. Left to do was the `<input>`
    // -Challenge was matching the Stardust render behavior WRT props for <input>
    // const actionClassNames = !!action && `${action.position} action`
    // const iconClassNames = !!icon && `${icon.position} icon`
    // const labelClassNames = !!label && `${label.position} labeled`
    // UIProps.className = classnames(props.size, actionClassNames, iconClassNames, labelClassNames);
    // return (
    //   <UIInput { ...UIProps } >
    //     { !!action && action.position === positions.LEFT && action.element }
    //     { !!label && label.position === positions.LEFT && label.element }
    //     { !!icon && icon.position === positions.LEFT && icon.element }
    //
    //     { !!icon && icon.position === positions.RIGHT && icon.element }
    //     { !!label && label.position === positions.RIGHT && label.element }
    //     { !!action && action.position === positions.RIGHT && action.element }
    //   </UIInput>
    // )
  },
});

/* Leaving this in case we want the clear 'x' button in an input down the line
  stylistically advantagous to include it in the action

    UIProps.action = (
      <span className='ck input-action'>
        <If condition={ !!action && action.position === positions.LEFT }>
          { React.cloneElement(action.element, { isDisabled: props.isDisabled }) }
        </If>
        <If condition={ !_.isEmpty(props.value) || _.isNumber(props.value) }>
          <span className='ck-clear-btn'>
            <Button.Secondary
              isDisabled={ props.isDisabled }
              color={ props.isInverted ? colors.WHITE : colors.BASE }
              icon={ <Icon name={ icons.CANCEL } /> }
              size={ sizes.X2 }
              onClick={ props.handleClear }
              isCompact
            />
          </span>
        </If>
        <If condition={ !!action && action.position === positions.RIGHT }>
         { React.cloneElement(action.element, { isDisabled: props.isDisabled }) }
        </If>
      </span>
    )
*/

export const PrivateInput = mapHandlers({ change: [], blur: [] })(Input);
export default compose(removeProps(['transparent']), whitelistStyles())(PrivateInput);
