import React from "react";
import { createComponent, PropTypes } from "wayin-react";
import classnames from "classnames";
import {
  sizes,
  positions,
  colors,
  font,
  textAlignments,
  display,
  buttonStates,
} from "enums";
import { extractElement, toUIProps } from "helpers";
import { whitelistStyles, removeProps, mapHandlers } from "components/core/hoc";
import { compose } from "recompose";

import { Button as UIButton } from "semantic-ui-react";
import { tooltipify } from "components/core/tooltip";

import Icon from "../icon";

const propMap = {
  // state
  isActive: "active",
  isDisabled: "disabled",
  // style
  isCompact: "compact",
  isInverted: "inverted",
  isFluid: "fluid",
  float: "floated",
};

const MODIFIED_PROPS = ["icon", "color"];

const UNSUPPORTED_PROPS = [
  "active",
  "animated",
  "as",
  "attached",
  "basic",
  "children",
  "className",
  "circular",
  "compact",
  "content",
  "disabled",
  "floated",
  "fluid",
  "inverted",
  "labeled",
  "label",
  "loading",
  "negative",
  "positive",
  "primary",
  "secondary",
  "toggle",
  "type",
];

const ADDED_PROPS = [
  "float",
  "hasHoverBorder",
  "id",
  "isActive",
  "isDisabled",
  "isExitingError",
  "isExitingSuccess",
  "isCompact",
  "isFluid",
  "isInverted",
  "setIsExitingError",
  "setIsExitingSuccess",
  "text",
  "value",
  "font",
  "textAlign",
  "_display",
  "buttonState",
  "stopPropagation",
  "handleClick",
];

const Button = createComponent({
  displayName: "Button",
  propTypes: {
    text: PropTypes.string,
    icon: PropTypes.oneOfType([
      PropTypes.element, //Icon only
      PropTypes.string,
      PropTypes.shape({
        content: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
        position: PropTypes.oneOf(positions.LR),
      }),
    ]),
    color: PropTypes.oneOf(_.without(colors.CORE, colors.ERROR)),
    size: PropTypes.oneOf(sizes.ALL),
    font: PropTypes.shape({
      weight: PropTypes.oneOf(font.weights.ALL),
      family: PropTypes.oneOf(font.families.ALL),
      emphasis: PropTypes.oneOf(font.emphasis.ALL),
    }),
    hasHoverBorder: PropTypes.bool,
    textAlign: PropTypes.oneOf(textAlignments.ALL),

    value: PropTypes.any,
    onClick: PropTypes.func.isRequired,

    float: PropTypes.oneOf(positions.LR),
    isActive: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
    isDisabled: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
    isCompact: PropTypes.bool,
    isFluid: PropTypes.bool,
    buttonState: PropTypes.oneOf(buttonStates.ALL),
    stopPropagation: PropTypes.bool,

    _display: PropTypes.oneOf(display.ALL),
  },
  defaultProps: {
    text: null,
    icon: null,
    color: undefined, // Must be undefined to maintain override behavior
    size: undefined, // Must be undefined to maintain override behavior
    font: {},
    hasHoverBorder: false,
    textAlign: textAlignments.CENTER,
    value: null,
    float: null,
    isActive: false,
    isDisabled: false,
    isCompact: false,
    isFluid: false,
    buttonState: buttonStates.DEFAULT,
    stopPropagation: false,
    _display: display.OUTLINE,
  },
  contextTypes: {
    isInverted: PropTypes.bool,
  },
  state: {
    isExitingSuccess: false,
    isExitingError: false,
  },
  lifecycles: {
    UNSAFE_componentWillUpdate(nextProps) {
      /*
       TODO The isSuccess and isError implementation should get swapped out for VelocityReact when appropriate and then this can go away.
        From Kyra: The docs are not good. There is a velocity helper - a function that takes a node (ref) an animation (styles) and a duration and returns a promise like object that is "thenable". Here's an example
         // animate old item out, set new state, animate new item in
         Velocity(this.itemRefs[randomTileIdx], { opacity: 0 }, TRANSITION_DURATION).then(
          this.setState.bind(this, { nextItemIdx, tileMap }, () => {
            Velocity(this.itemRefs[randomTileIdx], { opacity: 1 }, TRANSITION_DURATION);
          })
         );
       The delay of 100 is an approximation. We want the class to be removed at the same time the
        CSS transition of 500ms is ending. The class being removed also includes delays for the JS event loop
        and React's reconciliation which is why the value is less. If the CSS transition gets changed then this
        might need adjusting
      */
      if (
        nextProps.buttonState !== buttonStates.SUCCESS &&
        this.props.buttonState === buttonStates.SUCCESS
      ) {
        this.props.setIsExitingSuccess(true);
        _.delay(() => this.props.setIsExitingSuccess(false), 100);
      }
      if (
        nextProps.buttonState !== buttonStates.ERROR &&
        this.props.buttonState === buttonStates.ERROR
      ) {
        this.props.setIsExitingError(true);
        _.delay(() => this.props.setIsExitingError(false), 100);
      }
    },
  },
  handlers: {
    handleClick: (p) => (evt, data) => {
      //buttons ONLY in default state should propagate actions which means
      //no actions should be delegated if the button is in error, loading or
      //success states; these states are associated with the animation buttons
      //where default state is base for all buttons (including animation ones)
      if (p.buttonState === buttonStates.DEFAULT) {
        p.onClick(evt, data);
      }

      evt.currentTarget.blur();
      if (p.stopPropagation) {
        evt.stopPropagation();
      }
    },
  },
  render(p) {
    const props = _.defaults(p, defaultProps);
    const UIProps = toUIProps({
      props,
      propMap,
      MODIFIED_PROPS,
      UNSUPPORTED_PROPS,
      ADDED_PROPS,
    });

    const icon = extractElement(Icon, props.icon, "name");
    const iconElement =
      !!icon &&
      React.cloneElement(icon.element, {
        isDisabled: props.isDisabled,
        color: icon.element.props.color || props.color,
      });

    const iconPosition = icon && icon.position;

    const fontStyles = {
      weight: (props.font && props.font.weight) || font.weights.MEDIUM,
      family: (props.font && props.font.family) || getDefaultFontFamily(props),
    };

    UIProps.loading = props.buttonState === buttonStates.LOADING;

    UIProps.className = classnames(
      "ck",
      props.color,
      props.textAlign,
      {
        aligned: props.textAlign !== textAlignments.JUSTIFIED,
        icon: !!icon,
        "icon-only": !!icon && !props.text,
        outline: props._display === display.OUTLINE,
        basic: props._display === display.PLAIN,
        solid: props._display === display.SOLID,
        caps: isEmphasisCaps(props),
        [`icon-${iconPosition}`]: !!icon && !!props.text && icon.position,
        success: props.buttonState === buttonStates.SUCCESS,
        "success--exiting": props.isExitingSuccess,
        error: props.buttonState === buttonStates.ERROR,
        "error--exiting": props.isExitingError,
        "hover-border": props.hasHoverBorder,
      },
      `font-${fontStyles.family}-${fontStyles.weight}`
    );

    return (
      <UIButton {...UIProps} onClick={p.handleClick}>
        {!!icon && icon.position === positions.LEFT && iconElement}
        <If condition={!!props.text}>
          <span className="button-text">{props.text}</span>
        </If>
        {!!icon && icon.position === positions.RIGHT && iconElement}
      </UIButton>
    );
  },
});

const defaultProps = {
  color: colors.ACCENT,
  size: sizes.X3,
};

function isEmphasisCaps(props) {
  // legacy `textEmphasis` prop - keep backwards compatible
  logger.deprecation(
    `Prop "textEmphasis" has been deprecated, and can conflict with
    "font: emphasis", please remove "textEmphasis" from Button.`,
    !!props.textEmphasis
  );

  return (
    props.textEmphasis === font.emphasis.CAPS ||
    (!props.textEmphasis &&
      props.font &&
      props.font.emphasis === font.emphasis.CAPS)
  );
}

function getDefaultFontFamily(p) {
  // backwards compatable for time when CAPS set font family as well.
  if (p._display === display.PLAIN && p.font.emphasis !== font.emphasis.CAPS) {
    return font.families.SYSTEM;
  } else {
    return font.families.CUSTOM;
  }
}

export const PrivateButton = compose(
  tooltipify({ isInline: true }),
  mapHandlers({ click: [] }),
  whitelistStyles()
)(Button);
export default removeProps(["_display", "color"])(PrivateButton);
