import mapProps from './map-props';

export default function toUIProps({
  props,
  propMap = {},
  UNSUPPORTED_PROPS = [],
  ADDED_PROPS = [],
  MODIFIED_PROPS = [],
}) {
  /*
    TEMPORARILY DISABLING BECAUSE WAS FIRING TOO AGGRESSIVELY
    TEST WITH MULTISELECT STORY
  */

  // if (process.env.__DEV__) {
  //   const passedUnsupportedProps = _.pick(props, UNSUPPORTED_PROPS);
  //   logger.warn(
  //     `You are trying to pass unsupported props. They will not be applied: ${_.keys(passedUnsupportedProps)}`,
  //     !_.isEmpty(passedUnsupportedProps)
  //   );
  // }
  let UIProps = _.omit(props, UNSUPPORTED_PROPS);
  UIProps = mapProps(propMap, UIProps);
  UIProps = _.omit(UIProps, [...MODIFIED_PROPS, ...ADDED_PROPS]);
  return UIProps;
}
