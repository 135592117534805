import Menu from './menu';
import MenuHeader from './menu-header';
import MenuItem from './menu-item';
import MenuSubmenu from './menu-submenu';

// Menu.Header = MenuHeader;
Menu.Submenu = MenuSubmenu;
Menu.Item = MenuItem;

export default Menu;
