import React from "react";
import { createComponent, PropTypes } from "wayin-react";
import { toUIProps } from "helpers";
import Measure from "react-measure";
import cx from "classnames";

import { Reveal as UIReveal } from "semantic-ui-react";

const UNSUPPORTED_PROPS = ["as", "className", "hidden", "visible"];

const ADDED_PROPS = [
  "whenIsActive",
  "shouldCalcHeight",
  "isTransitioning",
  "setIsTransitioning",
  "transitionHeight",
  "setTransitionHeight",
  "isActive",
  "shouldTransitionOnMount"
];
const MODIFIED_PROPS = [];

const RevealContent = createComponent({
  displayName: "RevealContent",
  propTypes: {
    children: PropTypes.any.isRequired,
    whenIsActive: PropTypes.bool,
    shouldCalcHeight: PropTypes.bool,
    isActive: PropTypes.bool,
    shouldTransitionOnMount: PropTypes.bool
  },
  defaultProps: {
    whenIsActive: false,
    shouldCalcHeight: false,
    isActive: false,
    shouldTransitionOnMount: false
  },
  lifecycles: {
    UNSAFE_componentWillReceiveProps(nextProps) {
      if (nextProps.isActive !== this.props.isActive) {
        this.props.setIsTransitioning(true);
      }
      if (this.props.transitionHeight !== undefined) {
        if (nextProps.transitionHeight !== this.props.transitionHeight) {
          this.props.setIsTransitioning(true);
        }
      }
    }
  },
  state: {
    transitionHeight: {
      value: p => (p.shouldTransitionOnMount ? 0 : undefined),
      updater(setHeight, p) {
        return {
          setTransitionHeight: p => setHeight(getHiddenContentHeight(p.bounds))
        };
      }
    },
    isTransitioning: false
  },
  handlers: {
    onTransitionEnd: p => e => {
      p.setIsTransitioning(false);
    }
  },
  render(props) {
    let maxHeight = null;
    if (props.shouldCalcHeight) {
      maxHeight = props.isActive ? `${props.transitionHeight}px` : 0;
    }

    const UIProps = toUIProps({
      props,
      UNSUPPORTED_PROPS,
      ADDED_PROPS,
      MODIFIED_PROPS
    });
    UIProps.className = cx({ transitioning: props.isTransitioning });

    // reveal content when isActive is TRUE means it is initially hidden
    // reveal content when isActive is FALSE means it is initially visible
    UIProps.hidden = props.whenIsActive;
    UIProps.visible = !props.whenIsActive;
    UIProps.children = (
      <Measure bounds onResize={props.setTransitionHeight}>
        {({ measureRef }) => (
          <div ref={measureRef}>{props.children}</div>
        )}
      </Measure>
    );
    UIProps.onTransitionEnd = props.onTransitionEnd;
    UIProps.style = { maxHeight };

    return <UIReveal.Content {...UIProps} />;
  }
});

function getHiddenContentHeight(bounds) {
  const heightCushion = 5; // a little insurance so we don't cut/hide content with zero padding
  const height = !!bounds? bounds.height : 0;
  return height + heightCushion;
}
export default RevealContent;
