import React from 'react';
import cx from 'classnames';
import moment from 'moment-timezone';

import Layout from 'components/core/layout';
import Button from 'components/core/button';
import Label from 'components/core/label';
import CKImage from 'components/core/image';
import Tooltip from 'components/core/tooltip';

import { createComponent, PropTypes } from 'wayin-react';
import * as FileUtils from 'components/core/asset-uploader/file-utils';

import { positions, sizes, widths, textAlignments, font, directions } from 'enums';

const AssetList = createComponent({
  displayName: 'AssetView',

  propTypes: {
    onAssetSelected: PropTypes.func.isRequired,
    list: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        assetRef: PropTypes.string,
        thumbnailAssetPath: PropTypes.string,
        height: PropTypes.number,
        width: PropTypes.number,
        fileSize: PropTypes.number,
        modifiedBy: PropTypes.string,
        lastUpdated: PropTypes.string,
      })
    ),
    isCompact: PropTypes.bool,
    userTimeZone: PropTypes.shape({
      name: PropTypes.string,
      offset: PropTypes.string,
    }),
  },
  defaultProps: {
    list: [],
    isCompact: false,
    userTimeZone: {
      name: 'Europe/London',
      offset: '0',
    },
  },

  handlers: {
    onSelect: (p) => (e, v) => {
      p.onAssetSelected(v.value);
    },
  },
  render(props) {
    const { list, onSelect, isCompact, userTimeZone } = props;
    const noPadding = { padding: 0 };

    return (
      <div className="asset-select">
        <div className="asset-select__header">
          <Layout
            columnStyles={{
              textAlign: textAlignments.CENTER,
              verticalAlign: positions.verticalAlign.MIDDLE,
            }}
          >
            <Layout.Row columns={widths.SEVEN}>
              <Layout.Column columnWidth={widths.TWO} textAlign={textAlignments.LEFT}>
                <Label text="Title" font={{ emphasis: font.emphasis.CAPS }} size={sizes.X2} style={noPadding} />
              </Layout.Column>
              <Layout.Column columnWidth={widths.FOUR} />
              <Layout.Column columnWidth={widths.TWO}>
                <Label text="Size" font={{ emphasis: font.emphasis.CAPS }} size={sizes.X2} style={noPadding} />
              </Layout.Column>
              <Layout.Column columnWidth={widths.TWO}>
                <Label text="Category" font={{ emphasis: font.emphasis.CAPS }} size={sizes.X2} style={noPadding} />
              </Layout.Column>
              <Layout.Column columnWidth={widths.TWO}>
                <Label text="Modified on" font={{ emphasis: font.emphasis.CAPS }} size={sizes.X2} style={noPadding} />
              </Layout.Column>
              <Layout.Column columnWidth={widths.TWO}>
                <Label text="Modified by" font={{ emphasis: font.emphasis.CAPS }} size={sizes.X2} style={noPadding} />
              </Layout.Column>
              <Layout.Column columnWidth={widths.TWO} />
            </Layout.Row>
          </Layout>
        </div>
        <div className={cx('asset-select__list', { 'asset-select__list--modal': isCompact })}>
          <Layout
            borders={directions.H}
            columnStyles={{
              textAlign: textAlignments.CENTER,
              verticalAlign: positions.verticalAlign.MIDDLE,
            }}
          >
            {_.map(list, (asset, assetIdx) => {
              let gmtTime = moment.tz(asset.lastUpdated, 'GMT'),
                userTime = gmtTime.tz(userTimeZone.name);

              const isPortrait = FileUtils.isAssetPortrait(asset.width, asset.height);
              return (
                <Layout.Row columns={widths.SEVEN} key={'asset' + assetIdx}>
                  <Layout.Column columnWidth={widths.TWO} textAlign={textAlignments.LEFT}>
                    <div
                      className={cx('file-thumbnail-wrapper', {
                        'file-thumbnail-wrapper--landscape': !isPortrait,
                        'file-thumbnail-wrapper--portrait': isPortrait,
                      })}
                    >
                      <CKImage src={`${asset.thumbnailAssetPath}?rnd=${asset.lastUpdated}`} />
                    </div>
                  </Layout.Column>
                  <Layout.Column columnWidth={widths.FOUR} textAlign={textAlignments.LEFT}>
                    <Label
                      text={asset.name}
                      isFluid
                      style={noPadding}
                      font={{ weight: font.weights.MEDIUM }}
                      size={sizes.MEDIUM}
                    />
                  </Layout.Column>
                  <Layout.Column columnWidth={widths.TWO}>
                    <Label
                      text={asset.width ? asset.width + 'x' + asset.height + 'px' : ''}
                      isFluid
                      style={noPadding}
                    />
                  </Layout.Column>
                  <Layout.Column columnWidth={widths.TWO}>
                    <Label text={asset.classRef.label} style={noPadding} isFluid />
                  </Layout.Column>
                  <Layout.Column columnWidth={widths.TWO}>
                    <Tooltip tooltip={userTime.format('MMM DD YYYY, HH:mm a z')}>
                      <Label text={userTime.fromNow()} style={noPadding} isFluid />
                    </Tooltip>
                  </Layout.Column>
                  <Layout.Column columnWidth={widths.TWO}>
                    <Label text={asset.modifiedBy.name} style={noPadding} isFluid />
                  </Layout.Column>
                  <Layout.Column columnWidth={widths.TWO}>
                    <Button.Primary text="Select" onClick={onSelect} value={asset} size={sizes.X1} />
                  </Layout.Column>
                </Layout.Row>
              );
            })}
          </Layout>
        </div>
      </div>
    );
  },
});

export default AssetList;
