import React from 'react';
import cx from 'classnames';
import { default as RCSlider } from 'rc-slider';
import _ from 'lodash';

import { positions } from 'enums';

import { createComponent, PropTypes } from 'wayin-react';

import Label from 'components/core/label';

import { whitelistStyles } from 'components/core/hoc';

const Slider = createComponent({
  displayName: 'Slider',
  propTypes:   {
    id:       PropTypes.string,
    value:    PropTypes.number,
    label:    PropTypes.string,
    minValue: PropTypes.number,
    maxValue: PropTypes.number,
    stepSize: PropTypes.number,
    steps:    PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
      })
    ),
    stepLabelPosition: PropTypes.oneOf([positions.RIGHT, positions.BOTTOM]),
    stepLabelWidth:    PropTypes.number,
    formatStepLabel:   PropTypes.func,
    isDisabled:        PropTypes.bool,
    onChange:          PropTypes.func,
    onBeforeChange:    PropTypes.func,
    onAfterChange:     PropTypes.func,
    isFluid:           PropTypes.bool,
    animationEnabled:  PropTypes.bool,
  },
  defaultProps: {
    id:                '',
    value:             0,
    label:             undefined,
    minValue:          undefined,
    maxValue:          undefined,
    stepSize:          undefined,
    steps:             [],
    stepLabelPosition: undefined,
    stepLabelWidth:    undefined,
    formatStepLabel:   x => _.identity(x),
    isDisabled:        false,
    onChange:          _.noop,
    onBeforeChange:    _.noop,
    onAfterChange:     _.noop,
    isFluid:           true,
    animationEnabled:  true,
  },
  contextTypes: {
    isInverted: PropTypes.bool,
  },
  state: {
    _sliderValue: {
      value: p => {
        return p.value;
      },
      updater: 'setSliderValue',
    },
    _active: {
      value:   p => false,
      updater: 'setActive',
    },
    _unfocusedClickProcess: {
      value:   p => false,
      updater: 'setUnfocusedClickProcess'
    }
  },
  lifecycles: {
    UNSAFE_componentWillUpdate(nextProps) {
      if (this.props.value !== nextProps.value) this.props.setSliderValue(nextProps.value);
    },
  },
  handlers: {
    handleOnBeforeChange: p => (value, e) => {
      const unfocusedClick = document.activeElement.className.indexOf('rc-slider-handle') === -1;

      if (!p.isDisabled) {
        if (unfocusedClick && !p._unfocusedClickProcess) {
          p.setUnfocusedClickProcess(true);
        } else {
          p.setActive(true);
          // because our _formatMarks function uses memoization, we can call it here with almost no performance hit
          p.onBeforeChange(e, { value, ...p.steps[value], id: p.id, label: _formatMarks(p)[value] });
        }
      }
    },
    handleOnChange: p => (value, e) => {
      //WYN-4668 [Victor]: basically this: http://www.lukekedz.com/rc-slider-onafterchange-event-behavior/
      const _activeElementCls = document.activeElement.className;

      const focusedClick = _activeElementCls === 'rc-slider-handle rc-slider-handle-click-focused';
      const unfocusedClick = _activeElementCls.indexOf('rc-slider-handle') === -1;
      const isDragging = _activeElementCls.indexOf('rc-slider-handle') !== -1 && p._active;

      if (!p.isDisabled && (unfocusedClick || focusedClick || isDragging)) {
        p.setSliderValue(value);
        // because our _formatMarks function uses memoization, we can call it here with almost no performance hit
        p.onChange(e, { value, ...p.steps[value], id: p.id, label: _formatMarks(p)[value] });
      }
    },
    handleOnAfterChange: p => (value, e) => {
      const _activeElementCls = document.activeElement.className;

      const focusedClick = _activeElementCls === 'rc-slider-handle rc-slider-handle-click-focused';
      const isDragging = _activeElementCls.indexOf('rc-slider-handle') !== -1 && p._active;

      if (!p.isDisabled) {
        if (focusedClick && isDragging)  p.setUnfocusedClickProcess(false);
        p.setActive(false);
        // because our _formatMarks function uses memoization, we can call it here with almost no performance hit
        p.onAfterChange(e, { value, ...p.steps[value], id: p.id, label: _formatMarks(p)[value] });
      }
    },
  },
  render(props) {
    let _minValue, _maxValue, _stepSize;

    logger.warn(
      'Slider: setting a `stepLabelWidth` when the `stepLabelPosition` is `bottom` will have no effect on label width',
      props.stepLabelPosition !== positions.RIGHT && props.stepLabelWidth !== undefined
    );

    logger.warn(
      'Slider: setting a `minValue` when providing "steps" will have no effect on minValue of the slider',
      !!props.minValue && !_.isEmpty(props.steps)
    );

    logger.warn(
      'Slider: setting a `maxValue` when providing "steps" will have no effect on minValue of the slider. We will use the size of your steps array as maxValue',
      !!props.maxValue && !_.isEmpty(props.steps)
    );

    logger.warn(
      'Slider: setting a `stepSize` when providing "steps" will have no effect on step size. It will always be 1.',
      !!props.stepSize && !_.isEmpty(props.steps)
    );

    if (_.isEmpty(props.steps)) {
      _minValue = props.minValue || 0;
      _maxValue = props.maxValue || 1;
      _stepSize = props.stepSize || 1;
    } else {
      _minValue = 0;
      _maxValue = props.steps.length - 1;
      _stepSize = 1;
    }

    const _marks = _formatMarks(props);

    const _cls = cx('ck', 'slider', `label-position--${props.stepLabelPosition}`, {
      inverted: props.isInverted,
      active:   props._active,
      fluid:    props.isFluid,
      disabled: props.isDisabled,
      animated: props.animationEnabled
    });

    return (
      <div className={_cls}>
        <If condition={!!props.label}>
          <Label text={props.label} />
        </If>
        <div className="slider-inner">
          <div className="slider-inner__slider">
            <RCSlider
              id={props.id}
              min={_minValue}
              max={_maxValue}
              step={_stepSize}
              value={props._sliderValue}
              marks={props.stepLabelPosition === positions.BOTTOM ? _marks : undefined}
              onBeforeChange={props.handleOnBeforeChange}
              onChange={props.handleOnChange}
              onAfterChange={props.handleOnAfterChange}
            />
          </div>
          <If condition={props.stepLabelPosition === positions.RIGHT}>
            <div className="slider-inner__label" style={{ width: props.stepLabelWidth }}>
              <Label text={`${_marks[props._sliderValue]}`} hasPadding={true} />
            </div>
          </If>
        </div>
      </div>
    );
  },
});

// memoized function will use cache unless one of the passed in props is changed
const _formatMarks = _.memoize(
  ({ minValue, maxValue, stepSize, steps, formatStepLabel }) => {
    let _marks = {};

    if (_.isEmpty(steps)) {
      // we don't have labels so we're going to build an object from the min, max and step values
      _.times(Math.round(maxValue - minValue + 1) / (stepSize || 1), n => {
        _marks[n] = formatStepLabel(n);
      });
    } else {
      _marks = _.reduce(
        steps,
        (acc, x, i) => {
          acc[i] = formatStepLabel(steps[i].label);
          return acc;
        },
        {}
      );
    }
    return _marks;
  },
  ({ minValue, maxValue, stepSize, steps }) => JSON.stringify({ minValue, maxValue, stepSize, steps })
);

export const PrivateSlider = Slider;
export default whitelistStyles()(Slider);
