// WARNING !!!!
// _setAutoCloseTimerProps as a function instead as an object seems not to persist the change, which results in an
// infinite loop of calling props.onClose() .
//
//  _setAutoCloseTimerProps({ isClosing: true }) VS _setAutoCloseTimerProps.isClosing=true
//
// Connor suggested that we should change this component to a class in a future release !
//
// ***********************************************************************************

import React from 'react';
import { createComponent, PropTypes, Utils } from 'wayin-react';
import classnames from 'classnames';
import { whitelistStyles } from 'components/core/hoc';
import { sizes, colors, icons, messageTypes, positions, timeouts } from 'enums';

import Icon from 'components/core/icon';
import Button from 'components/core/button';
import Animate from 'components/abstractions/animate';

const Message = createComponent({
  displayName: 'Message',
  propTypes:   {
    isDisplayed: PropTypes.bool,
    messageType: PropTypes.oneOf(messageTypes.ALL),
    padding:     PropTypes.oneOfType([
      PropTypes.oneOf(sizes.ALL),
      PropTypes.shape({
        horizontal: PropTypes.oneOf(sizes.ALL),
        vertical:   PropTypes.oneOf(sizes.ALL),
      }),
    ]),
    isGrowler:     PropTypes.bool,
    icon:          PropTypes.oneOfType([PropTypes.oneOf(icons.ALL), PropTypes.element]),
    verticalAlign: PropTypes.oneOf(positions.verticalAlign.ALL),

    children:  PropTypes.node.isRequired,
    onClose:   PropTypes.func,
    autoClose: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]), // in reality, this is either false or a number determining the timeout length
  },

  defaultProps: {
    messageType:   messageTypes.SUCCESS,
    isDisplayed:   false,
    isGrowler:     false,
    padding:       sizes.X4,
    icon:          null,
    autoClose:     false,
    onClose:       null,
    verticalAlign: positions.verticalAlign.TOP,
  },
  state: {
    autoCloseTimer: {
      value: {
        isClosing: false,
      },
      updater(_setAutoCloseTimerProps, props) {
        return {
          _autoClose: () => {
            // default the timeout to the message type if it's strictly true otherwise use the numeric value as the timeout
            const _timeout = props.autoClose === true ? timeouts[props.messageType] : props.autoClose;
            if (!props.autoCloseTimer.isClosing) {
              //start the closing process
              _setAutoCloseTimerProps.isClosing = true;
              setTimeout(function() {
                //finish the closing process
                _setAutoCloseTimerProps.isClosing = false;
                //fire the provided close method
                if (props.onClose) props.onClose();
              }, _timeout);
            }
          },
        };
      },
    },
  },

  lifecycles: {
    // if the component mounts for the first time and autoClose is not false we should initiate the close
    componentDidMount() {
      if (this.props.isDisplayed === true && !!this.props.autoClose) {
        this.props._autoClose();
      }
    },

    // if we update the state of our message to "show" and autoClose is not false we should start the autoclose
    //timer
    componentDidUpdate(prevProps, prevState) {
      if (this.props.isDisplayed === true && !!this.props.autoClose) {
        this.props._autoClose();
      }
    },
  },

  render(props) {
    let icon = null;
    switch (props.messageType) {
      case messageTypes.WARNING:
        icon = props.icon || icons.WARNING;
        break;

      case messageTypes.INFO:
        icon = props.icon || icons.INFO;
        break;

      case messageTypes.ERROR:
        //this could work with border and isCircle
        icon = props.icon || icons.WARNING;
        break;

      case messageTypes.SUCCESS:
        icon = props.icon || icons.APPROVE;
        break;

      default:
        icon = props.icon || icons.SUCCESS;
        break;
    }

    // a little messy but this allows us to override the icon or set it to nothing
    if (props.icon === false) icon = null;
    if (_.includes(icons, props.icon)) icon = props.icon;

    const classname = classnames('ck', 'message', {
      [`padding-${props.padding}`]:                       _.isString(props.padding),
      [`padding-vertical-${props.padding.vertical}`]:     !!props.padding.vertical,
      [`padding-horizontal-${props.padding.horizontal}`]: !!props.padding.horizontal,
      [`vertical-align--${props.verticalAlign}`]:         true,
      'growler-mode':                                     !!props.isGrowler,
      'has-onClose':                                      !!props.onClose,
      //use to dynamically assign type class : warning/error/etc.
      [`message-type--${props.messageType}`]:             !!props.messageType,
    });

    return (
      <Animate.OnMountChange onMountAnimation="fadeIn" onUnmountAnimation="fadeOut">
        <If condition={props.isDisplayed}>
          <div className={classname} style={props.style}>
            <div className="message__inner">
              <If condition={!!icon}>
                <div className="message__icon">
                  <Choose>
                    <When condition={Utils.isType([Icon], icon)}>{icon}</When>
                    <Otherwise>
                      <Icon size={sizes.X8} name={icon} color={colors.BASE} />
                    </Otherwise>
                  </Choose>
                </div>
              </If>
              <div className="message__content">{props.children}</div>
              <If condition={props.onClose}>
                <div className="message__close">
                  <Button.Secondary
                    onClick={props.onClose}
                    isCompact={true}
                    color={colors.WHITE}
                    icon={<Icon size={sizes.X6} name={icons.CANCEL} />}
                  />
                </div>
              </If>
            </div>
          </div>
        </If>
      </Animate.OnMountChange>
    );
  },
});

export default whitelistStyles()(Message);
