import React from 'react';
import { createComponent, PropTypes } from 'wayin-react';

/**
 * layout for presenting a thumbnail and content as asset overview
 * will show the thumbnailContent in a smaller column, horizontally aligned to the center and vertically aligned to the middle
 * will show the metaContent vertically aligned to the middle
 * Not using <Layout> because we have to set a fixed width on the 'thumbnailContent' wrapper and the rest of the space has to be taken by the  'metaContent' wrapper
 */
const AssetOverview = createComponent({
  displayName: 'AssetOverview',

  propTypes: {
    thumbnailContent: PropTypes.element.isRequired,
    metaContent:      PropTypes.element.isRequired,
  },

  render(props) {
    const { thumbnailContent, metaContent } = props;
    return (
      <div className="asset-overview-layout">
        <div className="asset-overview-layout__column asset-overview-layout__thumbnail-wrapper">{thumbnailContent}</div>
        <div className="asset-overview-layout__column asset-overview-layout__content-wrapper">{metaContent}</div>
      </div>
    );
  },
});

export default AssetOverview;
