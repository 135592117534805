import React from 'react';
import { createComponent, PropTypes } from 'wayin-react';
import { wrapDisplayName } from 'recompose';
import { positions, colors } from 'enums';
import Tooltip from './tooltip';

/* INTERFACE */
const propTypes = {
  tooltip:         PropTypes.string,
  tooltipPosition: PropTypes.oneOf(positions.ALL),
};

const defaultProps = {
  tooltip:         null,
  tooltipPosition: positions.TOP,
  tooltipColor:    colors.BASE,
};

/* RENDER */
const render = ({ isInline, isCompact }, Component) => props => {
  const childProps = _.omit(props, ['tooltip', 'tooltipPosition', 'tooltipColor']);
  return (
    <Choose>
      <When condition={hasTooltip(props)}>
        <Tooltip
          tooltip={props.tooltip}
          position={props.tooltipPosition}
          color={props.tooltipColor}
          isInline={isInline}
          isCompact={isCompact}
        >
          <Component {...childProps} />
        </Tooltip>
      </When>
      <Otherwise>
        <Component {...childProps} />
      </Otherwise>
    </Choose>
  );
};

/* BLUEPRINT */
const tooltipify = (config = {}) => Component => {
  return createComponent({
    displayName: wrapDisplayName(Component, 'tooltipify'),
    propTypes,
    defaultProps,
    render:      render(config, Component),
  });
};

/* PRIVATE HELPERS */
const hasTooltip = ({ tooltip }) => !_.isNil(tooltip);

export default tooltipify;
