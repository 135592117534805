import React from 'react';
import _ from 'lodash';
import { createComponent, PropTypes } from 'wayin-react';
import classnames from 'classnames';
import { whitelistStyles } from 'components/core/hoc';

const ColorSwatch = createComponent({
  displayName: 'color swatch',
  PropTypes: {
    value: PropTypes.string,
    onClick: PropTypes.func,
    hasBorder: PropTypes.bool,
  },
  defaultProps: {
    value: undefined,
    onClick: undefined,
    hasBorder: false,
  },
  contextTypes: {
    isInverted: PropTypes.bool,
  },
  handlers: {
    handleClick: p => e => {
      p.onClick &&
        p.onClick(e, {
          value: p.value, // pass current value
        });
    },
  },
  render(props) {
    const _color = !!props.value && colorToRGBA(props.value);
    const _isValidColor = !!_color;

    const _cls = classnames('ck color-swatch', {
      ['color-swatch--clickable']: !!props.onClick,
      ['color-swatch--inverted']: props.isInverted,
      ['color-swatch--has-border']: props.hasBorder,
      ['color-swatch--invalid-color']: !_isValidColor && !!props.value,
      ['color-swatch--transparent-value']: props.value === 'transparent',
    });

    const _style = _isValidColor ? { backgroundColor: props.value } : {};

    return <div onClick={props.handleClick} className={_cls} style={_style} />;
  },
});

const colorToRGBA = _.memoize(
  color => {
    const canvas = document.createElement('canvas');
    canvas.width = canvas.height = 1;
    const ctx = canvas.getContext('2d');

    if (!!ctx) {
      ctx.clearRect(0, 0, 1, 1);
      // In order to detect invalid values,
      // we can't rely on col being in the same format as what fillStyle is computed as,
      // but we can ask it to implicitly compute a normalized value twice and compare.
      ctx.fillStyle = '#000';
      ctx.fillStyle = color;
      const computed = ctx.fillStyle;
      ctx.fillStyle = '#fff';
      ctx.fillStyle = color;
      if (computed !== ctx.fillStyle) {
        return; // invalid color
      }
      ctx.fillRect(0, 0, 1, 1);
      return [...ctx.getImageData(0, 0, 1, 1).data];
    }
  },
  color => color
);

export default whitelistStyles()(ColorSwatch);
