const videoServices = {
  YOUTUBE:   'youtube',
  VIMEO:     'vimeo',
  INSTAGRAM: 'instagram',
  VINE:      'vine',
  TWITTER:   'twitter',
  FACEBOOK:  'facebook',
  GIF:       'gif',
  HTML5:     'html5',
  URL:       'url',
  MUX:       'mux',
};

videoServices.ALL = _.values(videoServices);

export default videoServices;
