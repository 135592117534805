import React from 'react';
import { createComponent, PropTypes } from 'wayin-react';
import classnames from 'classnames';
import { whitelistStyles } from 'components/core/hoc';
import { sizes } from 'enums';

import Header from 'components/core/header';

const Panel = createComponent({
  displayName: 'Panel',
  propTypes:   {
    header:       PropTypes.string,
    byline:       PropTypes.string,
    children:     PropTypes.node,
    hasBorder:    PropTypes.bool,
    childSpacing: PropTypes.oneOf(sizes.ALL),
    padding:      PropTypes.oneOfType([
      PropTypes.oneOf(sizes.ALL),
      PropTypes.shape({
        horizontal: PropTypes.oneOf(sizes.ALL),
        vertical:   PropTypes.oneOf(sizes.ALL),
      }),
    ]),
  },
  defaultProps: {
    header:       null,
    byline:       null,
    children:     null,
    hasBorder:    true,
    childSpacing: sizes.X4,
    padding:      sizes.X4,
  },
  contextTypes: {
    isInverted: PropTypes.bool,
  },
  render(props) {
    /*
       no defaults for horizontal and vertical padding props, in order
       for null value to set padding to zero.
    */
    const classname = classnames('ck-panel', props.childSpacing, {
      [`padding-${props.padding}`]:                       _.isString(props.padding),
      [`padding-vertical-${props.padding.vertical}`]:     !!props.padding.vertical,
      [`padding-horizontal-${props.padding.horizontal}`]: !!props.padding.horizontal,
      bordered:                                           props.hasBorder,
      inverted:                                           props.isInverted,
    });
    return (
      <div className={classname} style={props.style}>
        <If condition={!!props.header || !!props.byline}>
          <Header text={props.header} byline={props.byline} />
        </If>
        {props.children}
      </div>
    );
  },
});

export default whitelistStyles()(Panel);
