import moment from 'moment-timezone';
import _ from 'lodash';

import { videoServices } from 'enums';
import { regexMap as videoServicesRegexMap } from '../util/third-party-url';
const Validators = {};

//  Exporting these for testing
const RequiredMessage = 'This field is required';
const InvalidEmailMessage = 'Enter a valid email';
const InvalidInputMessage = 'Input Value Invalid';
const InvalidURLMessage = 'Enter a valid URL';
const NotANumberMessage = 'Enter a number';
const NotAColorMessage = 'Enter a valid color';
const NotWithinRangeMessage = 'Enter a number between $min and $max';
const NotIntegerMessage = 'Enter a whole number';
const NotWithinCharLimitMessage = 'More than $max characters';
const NotWithinDateRangeMessage = 'Date not within range';
const NotMinLengthMessage = 'Less than $min characters';
const NotMinValueMessage = 'Needs to be greater than or equal to $min';
const NotMaxValueMessage = 'Needs to be less than or equal to $max';
const InvalidOrUnacceptedVideoUrl = 'Invalid or unaccepted video url';

const ACTION_REGEX = /^action:[A-Za-z0-9\-]+$/;
const NUMBER_REGEX = /^[0-9]*$/;

// PROTOCOL RELATIVE URL
// see test cases here https://regexr.com/5v34o (old tests are at https://regexr.com/599g5)
const PRURL_REGEX = /^(https?:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?|^(https?:\/\/)?(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])(:\d+)?(\/.*)?$/;

// URL
// see test cases here: https://regexr.com/599gh
const URL_REGEX = /^(https?:\/\/)[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?|^(https?:\/\/)(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])(:\d+)*$/;

// Email
// [Victor] JDX-192
const EMAIL_REGEX = /^(?:[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-zA-Z0-9-]*[a-zA-Z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;

const ROUTE_REGEX = /^route:[A-Za-z0-9\-]+$/;
const HEX_REGEX = /^#(?:[A-Fa-f0-9]{3}){1,2}$|^#(?:[A-Fa-f0-9]{4}){1,2}$/;
const RGB_REGEX = /^rgb[(](?:\s*0*(?:\d\d?(?:\.\d+)?(?:\s*%)?|\.\d+\s*%|100(?:\.0*)?\s*%|(?:1\d\d|2[0-4]\d|25[0-5])(?:\.\d+)?)\s*(?:,(?![)])|(?=[)]))){3}[)]$/;
const RGBA_REGEX = /^rgba[(](?:\s*0*(?:\d\d?(?:\.\d+)?(?:\s*%)?|\.\d+\s*%|100(?:\.0*)?\s*%|(?:1\d\d|2[0-4]\d|25[0-5])(?:\.\d+)?)\s*,){3}\s*0*(?:\.\d+|1(?:\.0*)?)\s*[)]$/;
const HSL_REGEX = /^hsl[(]\s*0*(?:[12]?\d{1,2}|3(?:[0-5]\d|60))\s*(?:\s*,\s*0*(?:\d\d?(?:\.\d+)?\s*%|\.\d+\s*%|100(?:\.0*)?\s*%)){2}\s*[)]$/;
const HSLA_REGEX = /^hsla[(]\s*0*(?:[12]?\d{1,2}|3(?:[0-5]\d|60))\s*(?:\s*,\s*0*(?:\d\d?(?:\.\d+)?\s*%|\.\d+\s*%|100(?:\.0*)?\s*%)){2}\s*,\s*0*(?:\.\d+|1(?:\.0*)?)\s*[)]$/;

const HTML_COLOR_NAMES = [
  'AliceBlue',
  'AntiqueWhite',
  'Aqua',
  'Aquamarine',
  'Azure',
  'Beige',
  'Bisque',
  'Black',
  'BlanchedAlmond',
  'Blue',
  'BlueViolet',
  'Brown',
  'BurlyWood',
  'CadetBlue',
  'Chartreuse',
  'Chocolate',
  'Coral',
  'CornflowerBlue',
  'Cornsilk',
  'Crimson',
  'Cyan',
  'DarkBlue',
  'DarkCyan',
  'DarkGoldenrod',
  'DarkGray',
  'DarkGreen',
  'DarkKhaki',
  'DarkMagenta',
  'DarkOliveGreen',
  'DarkOrange',
  'DarkOrchid',
  'DarkRed',
  'DarkSalmon',
  'DarkSeaGreen',
  'DarkSlateBlue',
  'DarkSlateGray',
  'DarkTurquoise',
  'DarkViolet',
  'DeepPink',
  'DeepSkyBlue',
  'DimGray',
  'DodgerBlue',
  'FireBrick',
  'FloralWhite',
  'ForestGreen',
  'Fuchsia',
  'Gainsboro',
  'GhostWhite',
  'Gold',
  'Goldenrod',
  'Gray',
  'Green',
  'GreenYellow',
  'HoneyDew',
  'HotPink',
  'IndianRed',
  'Indigo',
  'Ivory',
  'Khaki',
  'Lavender',
  'LavenderBlush',
  'LawnGreen',
  'LemonChiffon',
  'LightBlue',
  'LightCoral',
  'LightCyan',
  'LightGoldenrodYellow',
  'LightGray',
  'LightGreen',
  'LightPink',
  'LightSalmon',
  'LightSalmon',
  'LightSeaGreen',
  'LightSkyBlue',
  'LightSlateGray',
  'LightSteelBlue',
  'LightYellow',
  'Lime',
  'LimeGreen',
  'Linen',
  'Magenta',
  'Maroon',
  'MediumAquamarine',
  'MediumBlue',
  'MediumOrchid',
  'MediumPurple',
  'MediumSeaGreen',
  'MediumSlateBlue',
  'MediumSlateBlue',
  'MediumSpringGreen',
  'MediumTurquoise',
  'MediumVioletRed',
  'MidnightBlue',
  'MintCream',
  'MistyRose',
  'Moccasin',
  'NavajoWhite',
  'Navy',
  'OldLace',
  'Olive',
  'OliveDrab',
  'Orange',
  'OrangeRed',
  'Orchid',
  'PaleGoldenrod',
  'PaleGreen',
  'PaleTurquoise',
  'PaleVioletRed',
  'PapayaWhip',
  'PeachPuff',
  'Peru',
  'Pink',
  'Plum',
  'PowderBlue',
  'Purple',
  'RebeccaPurple',
  'Red',
  'RosyBrown',
  'RoyalBlue',
  'SaddleBrown',
  'Salmon',
  'SandyBrown',
  'SeaGreen',
  'SeaShell',
  'Sienna',
  'Silver',
  'SkyBlue',
  'SlateBlue',
  'SlateGray',
  'Snow',
  'SpringGreen',
  'SteelBlue',
  'Tan',
  'Teal',
  'Thistle',
  'Tomato',
  'Transparent',
  'Turquoise',
  'Violet',
  'Wheat',
  'White',
  'WhiteSmoke',
  'Yellow',
  'YellowGreen',
];

const TWEET_MAX_LENGTH = 140;

const formatNumberMessage = (errorMsg, min, max) => errorMsg.replace('$min', min).replace('$max', max);

const formatCharacterLimitMessage = (errorMsg, max) => errorMsg.replace('$max', max);
const formatMinLengthMessage = (errorMsg, min) => errorMsg.replace('$min', min);

/**
  isRequired Validator: Reports error when value is undefined or empty
  params:
    errorMessage - custom error message to report

  returns:
    errorMessage if invalid
    true if valid
**/
Validators.isRequired = (errorMessage = RequiredMessage) => (value) => {
  const _value = typeof value === 'number' ? value.toString() : value;
  if (!_value || _value.trim() === '') {
    return errorMessage;
  }
  return true;
};

/**
  inputfield validator:  Validates length and against a list of regexes.

  params:
    Object -> {
      errorMessage - custom error message
      maxLength - maximum supported length
      minLength - minimum supported length
      regexes - a list of regular expressions where one regex must pass for value to valid

  returns:
    errorMessage if invalid
    true if valid
**/
Validators.inputField = ({ errorMessage = InvalidInputMessage, maxLength, minLength, regexes }) => value => {
  //  allow undefined or empty
  // for CTADropdown allow default input value http:// or 'url' for dropdown select issue
  if (value === undefined || value === '' || value === 'http://' || value === 'url') {
    return true;
  }

  //  using 0 length if value is undefined
  const length = _.get(value, 'length') || 0;

  if (maxLength && length > maxLength) {
    return errorMessage;
  }
  if (minLength && length < minLength) {
    return errorMessage;
  }

  //  iterate through the provided regexes and determine if there is one that passes
  if (_.get(regexes, 'length')) {
    if (_.find(regexes, regex => regex.test(value)) === undefined) {
      return errorMessage;
    }
  }

  return true;
};

/*
  hasMinLength - Validate that an input string has atleast min length.
    params:
      minLength - minimum length
      errorMessage - custom error message
    returns:
      error message if invalid
      true if valid
*/
Validators.minLength = (minLength, errorMessage = NotMinLengthMessage) =>
  Validators.inputField({
    errorMessage: formatMinLengthMessage(errorMessage, minLength),
    minLength,
  });

/*
  isWithinCharacterLimit - Validates value to be within a character limit (i.e. 140 character tweet)
  params:
      maxLength - max character limit
      errorMessage - custom error message
  returns:
    errorMessage if invalid
    true if valid
*/
Validators.maxLength = (maxLength, errorMessage = NotWithinCharLimitMessage) =>
  Validators.inputField({
    errorMessage: formatCharacterLimitMessage(errorMessage, maxLength),
    maxLength,
  });

/*
  isNumberWithinRange - Validates that the value is within the provided range.
  params:
    Object -> {
      errorMessage - custom error message
      min - minimum of range
      max - maximum of range
    }

  returns:
    errorMessage if invalid
    true if valid
*/
Validators.isWithinNumberRange = (min, max, errorMessage = NotWithinRangeMessage) => value => {
  errorMessage = formatNumberMessage(errorMessage, min, max);

  //  allow undefined or empty
  if (value === undefined || value === '') {
    return true;
  }

  const number = parseFloat(value, 10);

  if (isNaN(number)) {
    return errorMessage;
  }
  if (number < min || number > max) {
    return errorMessage;
  }
  return true;
};

Validators.minValue = (min, errorMessage = NotMinValueMessage) => value => {
  errorMessage = formatNumberMessage(errorMessage, min);

  //  allow undefined or empty
  if (value === undefined || value === '') {
    return true;
  }

  const number = parseFloat(value, 10);

  if (isNaN(number)) {
    return errorMessage;
  }
  if (number < min) {
    return errorMessage;
  }
  return true;
};

Validators.maxValue = (max, errorMessage = NotMaxValueMessage) => value => {
  errorMessage = formatNumberMessage(errorMessage, undefined, max);

  //  allow undefined or empty
  if (value === undefined || value === '') {
    return true;
  }

  const number = parseFloat(value, 10);

  if (isNaN(number)) {
    return errorMessage;
  }
  if (number > max) {
    return errorMessage;
  }
  return true;
};

Validators.isInteger = (errorMessage = NotIntegerMessage) => value => {
  //  allow undefined or empty
  if (value === undefined || value === '') {
    return true;
  }
  const number = parseFloat(value, 10);
  if (!Number.isInteger(number)) {
    return errorMessage;
  }
  return true;
};

Validators.isWithinDateRange = (minDate, maxDate, errorMessage = NotWithinDateRangeMessage) => value => {
  const dateToValidate = moment(value);
  if (!dateToValidate.isBetween(minDate, maxDate)) {
    return errorMessage;
  }
  return true;
};

/**
 * Validates whether the provided value is a URL. Protocol is optional
 * @param {string} [errorMessage=InvalidUrlMessage] - optional custom error message
 * @returns {string|boolean} - string is errorMessage if invalid. Otherwise returns true
 */
Validators.isPRURL = (errorMessage = InvalidURLMessage) => {
  return Validators.inputField({
    errorMessage,
    regexes: [PRURL_REGEX],
  });
};


/**
 * Validates whether the provided value is a valid email address.
 * @param {string} [errorMessage=InvalidEmailMessage] - optional custom error message
 * @returns {string|boolean} - string is errorMessage if invalid. Otherwise returns true
 */
Validators.isValidEmailFormat = (errorMessage = InvalidEmailMessage) => {
  return Validators.inputField({
    errorMessage,
    regexes: [EMAIL_REGEX],
  });
};

/**
 * Validates whether the provided value is a URL. Protocol is required
 * @param {string} [errorMessage=InvalidUrlMessage] - optional custom error message
 * @returns {string|boolean} - string is errorMessage if invalid. Otherwise returns true
 */
Validators.isURL = (errorMessage = InvalidURLMessage) => {
  return Validators.inputField({
    errorMessage,
    regexes: [URL_REGEX],
  });
};

Validators.isCTALink = (errorMessage = InvalidURLMessage) => {
  return Validators.inputField({
    errorMessage,
    regexes: [PRURL_REGEX, ROUTE_REGEX, ACTION_REGEX],
  });
};
Validators.isNumber = (errorMessage = NotANumberMessage) => {
  return Validators.inputField({
    errorMessage,
    regexes: [NUMBER_REGEX],
  });
};

Validators.isColor = (errorMessage = NotAColorMessage) => value => {
  let _isValidRegex = true;
  const regexes = [HEX_REGEX, RGB_REGEX, RGBA_REGEX, HSL_REGEX, HSLA_REGEX];

  _isValidRegex = Validators.inputField({
    errorMessage,
    regexes,
  })(value);

  if (_isValidRegex === true) return true;

  // if you got this far, it's passed the regex check - you now need to check if it's an HTML color name
  return _.find(HTML_COLOR_NAMES, color => color.toLowerCase() === value.toLowerCase()) ? true : errorMessage;
};

/**
 * validates a video url against different video services
 * @param acceptedVideoServices - array of videoServices
 * @param errorMessage - error message to return when url is invalid
 */
Validators.isVideoUrl = (
  acceptedVideoServices = videoServices.ALL,
  errorMessage = InvalidOrUnacceptedVideoUrl
) => {
  const regexes = _.reduce(
    videoServicesRegexMap,
    (acc, patt, service) => {
      if (_.indexOf(acceptedVideoServices, service) > -1) {
        acc.push(patt);
      }
      return acc;
    },
    []
  );
  return Validators.inputField({
    errorMessage,
    regexes,
  });
};

//  Some common error copy
Validators.Messages = {
  RequiredMessage,
  InvalidInputMessage,
  InvalidURLMessage,
  NotANumberMessage,
};

Validators.Regexes = {
  ACTION_REGEX,
  ROUTE_REGEX,
  URL_REGEX,
  NUMBER_REGEX,
  PRURL_REGEX,
  EMAIL_REGEX,
};

export default Validators;

export const _test = {
  RequiredMessage,
  InvalidEmailMessage,
  InvalidInputMessage,
  InvalidURLMessage,
  NotANumberMessage,
  NotAColorMessage,
  NotIntegerMessage,
  NotWithinRangeMessage,
  NotWithinCharLimitMessage,
  NotWithinDateRangeMessage,
  NotMinLengthMessage,
  NotMinValueMessage,
  NotMaxValueMessage,
  NUMBER_REGEX,
  TWEET_MAX_LENGTH,
  PRURL_REGEX,
  EMAIL_REGEX,
  formatNumberMessage,
  formatCharacterLimitMessage,
  formatMinLengthMessage,
};
