import React from 'react';
import { createComponent, PropTypes } from 'wayin-react';
import classnames from 'classnames';

import { sizes, colors } from 'enums';
import { extractElement, toUIProps, cloneChildrenWithDefaultProps } from 'helpers';
import { whitelistStyles } from 'components/core/hoc';

import { Button as UIButton } from 'semantic-ui-react';

const propMap = {
  isFluid: 'fluid',
  float:   'floated',
};

const UNSUPPORTED_PROPS = [
  'attached',
  'as',
  'className',
  'basic',
  'floated',
  'icon',
  'labeled',
  'vertical',
  'widths',
];

const ADDED_PROPS = ['spacing', 'isFluid', 'isInverted', 'layout'];

const MODIFIED_PROPS = ['color', 'onClick', 'children'];

const ButtonGroup = createComponent({
  displayName: 'ButtonGroup',
  propTypes:   {
    children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)])
      .isRequired, // Buttons only
    color:    PropTypes.oneOf(colors.CORE),
    isFluid:  PropTypes.bool,
    layout:   PropTypes.oneOf(['horizontal', 'vertical']),
    size:     PropTypes.oneOf(sizes.ALL),
    spacing:  PropTypes.oneOf(sizes.S_M_L),
    onClick:  PropTypes.func,
  },
  defaultProps: {
    color:   colors.ACCENT,
    isFluid: false,
    layout:  'horizontal',
    size:    sizes.X3,
    spacing: undefined,
    onClick: undefined,
  },
  contextTypes: {
    isInverted: PropTypes.bool,
  },
  render(props) {
    const UIProps = toUIProps({ props, propMap, MODIFIED_PROPS, UNSUPPORTED_PROPS, ADDED_PROPS });
    const children = cloneChildrenWithDefaultProps(props, ['onClick', 'color', 'size']);

    if (props.layout === 'vertical') {
      UIProps.vertical = true;
    }

    UIProps.className = classnames(
      'ck',
      props.spacing,
      { [`${props.layout}-spacing`]: !!props.spacing }, // needs to stay in this order
      { fluid: props.isFluid }
    );

    return (
      <UIButton.Group {...UIProps}>
        {children}
      </UIButton.Group>
    );
  },
});

export const PrivateButtonGroup = whitelistStyles()(ButtonGroup);
export default PrivateButtonGroup;
