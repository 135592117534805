//  A list of icons
const icons = {
  ACTIVITY: 'activity',
  ADVOCACY: 'advocacy',
  ADVOCATE: 'advocate',
  ALIGN_CENTER: 'align-center',
  ALIGN_LEFT: 'align-left',
  ALIGN_RIGHT: 'align-right',
  APP: 'app',
  APPROVE: 'approve',
  ARCHIVE: 'archive',
  ARROW_FIRST: 'arrow-first',
  ARROW_LAST: 'arrow-last',
  ARROW_NEXT: 'arrow-next',
  ARROW_PREVIOUS: 'arrow-previous',
  ASSETS: 'assets',
  AUDIENCE: 'audience',
  BAZAARVOICE: 'bazaarvoice',
  BOOKMARK: 'bookmark',
  BOOK_SEARCH: 'book-search',
  BRANCHING: 'branching',
  BROADCAST: 'broadcast',
  BUG: 'bug',
  BULLET: 'bullet',
  CALENDAR: 'calendar',
  CAMPAIGN: 'campaign',
  CANCEL: 'cancel',
  CAROUSEL: 'carousel',
  CAROUSEL_ACTIVE: 'carousel-active',
  CART: 'cart',
  CHAMPION: 'champion',
  CHANNEL: 'channel',
  CHART: 'chart',
  CHECK_SELECTED: 'check-selected',
  CHECK_UNSELECTED: 'check-unselected',
  CHEERLEADER: 'cheerleader',
  CLOSE: 'close',
  CLOUD: 'cloud',
  CODE: 'code',
  COL: 'col',
  COL2: 'col2',
  COL3: 'col3',
  COLL: 'colL',
  COLR: 'colR',
  COMMENT: 'comment',
  COMMENTS: 'comments',
  COMPETITION: 'competition',
  CONFIG: 'config',
  CONNECT: 'connect',
  CONTACT: 'contact',
  CONTENT: 'content',
  CONTEST: 'contest',
  COPY: 'copy',
  COPYRIGHT: 'copyright',
  COUNTDOWN: 'countdown',
  COUPON: 'coupon',
  CTA: 'cta',
  CURSOR: 'cursor',
  DANGER: 'danger',
  DESKTOP: 'desktop',
  DICE: 'dice',
  DISCONNECT: 'disconnect',
  DOWN: 'down',
  DOWNLOAD: 'download',
  EDIT: 'edit',
  EMAIL: 'email',
  ENGAGED: 'engaged',
  EXCLUSIVE: 'exclusive',
  FACEBOOK: 'facebook',
  FACEBOOK_OUTLINE: 'facebook-outline',
  FAVOURITE: 'favourite',
  FAVOURITE_SOLID: 'favourite-solid',
  FEED: 'feed',
  FILE: 'file',
  FILE_CHECKED: 'file-checked',
  FILTER: 'filter',
  FLICKR: 'flickr',
  FOLDER: 'folder',
  FORM: 'form',
  FRAU: 'frau',
  FULLSCREEN: 'fullscreen',
  FUNCTION: 'function',
  GALLERY: 'gallery',
  GAME: 'game',
  GOOGLEPLUS: 'googleplus',
  GRID: 'grid',
  GRID_ITEMS: 'grid-items',
  GRID_LARGE: 'grid-large',
  GRID_SMALL: 'grid-small',
  HAPPY: 'happy',
  HEART: 'heart',
  HEIGHT: 'height',
  HELP: 'help',
  HELP_STRONG: 'help-strong',
  HOME: 'home',
  IMAGE: 'image',
  INBOX: 'inbox',
  INFINITE: 'infinite',
  INFO: 'info',
  INHERIT: 'inherit',
  INSIGHTS: 'insights',
  INSTAGRAM: 'instagram',
  INTERACT: 'interact',
  INVITE: 'invite',
  JOIN: 'join',
  JOURNEY_MAP: 'journey-map',
  KEEP: 'keep',
  LAYOUT: 'layout',
  LEGO: 'lego',
  LINE: 'line',
  LINEHEIGHT: 'lineheight',
  LINKEDIN: 'linkedin',
  LITHIUM: 'lithium',
  LIVE: 'live',
  LIVE2: 'live2',
  LOCATION: 'location',
  LOCKED: 'locked',
  LOGOUT: 'logout',
  MAN: 'man',
  MAP: 'map',
  MENU: 'menu',
  MINION: 'minion',
  MINUS: 'minus',
  MOBILE: 'mobile',
  MODERATE: 'moderate',
  MODULE: 'module',
  MORE_VERTICAL: 'more-vertical',
  MOSAIC: 'mosaic',
  MOVE: 'move',
  NAV: 'nav',
  NEUTRAL: 'neutral',
  NEWSCRED: 'newscred',
  NEXT: 'next',
  NUMBER: 'number',
  OPEN: 'open',
  PACMAN: 'pacman',
  PAUSE: 'pause',
  PHONE: 'phone',
  PIN: 'pin',
  PINTEREST: 'pinterest',
  PLAY: 'play',
  PLAY3: 'play3',
  PLUS: 'plus',
  POLYGONAL: 'polygonal',
  PREMIUM: 'premium',
  PREVIEW: 'preview',
  PREVIEW_OFF: 'preview-off',
  PREVIOUS: 'previous',
  PRICING: 'pricing',
  PROMOTE: 'promote',
  PROMOTE2: 'promote2',
  PROMOTION: 'promotion',
  PUBLISH: 'publish',
  QUIZ: 'quiz',
  QUOTE: 'quote',
  RADIO_SELECTED: 'radio-selected',
  RADIO_UNSELECTED: 'radio-unselected',
  RANKINGS: 'rankings',
  REFRESH: 'refresh',
  REPLY: 'reply',
  RESULTS: 'results',
  RETWEET: 'retweet',
  REVIEW: 'review',
  ROBOTMOD: 'robotmod',
  ROCKET: 'rocket',
  ROWS: 'rows',
  RSS: 'rss',
  RULES: 'rules',
  SAD: 'sad',
  SCIENCE: 'science',
  SCORE: 'score',
  SCORE_QUIZ: 'score-quiz',
  SCREENDESIGNER: 'screendesigner',
  SCREENS: 'screens',
  SEARCH: 'search',
  SECURITY: 'security',
  SETTINGS: 'settings',
  SETTINGS2: 'settings2',
  SHARE: 'share',
  SHARE2: 'share2',
  SHOCKED: 'shocked',
  SHOP: 'shop',
  SIGNUP: 'signup',
  SINE: 'sine',
  SMILEY: 'smiley',
  SNAPCHAT: 'snapchat',
  SOCIALPLUGIN: 'socialplugin',
  SOCIALWALL: 'socialwall',
  STAGING: 'staging',
  STARBURST: 'starburst',
  STOP: 'stop',
  STRESS: 'stress',
  SUPERFAN: 'superfan',
  SWITCH: 'switch',
  TABLET: 'tablet',
  TALK: 'talk',
  TARGET: 'target',
  TARGET2: 'target2',
  TEMPLATE: 'template',
  TEST: 'test',
  TEXT: 'text',
  THEME: 'theme',
  THEMERIZE: 'themerize',
  TROPHY: 'trophy',
  TUMBLR: 'tumblr',
  TWITTER: 'twitter',
  UNPUBLISH: 'unpublish',
  UP: 'up',
  UPLOAD: 'upload',
  USER: 'user',
  VIDEO: 'video',
  VIDEOGALLERY: 'videogallery',
  VIMEO: 'vimeo',
  VINE: 'vine',
  VK: 'vk',
  VOTING: 'voting',
  WARNING: 'warning',
  WARNING_SOLID: 'warning-solid',
  WEB: 'web',
  WIDTH: 'width',
  YOUTUBE: 'youtube',
};

icons.ALL = _.values(icons);

export default icons;
