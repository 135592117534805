import React from 'react';
import { compose } from 'recompose';
import cx from 'classnames';

import { createComponent, PropTypes } from 'wayin-react';
import { whitelistStyles, mapHandlers } from 'components/core/hoc';

import { tooltipify } from 'components/core/tooltip';

import Input from 'components/core/input';
import Button from 'components/core/button';
import Icon from 'components/core/icon';

import { positions } from 'enums';

const BigSearch = createComponent({
  displayName:  'BigSearchCmp',
  propTypes: {
    initialValue:     PropTypes.string,
    onChange:         PropTypes.func,
    onSubmit:         PropTypes.func,
    placeholder:      PropTypes.string,
    actionText:       PropTypes.string,
    isDisabledAction: PropTypes.bool,
  },
  defaultProps: {
    initialValue:     '',
    onChange:         _.noop,
    onSubmit:         undefined,
    placeholder:      undefined,
    actionText:       undefined,
    isDisabledAction: false,
  },
  state: {
    searchValue: {
      value:   p => p.initialValue,
      updater: 'setSearchValue',
    },
  },
  lifecycles: {
    UNSAFE_componentWillReceiveProps(nextProps) {
      // new prop initialValue should override local scope searchValue
      if (this.props.initialValue !== nextProps.initialValue && nextProps.searchValue !== nextProps.initialValue) {
        this.props.setSearchValue(nextProps.initialValue);
      }
    },
  },
  handlers: {
    handleClear: p => e => {
      p.setSearchValue('');
      p.onChange(e, { value: '' });
      !!p.onSubmit && p.onSubmit(e, { value: '' });
    },
    handleInputChange: p => (e, { value }) => {
      p.setSearchValue(value);
      p.onChange(e, { value });
    },
    handleSubmit: p => e => {
      e.preventDefault();
      !!p.onSubmit && p.onSubmit(e, { value: p.searchValue });
    },
  },
  render(p) {
    const _cx = cx({
      'big-search': true,
    });

    const isPopulated = p.searchValue || p.initialValue;
    const input = (
      <Input
        isDisabledAction={p.isDisabledAction}
        isFluid
        icon={{ content: 'search', position: positions.LEFT }}
        innerLabel={
          isPopulated ?
          {
            content:  <Icon name="cancel" onClick={p.handleClear} />,
            position: positions.RIGHT,
          } :
            undefined
        }
        placeholder={p.placeholder}
        onChange={p.handleInputChange}
        //[Victor]: this will allow us to change the value to empty on the search query text in CS moderation search
        value={p.searchValue === '' || p.searchValue ? p.searchValue : p.initialValue}
        action={
          <If condition={isPopulated && !!p.actionText}>
            <Button.Primary text={p.actionText} onClick={p.handleSubmit} />
          </If>
        }
      />
    );

    return (
      <div className={_cx}>
        <Choose>
          <When condition={!!p.onSubmit}>
            <form onSubmit={p.handleSubmit}>{input}</form>
          </When>
          <Otherwise>{input}</Otherwise>
        </Choose>
      </div>
    );
  },
});


export const PrivateBigSearch = compose(
  tooltipify({ isInline: false }),
  mapHandlers({ click: [] }),
  whitelistStyles()
)(BigSearch);
export default (PrivateBigSearch);
