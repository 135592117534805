function isStateless(Component) {
  return !_.has(Component, 'prototype.render');
}

/**
 * @param  {ReactComponent|[ReactComponent]} Component -
 *    The type you expect the Element to be. Can be an array of Components or a single Component
 * @param  {ReactElement} Element - The element you are asserting on
 */
function isType(Component, element) {
  logger.warn(
    `"element" is null. This is likely due to a conditional render (e.g. { someCondition && <MyElement> }).
 This can often lead to bugs. You probably want to strip out null values. React.Children.toArray can do this.`,
    _.isNil(element)
  );

  const _Components = _.isArray(Component) ? Component : [Component];

  return _.some(_Components, _Component => element && element.type === _Component);
}

export default {
  isStateless,
  isType,
}
