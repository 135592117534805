import React from 'react';
import { createComponent, PropTypes } from 'wayin-react';
import { toUIProps } from 'helpers';
import { positions, sizes, textAlignments, colors } from 'enums';
import classnames from 'classnames';

import { Segment as UISegment } from 'semantic-ui-react';

const UNSUPPORTED_PROPS = [
  'as',
  'basic',
  'circular',
  'className',
  'clearing',
  'content',
  'disabled',
  'floated',
  'inverted',
  'loading',
  'padded',
  'piled',
  'raised',
  'secondary',
  'stacked',
  'tertiary',
  'vertical',
];
const ADDED_PROPS = [
  'float',
  'hasMargins',
  'hasPadding',
  'isBasic',
  'isCompact',
  'isDisabled',
  'isInverted',
  'isLoading',
];
const MODIFIED_PROPS = ['color'];
const propMap = {
  float:      'floated',
  hasMargins: 'vertical',
  hasPadding: 'padded',
  isBasic:    'basic',
  isCompact:  'compact',
  isDisabled: 'disabled',
  isInverted: 'inverted',
  isLoading:  'loading',
};

const Segment = createComponent({
  displayName: 'Segment',
  propTypes:   {
    children: PropTypes.node.isRequired,

    attached:   PropTypes.oneOf(positions.WITHOUT_SIDES),
    color:      PropTypes.oneOf(colors.CORE),
    float:      PropTypes.oneOf(positions.LR),
    hasMargins: PropTypes.bool,
    hasPadding: PropTypes.bool,
    isBasic:    PropTypes.bool,
    isCompact:  PropTypes.bool,
    isDisabled: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
    isLoading:  PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
    size:       PropTypes.oneOf(sizes.ALL),
    textAlign:  PropTypes.oneOf(textAlignments.ALL),
  },
  defaultProps: {
    color:      null, // transparent segment by default
    attached:   null,
    float:      null,
    hasMargins: false,
    hasPadding: false,
    isBasic:    false,
    isCompact:  false,
    isDisabled: false,
    isLoading:  false,
    size:       null,
    textAlign:  textAlignments.LEFT,
  },
  contextTypes: {
    isInverted: PropTypes.bool,
  },
  render(props) {
    const UIProps = toUIProps({ props, propMap, UNSUPPORTED_PROPS, ADDED_PROPS, MODIFIED_PROPS });
    delete UIProps.children;

    UIProps.className = classnames(props.color);

    return (
      <UISegment {...UIProps}>
        {props.children}
      </UISegment>
    );
  },
});

export default Segment;
