import React from 'react';
import cx from 'classnames';
import { extractElement } from 'helpers';

import { whitelistStyles } from 'components/core/hoc';
import { createComponent, PropTypes, Utils } from 'wayin-react';

// import enums
import { sizes } from 'enums';

//import components
import Header from 'components/core/header';

// create component
const LoadingState = createComponent({
  displayName: 'LoadingStateCmp',
  propTypes:   {
    height:      PropTypes.oneOf([sizes.X1, sizes.S, sizes.M, sizes.L]),
    spinnerSize: PropTypes.oneOf([sizes.X1, sizes.S, sizes.M, sizes.L]),
    header:      PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  },
  defaultProps: {
    height:      sizes.S,
    spinnerSize: sizes.M,
    header:      undefined,
  },
  contextTypes: {
    isInverted: PropTypes.bool,
  },
  render(p) {

    const _headerIsValidType = p.header && (Utils.isType([Header], p.header) || typeof p.header === 'string');
    let _header;

    const _cx = cx({
      ck:                                          true,
      'loading-state':                             true,
      'loading-state--inverted':                   p.isInverted,
      [`loading-state--${p.height}`]:              p.height,
      [`loading-state--spinner-${p.spinnerSize}`]: p.spinnerSize,
    });

    const _cxSpinner = cx({
      'loading-spinner': true,
    });

    logger.warn(
      'LoadingState header props is not intended for use with anything other than a header component.  Please pass Header or string instead.',
      !!p.header && !_headerIsValidType
    );

    if ( _headerIsValidType ) _header = extractElement(Header, p.header, 'text');

    return (
      <div className={_cx} style={p.style}>
        <div className="loading-state__inner">
          <div className={_cxSpinner}>
            <div className="loading-spinner__line1" />
            <div className="loading-spinner__line2" />
            <div className="loading-spinner__line3" />
            <div className="loading-spinner__line4" />
          </div>
          <If condition={_header}>
            <div className="loading-state__text">
              {_header.element}
            </div>
          </If>
        </div>
      </div>
    );
  },
});

export default whitelistStyles()(LoadingState);
