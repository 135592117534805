import React from 'react';
import { createComponent } from 'wayin-react';

import { positions, sizes } from 'enums';

import Layout from 'components/core/layout';
import LoadingState from 'components/abstractions/loading-state';
/**
 * displays a 'loading' view of the uploader, useful while loading in asset ref information
 */
const FileLoadingView = createComponent({
  displayName: 'FileLoadingView',

  render() {
    return (
      <div className="upload-view">
        <Layout isFluidHeight columnStyles={{ verticalAlign: positions.verticalAlign.MIDDLE }}>
          <LoadingState spinnerSize={sizes.M} />
        </Layout>
      </div>
    );
  },
});

export default FileLoadingView;
