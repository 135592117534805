import React from 'react';
import { connect } from 'react-redux';
import { createComponent, PropTypes } from 'wayin-react';

import Actions from './actions';
import Selectors from './selectors';

const Form = createComponent({
  displayName: 'Form',
  propTypes:   {
    children:    PropTypes.node.isRequired,
    formId:      PropTypes.string.isRequired,
    validations: PropTypes.object,
    isValid:     PropTypes.bool.isRequired,
    isDirty:     PropTypes.bool.isRequired,
  },
  defaultProps: {
    validations: null,
  },
  lifecycles: {
    UNSAFE_componentWillMount() {
      this.props.registerForm(this.props.formId, this.props.validations);
    },
    componentWillUnmount() {
      this.props.deregisterForm(this.props.formId);
    },
    UNSAFE_componentWillReceiveProps(nextProps) {
      //  We're going to only apply form field level validation for fields that changed
      if (!_.isEqual(this.props.formValues, nextProps.formValues) && nextProps.isDirty) {
        const changedFields = _.filter(
          _.keys(this.props.formValues),
          fieldId => nextProps.formValues[fieldId] !== this.props.formValues[fieldId]
        );
        this.props.changeFormValues(
          this.props.formId,
          changedFields,
          nextProps.formValues,
          this.props.validations
        );
      }
    },
  },
  render(p) {
    return <div>{p.children}</div>;
  },
});

const mapDispatchToProps = dispatch => {
  return {
    registerForm:     (formId, validations) => dispatch(Actions.registerForm(formId, validations)),
    deregisterForm:   formId => dispatch(Actions.deregisterForm(formId)),
    changeFormValues: (formId, fieldIds, formValues, validations) =>
      dispatch(Actions.changeFormValues(formId, fieldIds, formValues, validations)),
  };
};

const mapStateToProps = (state, ownProps) => {
  return {
    formErrors: Selectors.getFormErrors(state, ownProps.formId),
    formValues: Selectors.getFormValues(state, ownProps.formId),
    isValid:    Selectors.isFormValid(state, ownProps.formId),
    isDirty:    Selectors.isFormDirty(state, ownProps.formId),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Form);
