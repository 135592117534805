import React from 'react';
import { Embed } from 'semantic-ui-react';

import { createComponent } from 'wayin-react';
import { getTwitterId } from 'helpers';

const TwitterVideo = createComponent({
  displayName: 'TwitterVideo',

  render({ src, id }) {
    if (!id) {
      id = getTwitterId(src);
    }

    return <Embed active url={`//twitter.com/i/videos/tweet/${id}`} />;
  },
});

export default TwitterVideo;
