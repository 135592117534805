/**
 * defines a component that can be used to upload files to the 'wayin' platform
 * will show controls for uploading a new file or selecting an existing one from the library
 * will handle upload errors
 */
import React from 'react';
import { createComponent, PropTypes } from 'wayin-react';

import cx from 'classnames';

import { sizes, documentTypes as DOCUMENT_TYPES } from 'enums';

import { whitelistStyles, removeProps } from 'components/core/hoc';
import assetRefService from 'helpers/asset';
import { extractElement } from 'helpers';

import { fileUploadStates, FileUploaderStore, UploaderMessages } from './file-utils';
import * as DragUtils from './drag-and-drop-utils';

import ErrorIcon from 'components/abstractions/error-icon';
import Input from 'components/core/input';
import { PrivateLabel } from 'components/core/label';
import Tooltip from 'components/core/tooltip';

import AssetFileOverview from './file-overview';
import VideoURL from './video-url';
import FileSelectView from './file-select';
import FileUploadProgressView from './file-upload-progress';
import UploadErrorHandling from './upload-error-handling';
import UploadSuccessMessage from './upload-success';
import FileLoadingView from './file-loading';

/**
 * define default max upload size that we accept for a file
 */
export const DEFAULT_MAX_UPLOAD_SIZE = 10 * 1024 * 1024; //10MB

/**
 * the light uploader component
 */
const AssetUploader = createComponent({
  displayName: 'AssetUploader',

  propTypes: {
    libraryRef:      PropTypes.string,
    containerRef:    PropTypes.string,
    assetCategories: PropTypes.arrayOf(
      PropTypes.shape({
        text:  PropTypes.string,
        value: PropTypes.string,
      })
    ),
    uploadUrl:            PropTypes.string,
    stateIdentifier:      PropTypes.string.isRequired,
    onChange:             PropTypes.func.isRequired,
    value:                PropTypes.string,
    showUploadButton:     PropTypes.bool,
    showSelectButton:     PropTypes.bool,
    showVideoUrlButton:   PropTypes.bool,
    uploadButtonText:     PropTypes.string,
    selectButtonText:     PropTypes.string,
    dragAndDropText:      PropTypes.string,
    documentTypes:        PropTypes.arrayOf(PropTypes.oneOf(DOCUMENT_TYPES.ALL)),
    isDisabled:           PropTypes.bool,
    isFluid:              PropTypes.bool,
    isTransparent:        PropTypes.bool,
    maxFileSize:          PropTypes.number, //max file size in bytes to be accepted for an upload
    hasFluidButtons:      PropTypes.bool,
    errorButtonLayout:    PropTypes.oneOf(['horizontal', 'vertical']),
    label:                 PropTypes.oneOfType([
      PropTypes.element, //Label
      PropTypes.string,
    ]),
    hasBorder:            PropTypes.bool,
    videoPlaceholderText: PropTypes.string,
    allowAltText:         PropTypes.bool,
    altText:              PropTypes.string,
    onAltTextChange:      PropTypes.func,
    displayFilterScope:   PropTypes.bool,
  },
  defaultProps: {
    libraryRef:           '',
    containerRef:         '',
    assetCategories:      [],
    uploadUrl:            '',
    value:                '',
    showUploadButton:     true,
    showSelectButton:     true,
    showVideoUrlButton:   true,
    uploadButtonText:     '',
    selectButtonText:     '',
    dragAndDropText:      '',
    documentTypes:        [DOCUMENT_TYPES.IMAGE],
    isDisabled:           false,
    isFluid:              false,
    isTransparent:        true,
    maxFileSize:          DEFAULT_MAX_UPLOAD_SIZE,
    hasFluidButtons:      false,
    errorButtonLayout:    'horizontal',
    label:                null,
    hasBorder:            true,
    videoPlaceholderText: '',
    allowAltText:         false,
    altText:              undefined,
    onAltTextChange:      _.noop,
    displayFilterScope:   true,
  },

  state: {
    fileData: {
      value:   {},
      updater: 'setFileData',
    },
    dragAndDropInProgress: false,
  },

  refs: 'componentRef',

  lifecycles: {
    shouldComponentUpdate(nextProps, nextState) {
      return !_.isEqual(this.props, nextProps) || !_.isEqual(this.state, nextState);
    },

    componentDidMount() {
      const me = this,
        { onChange } = this.props;

      const assetRefObj = assetRefService.deserialize(this.props.value);
      const value = assetRefObj.location ?
        [assetRefObj.service, assetRefObj.assetType, assetRefObj.dim, assetRefObj.location].join(':') :
        '';

      me.componentIsMounted = true;

      //when a file object is changed, update the component state with the changes
      const onStateChange = fileData => {
        if (me.componentIsMounted) me.setState({ fileData: Object.assign({}, fileData) });
      };

      const onValueChange = assetRef => {
        const assetRefObj = assetRefService.deserialize(assetRef);
        let value = assetRef;

        if (assetRef && assetRefService.supportsSettings(assetRefObj)) {
          assetRefObj.settings = assetRefService.deserialize(this.props.value).settings;
          value = assetRefService.serialize(assetRefObj);
        }

        onChange(
          {
            /* for now passing an empty object as event */
          },
          { id: this.props.id, value }
        );
      };

      //check if a component state is saved, and load it when it's found
      let fItem = FileUploaderStore.getFile(this.props.stateIdentifier);
      if (fItem === false) {
        //this is the first time we mounted this component, add it to the store
        fItem = FileUploaderStore.registerNewUploader(this.props.stateIdentifier);
        fItem.setAssetRefData(value, false, false, 0); //sets the initial asset ref value. This will set the uploader state to the right state
      }

      fItem.setHandlers({
        onStateChange,
        onValueChange,
      });

      onStateChange(fItem.fileData);

      /**
       * setup drag and drop on the component
       */
      const componentRef = this.props.refs.componentRef;
      const showDragAndDropArea = show => {
        if (me.state.dragAndDropInProgress !== show) {
          me.setState({ dragAndDropInProgress: show });
        }
      };
      const onDropFiles = files => {
        fItem.uploadNewFile(
          me.props.uploadUrl,
          files,
          me.props.maxFileSize,
          me.props.containerRef,
          me.props.documentTypes
        );
      };
      DragUtils.initDragAndDrop(componentRef, showDragAndDropArea, onDropFiles);
    },

    UNSAFE_componentWillReceiveProps(nextProps) {
      const fileItem = FileUploaderStore.getFile(nextProps.stateIdentifier);

      if (this.props.stateIdentifier && this.props.stateIdentifier !== nextProps.stateIdentifier) {
        //the component has been recycled by react, update it's state to the new one
        this.setState({ fileData: Object.assign({}, fileItem.fileData) });
      }
      if (fileItem) {
        const assetRefObj = assetRefService.deserialize(nextProps.value);
        const value = assetRefObj.location ?
          [assetRefObj.service, assetRefObj.assetType, assetRefObj.dim, assetRefObj.location].join(':') :
          '';

        const fileData = fileItem.fileData;
        if (fileData && fileData.assetRef !== value) {
          //getting a different assetRef as what's on the fileItem
          if (fileData.uploadState === fileUploadStates.PROGRESS && fileData.xhr) {
            fileData.xhr.abort();
          }
          //console.log('replacing internal asset ref with value from props: ' + nextProps.value )
          fileItem.setAssetRefData(value, false, false, 0);
        }
      }
    },

    componentWillUnmount() {
      const me = this;
      me.componentIsMounted = false;
    },
  },

  handlers: {
    //called when a file is selected using the 'file browse' dialog, will try to upload the 'new' file
    onFileSelected: props => e => {
      FileUploaderStore.getFile(props.stateIdentifier).uploadNewFile(
        props.uploadUrl,
        e.target.files,
        props.maxFileSize,
        props.containerRef,
        props.documentTypes
      );
    },

    //called when an asset is selected from the library, will set the asset ref on the uploader
    selectFromLibrary: props => asset => {
      const fileItem = FileUploaderStore.getFile(props.stateIdentifier);
      fileItem.resetFile();
      fileItem.setAssetRefData(asset.assetRef, asset, true, 0);
    },
    //called when user handles an upload error
    handleUploadError: props => (evt, v) => {
      FileUploaderStore.getFile(props.stateIdentifier).handleUploadError(v.value, props.uploadUrl);
    },

    //called when the user wants to replace an asset on the uploader, will reset the state and clear the asset ref, then will show the file select/video input screen depending on the 'value' passed in.
    onReplaceVideo: props => e => {
      FileUploaderStore.getFile(props.stateIdentifier).updateFile({ uploadState: fileUploadStates.VIDEO_INPUT });
    },

    //called when the user wants to remove an asset on the uploader, will reset the state and clear the asset ref
    onRemoveFile: props => () => {
      const fileItem = FileUploaderStore.getFile(props.stateIdentifier);
      fileItem.resetFile({ uploadState: fileUploadStates.FILE_SELECT });
      fileItem.setAssetRefData('', false, true, 0);
    },

    onShowVideoInput: props => () => {
      FileUploaderStore.getFile(props.stateIdentifier).updateFile({ uploadState: fileUploadStates.VIDEO_INPUT });
    },

    onVideoURLChange: props => (e, { id, value }) => {
      FileUploaderStore.getFile(props.stateIdentifier).updateVideoUrl(value);
    },

    onVideoEnter: props => () => {
      FileUploaderStore.getFile(props.stateIdentifier).acceptVideoUrl();
    },
  },

  contextTypes: {
    isInverted: PropTypes.bool,
  },

  render(props) {
    const {
      fileData,
      selectFromLibrary,
      onFileSelected,
      handleUploadError,
      showUploadButton,
      showSelectButton,
      showVideoUrlButton,
      isDisabled,
      isFluid,
      isInverted,
      isTransparent,
      assetCategories,
      libraryRef,
      containerRef,
      uploadButtonText,
      selectButtonText,
      dragAndDropText,
      documentTypes,
      hasBorder,
      errorMessage,
      onShowVideoInput,
      onVideoURLChange,
      onVideoEnter,
      videoPlaceholderText,
      allowAltText,
      altText,
      onAltTextChange,
      displayFilterScope,
    } = props;
    const label = extractElement(PrivateLabel, props.label, {
      text:    props.label,
      isFluid: true,
    });
    const { uploadState } = fileData;
    const classNames = cx(
      'ck asset-uploader',
      'upload-state-' + uploadState,
      !props.isDisabled &&
        props.dragAndDropInProgress &&
        uploadState === fileUploadStates.FILE_SELECT &&
        'drag-and-drop-inprogress',
      isFluid && 'fluid',
      isTransparent && 'transparent',
      isInverted && 'inverted',
      isDisabled && 'disabled',
      hasBorder && 'has-border'
    );
    const wrapperClassNames = cx({
        ck:                       true,
        'asset-uploader-wrapper': true,
        error:                    !!errorMessage,
      }),
      assetRefObj = assetRefService.deserialize(fileData.assetRef);

    return (
      <div className={wrapperClassNames} style={props.style}>
        <If condition={label}>
          {!!label ? label.element : null}
        </If>
        <div className="error-wrapper">
          <div ref={props.setComponentRef} className={classNames}>
            <div className="uploader-background">&nbsp;</div>
            <If condition={uploadState === fileUploadStates.OVERVIEW || uploadState === fileUploadStates.SUCCEDED}>
              <AssetFileOverview
                file={fileData}
                onReplaceVideo={props.onReplaceVideo}
                onRemoveFile={props.onRemoveFile}
                showSelectButton={showSelectButton}
                onSelectFromLibrary={selectFromLibrary}
                assetCategories={assetCategories}
                libraryRef={libraryRef}
                containerRef={containerRef}
                documentTypes={documentTypes}
                displayFilterScope={displayFilterScope}
              />
            </If>
            <If condition={uploadState === fileUploadStates.VIDEO_INPUT}>
              <VideoURL
                videoURL={assetRefObj.location}
                onChange={onVideoURLChange}
                onEnter={onVideoEnter}
                videoPlaceholderText={videoPlaceholderText}
              />
            </If>
            <If condition={uploadState === fileUploadStates.FILE_SELECT}>
              <FileSelectView
                libraryRef={libraryRef}
                containerRef={containerRef}
                onFileSelected={onFileSelected}
                onSelectFromLibrary={selectFromLibrary}
                showUploadButton={showUploadButton}
                showSelectButton={showSelectButton}
                showVideoUrlButton={showVideoUrlButton}
                uploadButtonText={uploadButtonText}
                selectButtonText={selectButtonText}
                dragAndDropText={dragAndDropText}
                assetCategories={assetCategories}
                documentTypes={documentTypes}
                hasFluidButtons={props.hasFluidButtons}
                onShowVideoInput={onShowVideoInput}
                displayFilterScope={displayFilterScope}
              />
            </If>
            <If condition={uploadState === fileUploadStates.PROGRESS}>
              <FileUploadProgressView file={fileData} />
            </If>
            <If
              condition={uploadState === fileUploadStates.FAILED || uploadState === fileUploadStates.WRONG_IMAGE_SIZE}
            >
              <UploadErrorHandling
                hasFluidButtons={props.hasFluidButtons}
                file={fileData}
                handleUploadError={handleUploadError}
                errorButtonLayout={props.errorButtonLayout}
              />
            </If>
            <If condition={uploadState === fileUploadStates.SUCCEDED}>
              <UploadSuccessMessage file={fileData} />
            </If>
            <If condition={uploadState === fileUploadStates.LOADING}>
              <FileLoadingView />
            </If>
            <div className="drag-and-drop-container">
              <div className="v-align no-pointer-events">{UploaderMessages.DRAG_AND_DROP_FILES_HERE}</div>
              <div className="drag-and-drop-foreground no-pointer-events" />
            </div>
            <div className="file-upload-disabled" />
            <If condition={allowAltText}>
              <div className="asset-alt-text">
                <Tooltip tooltip="Enter a description of the image to support accessibility." isInline={false}>
                  <Input
                    size={sizes.X3}
                    placeholder="Alternative Text"
                    isFluid
                    isDisabled={isDisabled}
                    value={altText}
                    onChange={onAltTextChange}
                    id={`${props.id}AltText`}
                  />
                </Tooltip>
              </div>
            </If>
          </div>
          <ErrorIcon errorMessage={props.errorMessage} errorMessagePosition={props.errorMessagePosition} />
        </div>
      </div>
    );
  },
});

export const PrivateAssetUploader = whitelistStyles()(AssetUploader);

export default removeProps(['showUploadButton', 'showSelectButton', 'showVideoUrlButton'])(PrivateAssetUploader);
