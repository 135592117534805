import React from 'react';

import { createComponent, PropTypes } from 'wayin-react';
import { getInstagramId } from 'helpers';

// https://github.com/sugarshin/react-instagram-embed/blob/master/src/index.js
const InstagramVideo = createComponent({
  displayName: 'InstagramVideo',

  lifecycles: {
    // load instagram scripts
    UNSAFE_componentWillMount() {
      if (window.instgrm || document.getElementById('react-instagram-embed-script')) {
        return;
      }

      const s = document.createElement('script');
      s.async = s.defer = true;
      s.src = '//platform.instagram.com/en_US/embeds.js';
      s.id = 'react-instagram-embed-script';
      document.body.appendChild(s);
    },
  },

  render({ src, id }) {
    if (!id) {
      id = getInstagramId(src);
    }

    return (
      <div className="embed">
        <iframe
          allowFullScreen=""
          frameBorder={0}
          height="100%"
          scrolling="no"
          src={`//instagram.com/p/${id}/embed`}
          width="100%"
        />
      </div>
    );
  },
});

export default InstagramVideo;
