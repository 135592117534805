import React from 'react';
import { createComponent, PropTypes } from 'wayin-react';

import { sizes } from 'enums';

import { UploaderMessages } from './file-utils';

import ContentItem from 'components/abstractions/content-item';

import AssetOverviewLayout from './asset-overview';
import ItemThumbnail from './item-thumbnail';

/**
 * displays a message over the file item showing the success message
 */
const UploadSuccessMessage = createComponent({
  displayName: 'UploadSuccessMessage',

  propTypes: {
    file: PropTypes.object.isRequired,
  },

  render(props) {
    const { file } = props;
    return (
      <div className="upload-view upload-success-view">
        <AssetOverviewLayout
          thumbnailContent={
            <ItemThumbnail
              metaWidth={(file.assetMeta && file.assetMeta.width) || (file.resolution && file.resolution.width) || 0}
              metaHeight={(file.assetMeta && file.assetMeta.height) || (file.resolution && file.resolution.height) || 0}
              imageSrc={file.imageSrc}
              tooltip={file.fileName}
            />
          }
          metaContent={<ContentItem header={UploaderMessages.UPLOAD_SUCCESS} size={sizes.X7} />}
        />
      </div>
    );
  },
});

export default UploadSuccessMessage;
