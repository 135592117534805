import { Actions, Selectors, reducer } from './animating-button-duck';
import saga from './animating-button-sagas';
export { default as AnimatingButton } from './animating-button';

export default {
  Actions,
  namespace: 'animatingButton',
  sagas:     [saga],
  Selectors,
  reducer,
};
