import { PropTypes } from 'wayin-react';
import { withProps, compose, setDisplayName, setPropTypes } from 'recompose';
import { removeProps } from '../hoc';
import { sizes, positions } from 'enums';

import { PrivateMenu } from 'components/core/menu/menu';

// <TextMenu
//   items=[]
//   activeValue={0}
//   size={sizes.X1}
//   float={positions.R}
//   onItemClick={myHandler()}
// />

const TextMenu = compose(
  setDisplayName('TextMenu'),
  removeProps([
    'color',
    'menuType',
    'borders',
    'iconTextDisplay',
    'fitted',
    'isCompact',
    'isPaginated',
    'isTextOnly',
    'isUnderlined',
    'isVertical',
    'isFluid',
    'children',
  ]),
  withProps({ isTextOnly: true, menuType: 'text-menu' })
)(PrivateMenu);

export default TextMenu;
