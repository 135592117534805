import React from 'react';
import { createComponent, PropTypes } from 'wayin-react';
import { textOverflows, sizes, colors, positions, textAlignments, font, icons } from 'enums';
import { toUIProps, extractElement } from 'helpers';

import { removeProps, whitelistStyles } from 'components/core/hoc';
import { compose } from 'recompose';

import { Header as UIHeader } from 'semantic-ui-react';
import HeaderContent from './header-content';
import HeaderByline from './header-byline';
import Icon from '../icon';
import Image from '../image';

const MAPPED_PROPS = {
  isDisabled: 'disabled',
  isDividing: 'dividing',
  isInverted: 'inverted',
  text:       'content',
  byline:     'subheader',
  // product says not needed right now:
  // isBlock:   'block',
};

const UNSUPPORTED_PROPS = [
  'as',
  'className',
  'dividing',
  'inverted',
  'sub',
  // product says not needed right now:
  'block',
  'attached',
  'disabled',
  'content',
  'subheader',
];

const MODIFIED_PROPS = [
  'icon', // We will only use as boolen to display icon as block vs. inline.  Use children to add icons.
  'floated',
  'color',
  'size',
];

const ADDED_PROPS = [
  'assetDisplay', // replaces deprecated 'iconDisplay' prop
  'byline',
  'icon',
  'isDisabled',
  'isDividing',
  'isInverted',
  'float',
  'font',
  'text',
  'textOverflow',
];

const Header = createComponent({
  displayName: 'Header',
  propTypes:   {
    text:         PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    textOverflow: PropTypes.oneOf(textOverflows.ALL),
    byline:       PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    icon:         PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    image:        PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    children:     PropTypes.node, //Mutually exclusive wth content and subheader
    color:        PropTypes.oneOf([colors.ACCENT, colors.BASE, colors.WHITE, colors.DARK, colors.DARKER]),
    float:        PropTypes.oneOf(positions.LR),
    // include smaller sizes when needed
    size:         PropTypes.oneOf(sizes.ALL),

    // Note: does not affect icon position - inline icons will be left, and block icons will be centered.
    // Text immediately after an inline icon will be inline, not aligned.
    textAlign: PropTypes.oneOf(textAlignments.ALL),

    isDisabled: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
    isDividing: PropTypes.bool, // divide header from the content below it with a line

    font: PropTypes.shape({
      weight:   PropTypes.oneOf(font.weights.ALL),
      family:   PropTypes.oneOf(font.families.ALL),
      emphasis: PropTypes.oneOf(font.emphasis.ALL),
    }),
    // inline icon gets set to display: table-cell along w/ immediately following text.
    // inline prop value != display:inline, but it *looks* inline.
    assetDisplay: PropTypes.oneOf(['inline', 'block', 'inlineByline']),
    // currently not needed
    // attached:   PropTypes.oneOf(positions.TB), // can only be attached top or bottom
    // isBlock: PropTypes.bool,
  },
  defaultProps: {
    text:         null,
    textOverflow: textOverflows.WRAP,
    byline:       null,
    icon:         null,
    image:        null,
    children:     null,
    color:        colors.BASE,
    float:        null,
    size:         sizes.X5,
    textAlign:    null,
    isDisabled:   false,
    isDividing:   false,
    font:         {
      weight:   font.weights.MEDIUM,
      family:   font.families.CUSTOM,
      emphasis: font.emphasis.CAPS,
    },
    assetDisplay: 'inline', // replaces deprecated 'iconDisplay' prop
    // currently not needed
    // attached:   null,
    // isBlock:    false,
  },
  contextTypes: {
    isInverted: PropTypes.bool,
  },
  render(props) {
    const UIProps = toUIProps({
      props,
      propMap: MAPPED_PROPS,
      UNSUPPORTED_PROPS,
      ADDED_PROPS,
      MODIFIED_PROPS,
    });

    const fontStyles = {
      weight: props.font.weight || font.weights.MEDIUM,
      family: props.font.family || font.families.CUSTOM,
    };

    UIProps.className = `ck ${props.color} font-${fontStyles.family}-${fontStyles.weight} ${props.size} header__text--${
      props.textOverflow
    }`;
    if (props.assetDisplay === 'inlineByline') UIProps.className += ' inline-byline';
    // Shorthand vs verbose resolution
    logger.warn(
      'Header: "children" cannot be defined with "text", "byline", or "icon", please choose one or the other.',
      hasChildren(props) && hasShorthandProps(props)
    );

    const content = !!props.text ? (
      <HeaderContent key={2} font={{ emphasis: props.font.emphasis }}>
        {props.text}
      </HeaderContent>
    ) : (
      undefined
    );
    const byline = !!props.byline ? <HeaderByline key={3}>{props.byline}</HeaderByline> : undefined;

    if (hasChildren(props)) {
      delete UIProps.icon;
      delete UIProps.image;
      delete UIProps.content;
      delete UIProps.subheader;
    } else {
      UIProps.children = [!!props.icon ? getIcon(props) : undefined, !!props.image ? getImage(props) : undefined];

      if (props.assetDisplay === 'inlineByline') {
        UIProps.children.push(
          <div key={4} className="header__inline-content">
            {content}
            {byline}
          </div>
        );
      } else {
        UIProps.children.push(content);
        UIProps.children.push(byline);
      }

      delete UIProps.icon;
      delete UIProps.image;
      delete UIProps.content;
      delete UIProps.subheader;
    }

    return <UIHeader {...UIProps} icon={props.assetDisplay === 'block'} floated={props.float} />;
  },
});

function getImage(props) {
  return extractElement(Image, props.image, { src: props.image, key: 0 }).element;
}

function getIcon(props) {
  return extractElement(Icon, props.icon, {
    name:       props.icon,
    color:      props.color ? props.color : undefined,
    key:        1,
    isDisabled: props.isDisabled,
  }).element;
}

function hasChildren(props) {
  return !!props.children;
}

function hasShorthandProps(props) {
  return !!props.text || !!props.byline || !!props.icon || !!props.image;
}

export const PrivateHeader = Header;
export default compose(removeProps(['']), whitelistStyles())(Header);
