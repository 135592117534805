import React from 'react';
import { createComponent, PropTypes } from 'wayin-react';
import { sizes } from 'enums';
import { toUIProps } from 'helpers';

import { Image as UIImage } from 'semantic-ui-react';

import { whitelistStyles } from 'components/core/hoc';

const UNSUPPORTED_PROPS = ['as', 'className'];

const ImageGroup = createComponent({
  displayName: 'ImageGroup',
  propTypes:   {
    children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)])
      .isRequired,
    size: PropTypes.oneOfType([
      PropTypes.oneOf(sizes.ALL),
      PropTypes.shape({
        width:  PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      }),
    ]).isRequired,
  },
  render(props) {
    const UIProps = toUIProps({ props, UNSUPPORTED_PROPS });
    delete UIProps.children;
    delete UIProps.size;

    const children = React.Children.map(props.children, image => {
      return image.props.size === undefined
        ? React.cloneElement(image, { size: props.size })
        : image;
    });

    return (
      <UIImage.Group {...UIProps}>
        {children}
      </UIImage.Group>
    );
  },
});

export default whitelistStyles()(ImageGroup);
