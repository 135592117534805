import PropTypes from "prop-types";

const IGNORE_REQUIRED_SECRET_KEY = "IGNORE_REQUIRED_SECRET_KEY";

// custom prop to check regex props - so you can pass a regular expression as a prop
function regexpChecker(props, propName, componentName) {
  const _componentName = componentName || "ANONYMOUS";

  if (props[propName]) {
    return props[propName] instanceof RegExp
      ? null
      : new Error(
          propName +
            " in " +
            _componentName +
            " is not a valid regular expression"
        );
  }

  // assume all ok
  return null;
}

function decorateNoParameterTypeChecker(validate) {
  let preCheck, chainedCheck;

  preCheck = function(isRequired, defaultProps) {
    return check(validate, isRequired, defaultProps);
  };
  chainedCheck = preCheck.bind(null, false);
  chainedCheck.isRequired = preCheck.bind(null, true);

  return chainedCheck;
}

// These PropTypes take an additional parameter of potential options
function decorateWithParameterSimpleTypeChecker(validate) {
  let preCheck, chainedCheck;
  return function(options) {
    const _validate = validate(options);
    preCheck = function(isRequired, defaultProps) {
      return check(_validate, isRequired, defaultProps);
    };

    chainedCheck = preCheck.bind(null, false);
    chainedCheck.isRequired = preCheck.bind(null, true);

    return chainedCheck;
  };
}

function decorateWithParameterComplexTypeChecker(validate) {
  let preCheck, chainedCheck, _options;
  return function(options) {
    // These options are additional PropTypes
    // We need to call them to simulate the defaultProps that would get passed in by the component factory
    // We use this secret key to bypass the requirement for default props.

    // oneOfType
    if (_.isArray(options)) {
      _options = _.map(options, option => option(IGNORE_REQUIRED_SECRET_KEY));

      // arrayOf, objectOf
    } else if (_.isFunction(options)) {
      _options = options(IGNORE_REQUIRED_SECRET_KEY);

      // shape
    } else {
      _options = _.map(options, option => option(IGNORE_REQUIRED_SECRET_KEY));
    }

    const _validate = validate(_options);
    preCheck = function(isRequired, defaultProps) {
      return check(_validate, isRequired, defaultProps);
    };

    chainedCheck = preCheck.bind(null, false);
    chainedCheck.isRequired = preCheck.bind(null, true);

    return chainedCheck;
  };
}

function check(validate, isRequired, defaultProps) {
  return function(props, propName, componentName, location, ...rest) {
    if (
      !isRequired &&
      !!defaultProps &&
      !defaultProps.hasOwnProperty(propName) &&
      defaultProps !== IGNORE_REQUIRED_SECRET_KEY
    ) {
      return new Error(
        `Optional ${location} \`${propName}\` did not specify a default value.`
      );
    } else if (props[propName] == null) {
      if (isRequired) {
        return new Error(
          `Required ${location} \`${propName}\` was not specified in ` +
            `\`${componentName}\`.`
        );
      }
      return null;
    } else {
      return validate(props, propName, componentName, location, ...rest);
    }
  };
}

const propTypes = {
  array: decorateNoParameterTypeChecker(PropTypes.array),
  bool: decorateNoParameterTypeChecker(PropTypes.bool),
  func: decorateNoParameterTypeChecker(PropTypes.func),
  number: decorateNoParameterTypeChecker(PropTypes.number),
  object: decorateNoParameterTypeChecker(PropTypes.object),
  string: decorateNoParameterTypeChecker(PropTypes.string),
  symbol: decorateNoParameterTypeChecker(PropTypes.symbol),

  any: decorateNoParameterTypeChecker(PropTypes.any),
  element: decorateNoParameterTypeChecker(PropTypes.element),
  node: decorateNoParameterTypeChecker(PropTypes.node),

  regexp: decorateNoParameterTypeChecker(regexpChecker),

  oneOf: decorateWithParameterSimpleTypeChecker(PropTypes.oneOf),
  instanceOf: decorateWithParameterSimpleTypeChecker(PropTypes.instanceOf),

  arrayOf: decorateWithParameterComplexTypeChecker(PropTypes.arrayOf),
  objectOf: decorateWithParameterComplexTypeChecker(PropTypes.objectOf),
  // Currently shape does not supported nested definitions of any complexTypeChecker
  shape: decorateWithParameterComplexTypeChecker(PropTypes.shape),
  oneOfType: decorateWithParameterComplexTypeChecker(PropTypes.oneOfType)
};

export default propTypes;
