import { createAction } from 'redux-act';
import Selectors from './selectors';

const registerForm = createAction('register form', (formId, validators) => ({
  formId,
  validators,
}));
const deregisterForm = createAction('de-register form', formId => ({ formId }));
const registerField = createAction(
  'register field',
  (formId, fieldId, value, validationContext, validators, errorMessages) => ({
    formId,
    fieldId,
    value,
    validationContext,
    validators,
    errorMessages,
  })
);
const deregisterField = createAction('de-register field', (formId, fieldId) => ({
  formId,
  fieldId,
}));

/*
  'changeField' iterates through each validator for a particular field and
  passes any error messages it catches from the validators.  This action is invoked by react-thunk in middleware.
*/
const changeField = (formId, fieldId, value, validationContext, shouldValidate) => {
  return (dispatch, getState) => {
    let fieldErrors = Selectors.getFieldErrors(getState(), formId, fieldId);
    if (shouldValidate) {
      //  we clear out errors so that we can re-validate a field
      fieldErrors = [];
      const validators = Selectors.getFieldValidators(getState(), formId, fieldId);
      //  apply all validators for the changed field
      _.forEach(_.values(validators), validator => {
        const validation = validator(value, validationContext);
        catchError(validation, fieldErrors);
      });
    }
    dispatch(fieldChanged(formId, fieldId, value, validationContext, fieldErrors));
  };
};

/*
*  Applies all validations for a form.  All validations will be form level validations
*  as well as multi-field validations (i.e. password and password-match).  The validateForm
*  action will call this method.
*
*  @param formId - the id of the form to validate
*  @param formValues - the values for each field in the form
*  @param formFieldContexts - all validation contexts for each field in the form
*  @param validations - the object that contains form level validations and field level
*                       validations
*
* Form and Multi-Field Validation Example:  l
*
* const validations = {
*   formValidation: (values, validationContexts) => {
*     if (values.password === '') {
*       return RequiredMessage;
*     }
      if (validationContexts.password.type !== 'password-group') {
        return 'Not in group';
      }
*     return true;
*   },
*   passwordMatch: {
*     confirmPassword: (formValues, validationContexts) => {
*       if (formValues.password !== formValues.confirmPassword) {
*         return NoMatchMessage;
*       }
*       return true;
*      },
*    },
*  };
*/
const applyFormValidations = (formId, formValues, formFieldContexts, validators) => {
  const formErrors = [];
  const fieldErrors = {};
  _.map(validators, validator => {
    //  form level validation
    if (typeof validator === 'function') {
      const validation = validator(formValues, formFieldContexts);
      catchError(validation, formErrors);
    } else if (typeof validator === 'object') {
      //  run the multi-field validations
      _.map(validator, (value, key) => {
        const errors = fieldErrors[key] || [];

        //  value is the validator
        catchError(value(formValues, formFieldContexts), errors);
        fieldErrors[key] = errors;
      });
    }
  });
  return { formErrors, fieldErrors };
};

/*
*  Applies form level field validations.  An example would be multi-field validation where
*  a password and confirm password need to match.  'changeFormValues' will call this method.
*
*  @param formId - the id of the form to validate
*  @param fieldIds - the ids of the fields that have changed
*  @param formValues - the values for each field in the form
*  @param formFieldContexts - the validaton Contexts for each field
*  @param validators - the object that contains form level validators and field level
*                       validators
*/
const applyFormLevelFieldValidation = (formId, fieldIds, formValues, formFieldContexts, validators) => {
  const fieldErrors = {};
  _.map(validators, validatorObject => {
    if (typeof validatorObject === 'object') {
      _.map(validatorObject, (validator, key) => {
        if (fieldIds.indexOf(key) > -1) {
          const errors = fieldErrors[key] || [];
          catchError(validator(formValues, formFieldContexts), errors);
          fieldErrors[key] = errors;
        }
      });
    }
  });
  return fieldErrors;
};

/**
*  Triggers form validation
*  @param formId - the id of the form to validate
*/
const validateForm = formId => {
  return (dispatch, getState) => {
    const validators = Selectors.getFormValidators(getState(), formId);
    const formValues = Selectors.getFormValues(getState(), formId);
    const formFieldContexts = Selectors.getFormFieldContexts(getState(), formId);
    const { formErrors, fieldErrors } = applyFormValidations(formId, formValues, formFieldContexts, validators);
    dispatch(formChanged(formId, formErrors, fieldErrors));
    //  iterate through each field referenced in formValues to do field level validation
    _.forEach(formValues, (fieldValue, fieldId) => {
      const validationContext = Selectors.getFieldContext(getState(), formId, fieldId);
      dispatch(changeField(formId, fieldId, fieldValue, validationContext, true));
    });
  };
};

/*
*  Triggers form field level validations for the provided fieldIds.
*
*  @param formId - the id of the form to validate
*  @param fieldIds - the ids of the fields that have changed
*  @param formValues - the values for each field in the form
*  @param validations - the object that contains form level validations and field level
*                       validations
*/
const changeFormValues = (formId, fieldIds, formValues) => {
  return (dispatch, getState) => {
    const validators = Selectors.getFormValidators(getState(), formId);
    const formFieldContexts = Selectors.getFormFieldContexts(getState(), formId);
    const fieldErrors = applyFormLevelFieldValidation(formId, fieldIds, formValues, formFieldContexts, validators);
    dispatch(updateFormFieldErrors(formId, fieldErrors));
  };
};

const formChanged = createAction('CKForm => form changed', (formId, formErrors, fieldErrors) => ({
  formId,
  formErrors,
  fieldErrors,
}));

const fieldChanged = createAction('CKForm => field changed', (formId, fieldId, value, validationContext, errorMessages) => ({
  formId,
  fieldId,
  value,
  validationContext,
  errorMessages,
}));

const updateFormFieldErrors = createAction('CKForm => update form field errors', (formId, fieldErrors) => ({
  formId,
  fieldErrors,
}));

//  not exposed helper function
const catchError = (validation, errors) => {
  if (validation && validation !== true) {
    errors.indexOf(validation) == -1 && errors.push(validation);
  }
};

export default {
  changeField,
  changeFormValues,
  deregisterField,
  deregisterForm,
  formChanged,
  fieldChanged,
  registerField,
  registerForm,
  validateForm,
  updateFormFieldErrors,
};
