import withRefs from './with-refs';
import capitalize from 'helpers/capitalize';
import { compose, withState, lifecycle, mapProps, withHandlers, defaultProps } from 'recompose';

export default function createComponent(blueprint = {}) {
  let Component;

  validate(blueprint);

  Component = compose(create, injectPropTypes)(blueprint);
  Component = decorate(Component, blueprint);

  return Component;
}

//TODO Error vs warning
//TODO enforce handler naming convention?
function validate(blueprint) {
  const _blueprint = blueprint;

  if (process.env.__DEV__) {
    if (!_blueprint.render) {
      throw new Error('blueprint.render is required');
    }

    if (!_blueprint.displayName) {
      throw new Error('displayName is required');
    }
  }

  return _blueprint;
}

function injectPropTypes(blueprint) {
  let { defaultProps = {}, propTypes = {}, contextTypes = {} } = blueprint;

  propTypes = _.mapValues(propTypes, val => val(defaultProps));
  // Not concerned with checking for defaults here so just passing in null
  contextTypes = _.mapValues(contextTypes, val => val(null));

  return Object.assign({}, blueprint, { propTypes, defaultProps, contextTypes });
}

function create({ render, displayName, defaultProps, propTypes, contextTypes }) {
  const Component = function(props, context) {
    const _props = _.defaults({}, props, extractContext(context));

    return render(_props);
  };

  Component.displayName = displayName;
  Component.propTypes = propTypes;
  Component.contextTypes = contextTypes;
  // Component.unstable_handleError = function unstable_handleError(error) {
  //   console.log(`${displayName} Render Error: ${error}`)
  // };

  return Component;
}

const decorate = _.curry((Component, blueprint) => {
  //Order matters
  return compose(
    _maybeWrap({ blueprint, schematic: 'defaultProps', hoc: defaultProps }),
    _setRefs(blueprint),
    _setState(blueprint),
    _maybeWrap({ blueprint, schematic: 'handlers', hoc: withHandlers }),
    _maybeWrap({ blueprint, schematic: 'lifecycles', hoc: lifecycle }),
    _debug(blueprint)
  )(Component);
});

const _debug = _.curry(({ displayName, debug }, Component) => {
  return debug && process.env.__DEV__ ?
    lifecycle({
      componentDidUpdate(prevProps, prevState) {
        deepDiff(
            { props: prevProps, state: prevState },
            { props: this.props, state: this.state },
            displayName
          );
      },
    })(Component) :
    Component;
});

function isRequiredUpdateObject(o) {
  return Array.isArray(o) || (o && o.constructor === Object.prototype.constructor);
}

function deepDiff(o1, o2, p) {
  const notify = status => {
    if (['avoidable?', 'avoidable!'].includes(status)) {
      console.warn(`%cUpdate ${status}`, 'color: red');
    } else {
      console.log(`%cUpdate ${status}`, 'color: green');
    }
    console.log('%cbefore', 'font-weight: bold', o1);
    console.log('%cafter ', 'font-weight: bold', o2);
  };
  if (!_.isEqual(o1, o2)) {
    console.group(p);
    if ([o1, o2].every(_.isFunction)) {
      notify('avoidable?');
    } else if (![o1, o2].every(isRequiredUpdateObject)) {
      notify('required.');
    } else {
      const keys = _.union(_.keys(o1), _.keys(o2));
      for (const key of keys) {
        deepDiff(o1[key], o2[key], key);
      }
    }
    console.groupEnd();
  } else if (o1 !== o2) {
    console.group(p);
    notify('avoidable!');
    if (_.isObject(o1) && _.isObject(o2)) {
      const keys = _.union(_.keys(o1), _.keys(o2));
      for (const key of keys) {
        deepDiff(o1[key], o2[key], key);
      }
    }
    console.groupEnd();
  }
}

/*
 1) counter: 0 => props = { counter: 0, setCounter() }
 2) counter: {
      value: 0,
      updater: 'updateCounter'
    } => props = { counter: 0, updateCounter() }
 3) counter: {
      value: 0,
      updater(setCounter, props) {
        return {
          increment: () => setCounter(n => n + 1),
          decrement: () => setCounter(n => n - 1),
          reset: () => setCounter(0),
        }
      }
    } => props = { counter: 0, setCounter(), increment(), decrement(), reset() }
 */
const _setState = _.curry(({ state }, Component) => {
  return _.reduce(
    _.toPairs(state),
    (acc, keyVal) => {
      const key = keyVal[0];
      const val = keyVal[1];
      const defaultSetter = `set${capitalize(key)}`;

      if (_.isObject(val)) {
        if (_.isString(val.updater)) {
          acc = withState(key, val.updater, val.value)(acc);
        } else {
          // This order seems backwards to me, but its what the docs say to do...so c'est la vie
          acc = compose(
            withState(key, defaultSetter, val.value),
            mapProps(({ [defaultSetter]: setter, ...rest }) => {
              return Object.assign({}, rest, val.updater(setter, rest));
            })
          )(acc);
        }
      } else {
        acc = withState(key, defaultSetter, val)(acc);
      }
      return acc;
    },
    Component
  );
});

const _setRefs = _.curry(({ refs }, Component) => {
  if (_.isEmpty(refs) || _.isNil(refs)) {
    return Component;
  }

  let _refs = _.isString(refs) ? [refs] : refs;
  _refs = _.reduce(
    _refs,
    (acc, ref) => {
      acc[ref] = `set${capitalize(ref)}`;
      return acc;
    },
    {}
  );

  return withRefs(_refs)(Component);
});

const _maybeWrap = _.curry(({ blueprint, schematic, hoc }, Component) => {
  return !_.isEmpty(blueprint[schematic]) && !_.isNil(blueprint[schematic]) ?
    hoc(blueprint[schematic])(Component) :
    Component;
});

const contextDefaults = {
  isInverted: false,
};

function extractContext(context) {
  return _.mapValues(context, (value, key) => {
    return value !== undefined ? value : contextDefaults[key];
  });
}
