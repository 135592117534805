import React from 'react';
import { Embed } from 'semantic-ui-react';

import { createComponent, PropTypes } from 'wayin-react';
import { getYoutubeId } from 'helpers';

const YoutubeVideo = createComponent({
  displayName: 'YoutubeVideo',

  render({ src, id }) {
    if (!id) {
      id = getYoutubeId(src);
    }

    return <Embed id={id} source="youtube" active autoplay={false} />;
  },
});

export default YoutubeVideo;
