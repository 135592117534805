import _ from 'lodash';

import { messageTypes } from './message-types';

const messageTimeouts = {
  [messageTypes.INFO]:    2000,
  [messageTypes.WARNING]: 5000,
  [messageTypes.ERROR]:   false,
  [messageTypes.SUCCESS]: 2000,
};

messageTimeouts.ALL = _.values(messageTimeouts);

export default messageTimeouts;
