const families = {
  CUSTOM: 'custom',
  SYSTEM: 'system',
  BLOKK:  'blokk',
};

families.ALL = _.values(families);

const weights = {
  LIGHT:   'light',
  REGULAR: 'regular',
  MEDIUM:  'medium',
};

weights.ALL = _.values(weights);

const emphasis = {
  CAPS:   'caps',
  NORMAL: 'normal',
};

emphasis.ALL = _.values(emphasis);

const font = { families, weights, emphasis };

export default font;
