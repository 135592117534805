import mockCoreappConfig from '../../mock-coreapp-config.json';

let _config;

//TODO(Janos): not sure if _.pick is what we want. It kind of implies, that each time we add something new in the config in coreapp, we also add it here. Need a discussion around this, do we want to use the whole "App" object, or just build out a custom CK config object(I vote for this since there will be only couple of configs we need to pass to CK)
function setConfig(config) {
  _config = _.pick(config, ['assetUploader', 'assetUrls', 'account', 'campaign', 'userTimeZone']);
}

function values() {
  /*
    I don't love this setup of defaulting to the mock object above
    Would prefer to store mock in a JSON file and load through the bootstrapper
      to keep the pipeline the same
    Ran into issues with doing the through test-setup.js though do to lack of
      ES6 module support and funky behavior when same file was imported through
      the test-helper.js and also bundled in Webpack and referenced elsewhere
  */
  return _config || mockCoreappConfig.globalContext;
}

export default {
  setConfig,
  values,
};
