function mapProps(propMap, props) {
  return _.reduce(props, (result, value, propName) => {
    if (propMap.hasOwnProperty(propName) && !!value) {
      // TODO this is a hack for event handler accidentally getting triggered
      // Really was just intended for booleans that can be bools or funcs
      result[propMap[propName]] = _.isFunction(value) && !propName.includes('handle') ?
        value() :
        value;
    } else if (propMap.hasOwnProperty(propName)) {
      result[propMap[propName]] = value;
    } else {
      result[propName] = value;
    }
    return result;
  }, {});
}

export default _.curry(mapProps);
