import evalPredicate from './eval-predicate';
/*
  TODO- Consider ability to turn these ON in production
 */

// TODO- console.warn vs console.error vs throw
export function warn(msg, predicate = true) {
  if (process.env.__DEV__) {
    if (evalPredicate(predicate)) {
      console.warn(msg);
    }
  }
}

// TODO- Should debug be able to be turned on in production?
export function debug(msg, predicate = true) {
  if (evalPredicate(predicate)) {
    console.log(msg);
  }
}

export function error(msg, predicate = true) {
  if (process.env.__DEV__) {
    if (evalPredicate(predicate)) {
      console.error(msg);
    }
  }
}

export function deprecation(msg, predicate = true) {
  if (process.env.__DEV__) {
    if (evalPredicate(predicate)) {
      console.warn(msg);
    }
  }
}

export default {
  debug,
  error,
  warn,
  deprecation,
};
