import React from 'react';
import { createComponent, PropTypes } from 'wayin-react';
import cx from 'classnames';

import { ConfigService } from 'services';

import { isAssetPortrait } from './file-utils';

import CKImage from 'components/core/image';

/**
 * shows the image thumbnail for an upload item
 */
const ItemThumbnail = createComponent({
  displayName: 'ItemThumbnail',

  propTypes: {
    imageSrc:   PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    metaWidth:  PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    metaHeight: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    tooltip:    PropTypes.string,
  },
  defaultProps: {
    metaHeight: null,
    metaWidth:  null,
    imageSrc:   '',
    tooltip:    '',
  },

  render(props) {
    const { imageSrc } = props;
    const ConsoleKitConfig = ConfigService.values();
    const isPortrait = isAssetPortrait(props.metaWidth, props.metaHeight);
    return (
      <div
        className={cx({
          'file-thumbnail-wrapper':           !isPortrait,
          'file-thumbnail-wrapper--portrait': isPortrait,
        })}
      >
        <Choose>
          <When condition={imageSrc}>
            <CKImage src={imageSrc} />
          </When>
          <Otherwise>
            <CKImage src={ConsoleKitConfig.assetUploader.defaultFileThumbnail} />
          </Otherwise>
        </Choose>
      </div>
    );
  },
});

export default ItemThumbnail;
