import React from 'react';
import { createComponent, PropTypes } from 'wayin-react';
import { sizes } from 'enums';
import { toUIProps, cloneChildrenWithDefaultProps } from 'helpers';
import classnames from 'classnames';

import { whitelistStyles } from 'components/core/hoc';

/*
  Semantic Icon Group is designed to allow overlapping icons which differs from how all our other groups work. Since we don't have that use case currently we are rolling our own group component. Should we need to support overlapping icons in the future, we can see how best to support it then.
 */
const IconGroup = createComponent({
  displayName: 'IconGroup',
  propTypes:   {
    children:   PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)]).isRequired,
    layout:     PropTypes.oneOf(['horizontal', 'vertical']),
    size:       PropTypes.oneOfType([PropTypes.oneOf(sizes.ALL), PropTypes.string]),
    onClick:    PropTypes.func,
    isCircular: PropTypes.bool,
    isDisabled: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  },
  defaultProps: {
    layout:     'horizontal',
    size:       sizes.X4,
    onClick:    undefined,
    isCircular: false,
    isDisabled: false,
  },
  render(props) {
    const children = cloneChildrenWithDefaultProps(props, childPropWhitelist);
    const className = classnames('ck-icons', props.layout);
    return (
      <div className={className} style={props.style}>
        {children}
      </div>
    );
  },
});

const childPropWhitelist = ['size', 'onClick', 'isCircular', 'isDisabled'];

export default whitelistStyles()(IconGroup);
