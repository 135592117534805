import React from 'react';
import ReactDOM from 'react-dom';
import { createComponent, PropTypes, Utils } from 'wayin-react';
import { widths, textAlignments, positions, sizes, colors, directions } from 'enums';
import { toUIProps } from 'helpers';
import classnames from 'classnames';
import { whitelistStyles } from 'components/core/hoc';

import { Grid as UIGrid } from 'semantic-ui-react';
import LayoutRow, { PrivateLayoutRow } from './layout-row';
import Measure from 'react-measure';

const ADDED_PROPS = [
  'borders',
  'isCentered',
  'isDoubling',
  'isInverted',
  'isStackable',
  'gutterSize',
  'columnStyles',
  'rowStyles',
  'responsiveColumns',
  'onMeasure',
  'setResponsiveColumns',
  'setFirstChildRef',
  'refs',
  'isFluidHeight',
  'padding',
];

const MODIFIED_PROPS = [];
const UNSUPPORTED_PROPS = [
  'as',
  'className',
  'celled',
  'container',
  'divided',
  'centered',
  'doubling',
  'padded',
  'stackable',
  'stretched',
  'relaxed',
  'reversed',
];

const propMap = {
  isCentered:  'centered',
  isInverted:  'inverted',
  isDoubling:  'doubling',
  isStackable: 'stackable',
};

/**
 * TODO
 *  - Consider adding isAttached to remove the .ui.grid margins
 */
const Layout = createComponent({
  displayName: 'Layout',
  propTypes:   {
    isDoubling:    PropTypes.bool,
    isStackable:   PropTypes.bool,
    isFluidHeight: PropTypes.bool,
    borders:       PropTypes.oneOf(directions.COMPLETE),
    children:      PropTypes.node,
    columns:       PropTypes.oneOf([...widths.ALL, 'equal', 'responsive']),
    gutterSize:    PropTypes.oneOf([sizes.MEDIUM, sizes.LARGE]),
    padding:       PropTypes.oneOfType([
      PropTypes.oneOf(sizes.S_M_L),
      PropTypes.shape({
        horizontal: PropTypes.oneOf(sizes.S_M_L),
        vertical:   PropTypes.oneOf(sizes.S_M_L),
      }),
    ]),
    columnStyles: PropTypes.shape({
      backgroundColor:   PropTypes.oneOf([...colors.CORE, 'transparent']),
      float:             PropTypes.oneOf(positions.LR),
      textAlign:         PropTypes.oneOf(textAlignments.ALL),
      verticalAlign:     PropTypes.oneOf(positions.alignColumns.ALL),
      isContentCentered: PropTypes.bool,
    }),
    rowStyles: PropTypes.shape({
      isCentered: PropTypes.bool,
    }),
  },
  defaultProps: {
    isDoubling:    false,
    isStackable:   false,
    children:      undefined,
    isFluidHeight: false,
    borders:       null,
    columns:       'equal',
    columnStyles:  undefined,
    rowStyles:     undefined,
    gutterSize:    null,
    padding:       undefined,
  },
  contextTypes: {
    isInverted: PropTypes.bool,
  },
  refs:  'firstChildRef',
  state: {
    responsiveColumns: {
      value: 1,
      updater(setResponsiveColumns, props) {
        return {
          onMeasure: contentRect => {
            const dimensions = contentRect.bounds;

            const count = props.responsiveColumns || React.Children.count(props.children);
            const firstChild = ReactDOM.findDOMNode(props.refs.firstChildRef);

            // max-width is a CSS property and can't be read. Rather than pass in as a prop we will try to make a
            // reasonable guess at its value by always setting it to the max known child width
            maxWidth = firstChild.offsetWidth > maxWidth ? firstChild.offsetWidth : maxWidth;

            // Layout gutters provide padding. Need to account for those in the calculation
            // We have to account for left and right padding so multiply by 2.
            // But it looks better when we scale this down slightly so use 1.8 instead
            let padding;
            if (props.gutterSize === sizes.M) {
              padding = 21 * 1.8;
            } else if (props.gutterSize === sizes.L) {
              padding = 35 * 1.8;
            } else {
              padding = 14 * 1.8;
            }

            // At low column counts the primary calculation (below in the else block) does wrap eagerly enough
            // because the rounding has a greater significance at smaller screen widths so we treat 2 columns and less
            // a little differently
            if (count <= 2 && dimensions.width - firstChild.offsetWidth < maxWidth) {
              setResponsiveColumns(1);
            } else if (Math.round(dimensions.width / firstChild.offsetWidth) !== count) {
              const newCount = Math.round(dimensions.width / (maxWidth + padding));
              setResponsiveColumns(newCount);
            }
          },
        };
      },
    },
  },
  render(props) {
    let UIProps = toUIProps({
      props,
      propMap,
      UNSUPPORTED_PROPS,
      ADDED_PROPS,
      MODIFIED_PROPS,
    });

    delete UIProps.children;

    UIProps = Object.assign({}, UIProps, mapBorders(props));

    UIProps.className = classnames({
      ck:                    true,
      'layout-fluid-height': props.isFluidHeight,
      container:             props.isResponsive,
      ...mapPadding(props),
    });

    if (props.gutterSize === sizes.MEDIUM) {
      UIProps.relaxed = true;
    } else if (props.gutterSize === sizes.LARGE) {
      UIProps.relaxed = 'very';
    }
    logger.warn(
      'HEADS UP, props "padding" and "style: margin" conflict, please use one or the other',
      !!props.style && !!props.style.margin && !!props.padding
    );

    const propChildren = React.Children.toArray(props.children);

    let rowProps;
    let temp = [];
    const lastChildIndex = propChildren.length - 1;

    let chunk;
    if (props.columns === 'equal') {
      chunk = Math.min(propChildren.length, 16);
    } else if (props.columns === 'responsive') {
      UIProps.columns = chunk = props.responsiveColumns;
    } else {
      chunk = widths.toNumber(props.columns);
    }

    //TODO Handle case with no children
    const children = _.reduce(
      propChildren,
      (acc, child, index) => {
        if (Utils.isType([PrivateLayoutRow, LayoutRow], child)) {
          logger.warn('LayoutRow is not supported with responsive columns.', props.columns === 'responsive');

          if (temp.length > 0) {
            rowProps = {
              _parentRowStyles:    props.rowStyles,
              _parentColumnStyles: props.columnStyles,
              key:                 index + Math.random(), //Avoid duplicate key with below
            };

            acc.push(<PrivateLayoutRow {...rowProps}>{temp}</PrivateLayoutRow>);

            temp = [];
          }

          rowProps = Object.assign({}, child.props, {
            _parentRowStyles:    props.rowStyles,
            _parentColumnStyles: props.columnStyles,
            key:                 index,
          });
          acc.push(<PrivateLayoutRow {...rowProps} />);
        } else {
          // A bit hacky, but we need a ref on a child to make the width calculations.
          if (props.columns === 'responsive' && index === 0) {
            child = React.cloneElement(child, { ref: props.setFirstChildRef });
          }
          temp.push(child);

          if (temp.length === chunk || index === lastChildIndex) {
            rowProps = {
              _parentRowStyles:    props.rowStyles,
              _parentColumnStyles: props.columnStyles,
              key:                 index,
            };
            acc.push(<PrivateLayoutRow {...rowProps}>{temp}</PrivateLayoutRow>);

            temp = [];
          }
        }
        return acc;
      },
      []
    );

    return (
      <Choose>
        <When condition={props.columns === 'responsive'}>
          <Measure bounds onResize={props.onMeasure} whitelist={['width', 'height']}>
            {({ measureRef }) => (
              <div ref={measureRef}>
                <UIGrid {...UIProps}>{children}</UIGrid>
              </div>
            )}
          </Measure>
        </When>
        <Otherwise>
          <UIGrid {...UIProps}>{children}</UIGrid>
        </Otherwise>
      </Choose>
    );
  },
});
let maxWidth = 0;

/**
 * builds out the padding string required for grid padding in all directions and three sizes

 * @param      {Object}     p     the component props
 * @return     {Object}           object defining the padding props which will be destructured and added to the component
 */
function mapPadding(p) {
  const { padding } = p;
  return _.isObject(padding) ?
    {
      [`padding-vertical-${padding.vertical === null ? 'none' : padding.vertical}`]:
          !!padding.vertical || padding.vertical === null,
      [`padding-horizontal-${padding.horizontal === null ? 'none' : padding.horizontal}`]:
          !!padding.horizontal || padding.horizontal === null,
    } :
    {
      [`padding-${padding === null ? 'none' : padding}`]: _.isString(padding) || padding === null,
    };
}

function mapBorders(p) {
  switch (p.borders) {
    case directions.V:
      return {
        celled:  false,
        divided: true,
      };
    case directions.H:
      return {
        celled:  false,
        divided: directions.V,
      };
    case directions.VH:
    case directions.HV:
      return {
        celled:  'internally',
        divided: false,
      };
    case directions.ALL:
      return {
        celled:  true,
        divided: false,
      };
    default:
      return {
        celled:  false,
        divided: false,
      };
  }
}

export default whitelistStyles(['flexDirection'])(Layout);
