let widths = {
  ONE:      'one',
  TWO:      'two',
  THREE:    'three',
  FOUR:     'four',
  FIVE:     'five',
  SIX:      'six',
  SEVEN:    'seven',
  EIGHT:    'eight',
  NINE:     'nine',
  TEN:      'ten',
  ELEVEN:   'eleven',
  TWELVE:   'twelve',
  THIRTEEN: 'thirteen',
  FOURTEEN: 'fourteen',
  FIFTEEN:  'fifteen',
  SIXTEEN:  'sixteen',
};

const widthNumbers = {
  [widths.ONE]:      1,
  [widths.TWO]:      2,
  [widths.THREE]:    3,
  [widths.FOUR]:     4,
  [widths.FIVE]:     5,
  [widths.SIX]:      6,
  [widths.SEVEN]:    7,
  [widths.EIGHT]:    8,
  [widths.NINE]:     9,
  [widths.TEN]:      10,
  [widths.ELEVEN]:   11,
  [widths.TWELVE]:   12,
  [widths.THIRTEEN]: 13,
  [widths.FOURTEEN]: 14,
  [widths.FIFTEEN]:  15,
  [widths.SIXTEEN]:  16,
};

function toNumber(width) {
  return isNaN(width * 1) ?
         widthNumbers[width] :
         width;
}

widths = _.reduce(widths, (acc, value, key) => {
  acc[toNumber(value)] = value;
  return acc;
}, widths)

widths.ALL = _.values(widths);
widths.toNumber = toNumber;

export default widths;
