// import { default as _Button } from './button';
import ButtonGroup from './button-group';

import PrimaryButton from './primary-button';
import SecondaryButton from './secondary-button';
import { default as _animatingButtonRedux, AnimatingButton } from './animating-button';

export const animatingButtonRedux = _animatingButtonRedux;
export const Button = {
  Primary:       PrimaryButton,
  Secondary:     SecondaryButton,
  Group:         ButtonGroup,
  WithAnimation: AnimatingButton,
};
export default Button;
