import { assetServices, uploadAssetTypes } from 'enums';
import _ from 'lodash';

import queryString from 'helpers/query-string';

/** ########################################################################################
    PUBLIC
    ######################################################################################## */

/**
 * function will serialize an object into an assetRef string
 */
function serialize(assetRefObj) {
  const refArr = [assetRefObj.service, assetRefObj.assetType, assetRefObj.dim, assetRefObj.location];
  const settingsWithValues = assetRefObj.settings ? queryString.clean(assetRefObj.settings) : {};
  if (!_.isEmpty(settingsWithValues)) {
    refArr.push(queryString.serialize(settingsWithValues));
  }
  return _.some(refArr, (ref) => !_.isEmpty(ref)) ? refArr.join(':') : '';
}

/**
 * function will deserialize an asset ref into an object of props
 * can be used to deserialize an assetRef like 'asset:image:1300x2558:/images/5071/xxxx/zzzz.jpg:type=bg&size=cover&repeat=repeat&attachment=scroll&color=#ffCC00'
 */
function deserialize(assetRef = '') {
  const [service, assetType, dim, ...rest] = assetRef ? assetRef.split(':') : [''];
  let settings;
  let location = '';

  /**
   * The assumption here is that if the "service" is one of the listed ones then the "location" will NOT have a colon so we can safely assume that if "rest" has length of 2, the 2nd segment are asset settings
   * If the "service" were something like "weburl" then the "location" could contain additional colons and the assumption would fall apart
   */

  if (
    (_.includes([assetServices.ASSET, assetServices.BACKGROUND, assetServices.BUNDLE], service) &&
      assetType === uploadAssetTypes.IMAGE) ||
    (_.includes([assetServices.MUX], service) &&
      _.includes([uploadAssetTypes.IMAGE, uploadAssetTypes.VIDEO_URL], assetType))
  ) {
    //an assetRef with settings
    if (rest.length === 2) {
      settings = queryString.deserialize(rest.pop());
      location = rest.join(':');
      // an assetRef without settings
    } else {
      location = rest[0];
    }
  } else if (service && assetType) {
    //a populated/valid assetRef that does not support settings
    location = rest.join(':');
  } else {
    //an empty assetRef with ONLY settings (e.g. "::::size='cover'&color='blue'")
    settings = queryString.deserialize(rest.pop());
    location = '';
  }
  const assetRefObj = {
    service,
    assetType,
    dim,
    dimensions: location ? _extractDimensions(dim) : '',
    location,
    // defaulting this to empty makes it so downstream code doesn't have to do safety checks...need to ensure we check for empty and remove during serialize
    settings: {},
  };

  if (settings) {
    if (settings.status) {
      assetRefObj.status = settings.status;
      delete settings.status;
    }
    assetRefObj.settings = _.assign(settings, {
      type: settings.type,
      size: settings.size,
      attachment: settings.attachment,
      repeat: settings.repeat,
      color: settings.color,
    });
    assetRefObj.settings = _.omitBy(settings, _.isUndefined);
  }

  return assetRefObj;
}

function supportsSettings(assetRefObj) {
  return (
    (assetRefObj.location &&
      _.includes(
        [assetServices.ASSET, assetServices.BACKGROUND, assetServices.BUNDLE],
        assetRefObj.service
      ) &&
      assetRefObj.assetType === uploadAssetTypes.IMAGE) ||
    (_.includes([assetServices.MUX], assetRefObj.service) &&
      _.includes([uploadAssetTypes.IMAGE, uploadAssetTypes.VIDEO_URL], assetRefObj.assetType))
  );
}
/** ########################################################################################
    PRIVATE
    ######################################################################################## */

const _dimensionsRegex = /(\d+)x(\d+)/;

/**
 * will extract the dimensions from a 101x233 string
 */
function _extractDimensions(dim) {
  const isDimValid = _dimensionsRegex.test(dim);
  if (!isDimValid) {
    return 'fluid';
  }

  const [width, height] = dim.split('x').map((x) => `${x}px`);

  return {
    height,
    width,
  };
}

export default { serialize, deserialize, supportsSettings };
