import React from 'react';
import { createComponent, PropTypes } from 'wayin-react';
import { toUIProps } from 'helpers';
import classnames from 'classnames';
import { removeProps, whitelistStyles, mapHandlers } from 'components/core/hoc';
import { compose, withProps, setDisplayName } from 'recompose';

import { Checkbox as UICheckbox } from 'semantic-ui-react';

import { sizes } from 'enums';

const ADDED_PROPS = [
  'isCompact',
  'id',
  'isOn',
  'isSelected',
  'isChecked',
  'isDisabled',
  'isInverted',
  'description',
  'gutterSize',
  'checkSize',
  '_display',
];
const MODIFIED_PROPS = ['style'];
const UNSUPPORTED_PROPS = [
  'as',
  'className',
  'checked',
  'defaultChecked',
  'defaultIndeterminate',
  'indeterminate',
  'disabled',
  'onClick', // use onChange
  'readOnly', // input rendered with readOnly attribute regardless of this prop (prop disables toggling - same as isDisabled)
  'radio',
  'slider',
  'toggle',
];

const propMap = {
  isChecked:  'checked',
  isDisabled: 'disabled',
};

const Checkbox = createComponent({
  displayName: 'Checkbox',
  propTypes:   {
    label:       PropTypes.oneOfType([PropTypes.string, PropTypes.element]), // Stardust does not provide support for more custom labels. Can only be applied to the right
    description: PropTypes.string,
    value:       PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]).isRequired,
    onChange:    PropTypes.func.isRequired,
    gutterSize:  PropTypes.oneOf(sizes.S_M_L),
    checkSize:   PropTypes.oneOf([sizes.X4, sizes.X5, sizes.X6]),

    isChecked:  PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
    isDisabled: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
    isCompact:  PropTypes.bool,
    _display:   PropTypes.oneOf(['checkbox', 'toggle', 'radio']),
    name:       PropTypes.string,
  },
  defaultProps: {
    label:       null,
    description: null,
    isChecked:   false,
    isDisabled:  false,
    isCompact:   undefined,
    gutterSize:  sizes.M,
    checkSize:   sizes.X4,
    // not false, so that it can be
    // set by CheckboxGroup if not present
    _display:    'checkbox',
    name:        null,
  },
  contextTypes: {
    isInverted: PropTypes.bool,
  },
  render(props) {
    const UIProps = toUIProps({
      props,
      propMap,
      UNSUPPORTED_PROPS,
      ADDED_PROPS,
      MODIFIED_PROPS,
    });

    if (props._display === 'radio') {
      UIProps.radio = true;
      UIProps.type = 'radio';
    } else if (props._display === 'toggle') {
      UIProps.toggle = true;
      UIProps.type = 'checkbox';
    } else {
      UIProps.type = 'checkbox';
    }

    UIProps.className = classnames({
      inverted: props.isInverted,
      fitted:   props.isCompact,
    });

    const checkboxClassnames = classnames('ck-checkbox', props._display, {
      description:                              props.description,
      [`checkbox-gutter--${props.gutterSize}`]: props.gutterSize,
      [`checkbox-check--${props.checkSize}`]:   props.checkSize,
    });

    const descriptionClassnames = classnames('ck-checkbox-description', {
      inverted: props.isInverted,
      disabled: props.isDisabled,
    });

    return (
      <div className={checkboxClassnames} style={props.style}>
        <UICheckbox {...UIProps} />
        <If condition={!!props.description}>
          <div onClick={props.onChange} className={descriptionClassnames}>
            {props.description}
          </div>
        </If>
      </div>
    );
  },
});

export const PrivateCheckbox = mapHandlers({ change: ['checked'] })(Checkbox);
export default compose(
  setDisplayName('Checkbox'),
  removeProps(['_display']),
  withProps({ _display: 'checkbox' }),
  whitelistStyles()
)(PrivateCheckbox);
