import React from 'react';
import classnames from 'classnames';
import { createComponent, PropTypes } from 'wayin-react';
import { whitelistStyles } from 'components/core/hoc';

import { positions, colors, sizes } from 'enums';

/* INTERFACE */
const propTypes = {
  tooltip:  PropTypes.string,
  children: PropTypes.node.isRequired,

  isInline:    PropTypes.bool,
  isCompact:   PropTypes.bool,
  position:    PropTypes.oneOf(positions.ALL),
  color:       PropTypes.oneOf([colors.BASE, colors.ERROR]),
  isMultiLine: PropTypes.bool,
  isDisabled:  PropTypes.bool,
  width:       PropTypes.oneOf(sizes.S_M_L),
};

const defaultProps = {
  tooltip:     null,
  isInline:    true,
  position:    positions.TOP,
  color:       colors.BASE,
  isCompact:   false,
  isMultiLine: false,
  isDisabled:  false,
  width:       sizes.S,
};

const contextTypes = {
  isInverted: PropTypes.bool,
};

const state = {
  isVisible: {
    value: false,
    updater(setIsVisible) {
      return {
        show: () => {
          setIsVisible(true);
        },
        hide: () => {
          setIsVisible(false);
        },
      };
    },
  },
};

/* RENDER */
function render(props) {
  const className = classnames(
    {
      ck:                                true,
      tooltip:                           true,
      inline:                            props.isInline,
      inverted:                          props.isInverted,
      [`tooltip--width-${props.width}`]: true,
      ['tooltip--hidden']:               !props.isVisible,
      ['tooltip--compact']:              props.isCompact,
      ['tooltip--multiline']:            props.isMultiLine,
      disabled:                          props.isDisabled,
    },
    props.color
  );

  const ttProps = {
    className,
    style: props.style,
  };

  if (props.tooltip) {
    ttProps['data-ck-tooltip'] = props.tooltip;
    ttProps['data-ck-position'] = POSITION_MAP[props.position] || props.position;
  }

  return (
    <div
      {...ttProps}
      onMouseOver={props.show}
      onMouseDown={props.hide}
      onKeyDown={props.hide}
      onMouseOut={props.hide}
      onFocus={props.hide}
    >
      {props.children}
    </div>
  );
}

/* BLUEPRINT */
const Tooltip = createComponent({
  displayName: 'Tooltip',
  propTypes,
  defaultProps,
  contextTypes,
  state,
  render,
});

/* PRIVATE HELPERS */
const POSITION_MAP = {
  [positions.TOP]:    'top center',
  [positions.BOTTOM]: 'bottom center',
  [positions.LEFT]:   'left center',
  [positions.RIGHT]:  'right center',
};

export default whitelistStyles()(Tooltip);
