import { PrivateAssetUploader } from './asset-uploader';
import { compose, setDisplayName, withProps } from 'recompose';
import { removeProps } from 'components/core/hoc';

const VideoAssetUploader = compose(
  setDisplayName('VideoAssetUploader'),
  withProps({
    showUploadButton:   false,
    showSelectButton:   false,
    showVideoUrlButton: true,
  })
)(PrivateAssetUploader);

export default VideoAssetUploader;