import _ from 'lodash';

const copyEditorFields = {
  TAGLINE:          'tagline',
  HEADING:          'heading',
  BYLINE:           'byline',
  DESCRIPTION:      'description',
  HELP_TEXT:        'helpText',
  FEATURE_IMAGE:    'featureImageAssetRef',
  BACKGROUND_IMAGE: 'backgroundImageAssetRef',
  LINK_TEXT:        'linkText',
  LINK:             'link',
  PRICE:            'price',
  LABEL:            'label',
  CAPTION:          'caption',
};

copyEditorFields.ALL = _.values(copyEditorFields);
copyEditorFields.COMMON = _.difference(copyEditorFields.ALL, [
  copyEditorFields.TAGLINE,
  copyEditorFields.HELP_TEXT,
  copyEditorFields.LINK_TEXT,
  copyEditorFields.BACKGROUND_IMAGE,
  copyEditorFields.PRICE,
]);

export default copyEditorFields;
