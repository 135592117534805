import React from 'react';
import { createComponent, PropTypes } from 'wayin-react';
import { toUIProps } from 'helpers';
import { removeProps, whitelistStyles } from 'components/core/hoc';
import { compose } from 'recompose';

import { Progress as UIProgress } from 'semantic-ui-react';

import { sizes, positions } from 'enums';

const UNSUPPORTED_PROPS = [
  'as',
  'active',
  'autoSuccess',
  'children',
  'className',
  'content',
  'disabled',
  'error',
  'success',
  'indicating',
  'inverted',
  'precision',
  'progress',
  'percent',
  'total',
  'value',
  'warning',
];
const ADDED_PROPS = ['isInverted', 'isDisabled', 'hasError', 'hasSuccess', 'percentComplete', 'label'];
const MODIFIED_PROPS = ['color', 'style'];
const propMap = {
  isInverted:      'inverted',
  isDisabled:      'disabled',
  hasError:        'error',
  hasSuccess:      'success',
  percentComplete: 'percent',
};

const ProgressBar = createComponent({
  displayName: 'ProgressBar',
  propTypes:   {
    isDisabled:      PropTypes.bool,
    hasError:        PropTypes.bool,
    hasSuccess:      PropTypes.bool,
    attached:        PropTypes.oneOf(['', positions.TOP, positions.BOTTOM]),
    color:           PropTypes.string,
    percentComplete: PropTypes.number.isRequired,
    size:            PropTypes.oneOf([sizes.X2, sizes.X3, sizes.X4, sizes.X5, sizes.X6]),
    label:           PropTypes.string,
  },
  defaultProps: {
    isDisabled: false,
    hasError:   false,
    hasSuccess: false,
    attached:   '',
    color:      null,
    size:       sizes.X2,
    label:      '',
  },
  contextTypes: {
    isInverted: PropTypes.bool,
  },
  render(props) {
    const UIProps = toUIProps({ props, propMap, UNSUPPORTED_PROPS, ADDED_PROPS, MODIFIED_PROPS });
    UIProps.className = props.color;

    if (!UIProps.attached) delete UIProps.attached;

    return (
      <div className="ck progress-bar" style={props.style}>
        <UIProgress {...UIProps}>
         {props.label}
        </UIProgress>
      </div>
    );
  },
});

export const PrivateProgressBar = ProgressBar;
export default compose(removeProps(['isDisabled', 'hasError', 'hasSuccess']), whitelistStyles())(ProgressBar);
