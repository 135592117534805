import cx from 'classnames';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
// DraftJS Plugins things.
import { EditorState } from 'draft-js';
import { convertFromHTML } from 'draft-convert';
import Editor from '@draft-js-plugins/editor';
import createMentionPlugin, { defaultTheme as defaultMentionsTheme } from '@draft-js-plugins/mention';

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
// Inline toolbar plugin.
import createInlineToolbarPlugin from '@draft-js-plugins/inline-toolbar';

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
// Toolbar buttons.
import { ItalicButton, BoldButton, UnderlineButton } from '@draft-js-plugins/buttons';

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
// Link plugin.
import createLinkPlugin from '@draft-js-plugins/anchor';

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
// Export to HTML.
import { stateToHTML } from 'draft-js-export-html';

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
// CK things.
import { whitelistStyles } from 'components/core/hoc';
import { icons, colors, positions, sizes } from 'enums';
import evalPredicate from '../../../util/eval-predicate';

import { PrivateLabel } from 'components/core/label';
import Icon from 'components/core/icon';
import Tooltip from 'components/core/tooltip';
import ErrorIcon from 'components/abstractions/error-icon';

// This is important to keep
// When empty, the DraftJS component will have this as it's value
// By knowing this, we can discount this from it's value and therfore correclty
// validate an empty editor
const EMPTY_RICH_TEXT = '<p><br></p>';

const MENTION_CLASS = 'mention';
const ENTITY_TYPE = {
  MENTION: 'mention',
  LINK:    'link'
};

/**
 * when turning the rich data to HTML, this options will be used to create the HTML tags
 */
const getToHtmlConverter = (props) => ({
  //the entityStyleFn can be used to check the entity type.
  entityStyleFn: (entity) => {
    const entityType = entity.get('type').toLowerCase();
    const data = entity.getData();

    switch (entityType){
      case ENTITY_TYPE.LINK:
        return {
          element:    'a',
          attributes: {
            href:   data.url,
            target: '_blank'
          }
        };
      case ENTITY_TYPE.MENTION:
        if (props.useMentions){
          return {
            element:    'span',
            attributes: {
              'data-id':   data.mention.id,
              'data-name': data.mention.name,
              class:       MENTION_CLASS,
            },
          };
        }
        break;
    }
  },
});

/**
 * converting a HTML into RTE data, it will recognize <span> tags with 'mention' class and turn them into a 'mention' entity recognized by RTE
 */
const getFromHtmlConverter = (props) => ({
  htmlToEntity: (nodeName, node, createEntity) => {

    //create a mention entity
    if (props.useMentions && nodeName === 'span' && node.getAttribute('class') === MENTION_CLASS) {
      return createEntity(ENTITY_TYPE.MENTION, 'IMMUTABLE', {
        mention: {
          id:       node.getAttribute('data-id'),
          name:     node.getAttribute('data-name'),
        },
      });
    }

    //create a link entity
    if (nodeName === 'a'){
      return createEntity('LINK', 'MUTABLE', {
        url:   node.getAttribute('href'),
      });
    }
  },
});

/**
 * returns a clean editor content, turns RTE data into HTML
 */
const CleanEditorContent = (editorContent, props) => {
  const toHtmlConverter = getToHtmlConverter(props);
  let _content = stateToHTML(editorContent, toHtmlConverter);
  //some unwanted characters can turn up in the saved html, time to cut them out
  _content = _content.replace(/<br>\n&nbsp;|<br>\n/g, '<br>');
  return _content === EMPTY_RICH_TEXT ? '' : _content;
};

/**
 *
 * @param {*} block - selection block
 * @param {*} entityKey - entity key we want to return boundaries for
 * @returns { start, end } object containing start/end positions of the given entity key
 */
const getEntityBoundaries = (block, entityKey) => {
  const { characterList } = block.toJSON();
  return {
    start: _.findIndex(characterList, {entity: entityKey}),
    end:   _.findLastIndex(characterList, {entity: entityKey})
  };
};

/**
 * Tells if the given selection key is inside a mention entity
 * @param {*} contentState - as provided by the draftjs editor state
 * @param {*} selectionKey - selection start/end key as provided by the draftjs selection code
 * @param {*} position - selection offset, as provided by draftjs selection code
 * @returns
 */
const isPositionInsideMention = (contentState, selectionKey, position) => {
  const keyBlock = contentState.getBlockForKey(selectionKey),
    entityKey = keyBlock.getEntityAt(position);
  if (entityKey) {
    const entity = contentState.getEntity(entityKey);
    if (entity.getType() === ENTITY_TYPE.MENTION){
      const entityPos = getEntityBoundaries(keyBlock, entityKey);
      if (position > entityPos.start && position <= entityPos.end){
        return true;
      }
    }
  }
  return false;
};

/**
 * function can tell if the start or end point of a selection in the RTE input control is happening on/inside a mention.
 * this is needed, because the mention plugin does not make the mention text fully immutable, and because of that, the user could apply bold/italic/underlined to partial mention text for example
 */
const selectionIsInsideMention = (editorState) => {
  const selection = editorState.getSelection();
  if (!selection.isCollapsed()) {
    const contentState = editorState.getCurrentContent();
    return isPositionInsideMention(contentState, selection.getStartKey(), selection.getStartOffset()) ||
        isPositionInsideMention(contentState, selection.getEndKey(), selection.getEndOffset());
  }
  return false;
};

const filterMentions = (mentionList, filterValue) => {
  const searchText = filterValue.toLowerCase();
  return mentionList.filter((m) => {
    return m.fullText.toLowerCase().includes(searchText);
  });
};

/**
 * this is the mention box that shows inside the text editor
 */
const MentionComponent = (props) => {
  return (
    <span className={props.className}>
      {props.children}
    </span>
  );
};

/**
 * this is the suggestion item that shows up in the list of mentions, from where you can choose one
 */
const SuggestionItem = (props) => {
  const {
    mention,
    theme,
    isFocused, // eslint-disable-line no-unused-vars
    searchValue, // eslint-disable-line no-unused-vars
    selectMention, // eslint-disable-line no-unused-vars
    ...parentProps
  } = props;

  return (
    <div {...parentProps}>
      <div className={theme.mentionSuggestionsEntryContainer}>
        <div className={theme.mentionSuggestionsEntryContainerLeft}></div>

        <div className={theme.mentionSuggestionsEntryContainerRight}>
          <div className={theme.mentionSuggestionsEntryText}>
            {mention.fullText}
          </div>
        </div>
      </div>
    </div>
  );
};

class RichText extends Component {
  constructor(props) {
    super(props);

    // instantiate this editor instances plugins
    const inlineToolbarPlugin = createInlineToolbarPlugin();
    const linkPlugin = createLinkPlugin({
      theme: {
        input:        'link-input',
        inputInvalid: 'link-input--invalid',
      },
      placeholder: 'http://…',
    });

    //by default, the 'mention' library is naming it's classes using 'CSS Modules'(https://css-tricks.com/css-modules-part-1-need/) for example 'mnw6qvm', making it quite hard to target those names in our css/less files
    //so on this place, we will use the default mention classes, but also adding our custom class names, for easier css targeting
    const mentionTheme = {
      ...defaultMentionsTheme,
      mention: `${defaultMentionsTheme.mention} mention-plugin__wrapper`,
      mentionSuggestions: `${defaultMentionsTheme.mentionSuggestions} mention-plugin__suggestion-wrapper`,
      mentionSuggestionsEntry: `${defaultMentionsTheme.mentionSuggestionsEntry} mention-plugin__suggestion-entry`,
      mentionSuggestionsEntryFocused: `${defaultMentionsTheme.mentionSuggestionsEntryFocused} mention-plugin__suggestion-entry-focused`,
    }

    const mentionPlugin = createMentionPlugin({
      mentionTrigger:   '@',
      entityMutability: 'IMMUTABLE',
      mentionComponent: MentionComponent,
      theme: mentionTheme
    });

    this.PluginComponents = {
      InlineToolbar:      inlineToolbarPlugin.InlineToolbar,
      LinkButton:         linkPlugin.LinkButton,
      MentionSuggestions: mentionPlugin.MentionSuggestions
    };

    this.plugins = [inlineToolbarPlugin, linkPlugin, mentionPlugin];

    //https://github.com/HubSpot/draft-convert
    const fromHtmlConverter = getFromHtmlConverter(props);
    const contentState = convertFromHTML(fromHtmlConverter)(props.value || '');

    this.state = {
      editorState:      EditorState.createWithContent(contentState),
      suggestions:      props.mentionList,
      suggestionFilter: '',
      mentionIsOpen:    false,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleKeyCommand = this.handleKeyCommand.bind(this);
    this.handleFocus = this.handleFocus.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
    this.onSuggestionSearch = this.onSuggestionSearch.bind(this);
    this.handleOpenChange = this.handleOpenChange.bind(this);
  }

  componentDidUpdate(prevProps, prevState) {
    //This is the fix for updating the 'draft-js-plugins-editor' component internal state from a value received from props.
    //Please note, because we are comparing the props.value with the this.state value, we can’t use the componentWillReceiveProps function,
    //as the state is NOT updated with the value set in handleChange(editorState) when the componentWillReceiveProps is being called.
    //We also check for current vs. prev props value changes, to avoid setting the state when the value isn't updated between updates
    if ((this.props.value !== prevProps.value && CleanEditorContent(this.state.editorState.getCurrentContent(), this.props) !== this.props.value) ||
      this.props.useMentions !== prevProps.useMentions) {
      const fromHtmlConverter = getFromHtmlConverter(this.props);
      const contentState = convertFromHTML(fromHtmlConverter)(this.props.value || '');
      this.setState({
        editorState: EditorState.createWithContent(contentState),
      });
    }

  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!_.isEqual(this.props.mentionList, nextProps.mentionList)){
      this.setState({
        suggestions: filterMentions(nextProps.mentionList, this.state.suggestionFilter)
      });
    }
  }

  handleChange(editorState) {
    const { id, onChange, value } = this.props;

    if (!selectionIsInsideMention(editorState)){
      this.setState({
        editorState,
      });

      if (!!onChange){
        const newValue = CleanEditorContent(editorState.getCurrentContent(), this.props);
        //the underlying RTE component fires the change event when the field gets focus. It is unnecessary in that case to call the onChange function
        if (value !== newValue){
          onChange({}, { value: newValue, id });
        }
      }


    } else {
      this.setState({
        editorState: this.state.editorState,
      });
    }
  }

  handleKeyCommand(command, editorState) {
    if (command === 'split-block' && this.props.isMultiline === false) {
      return 'handled'; // this tells the editor to ignore the key stroke
    }
    return 'not-handled';
  }

  handleFocus(e) {
    const { id, onFocus } = this.props;
    this.editor.focus();
    !!onFocus && onFocus(e, { value: CleanEditorContent(this.state.editorState.getCurrentContent(), this.props), id });
  }

  handleBlur(e) {
    const { id, onBlur } = this.props;
    onBlur && onBlur(e, { value: CleanEditorContent(this.state.editorState.getCurrentContent(), this.props), id });
  }

  handleOpenChange(_open){
    this.setState({
      mentionIsOpen: _open
    });
  }

  onSuggestionSearch({ value }) {
    this.setState({
      suggestionFilter: value,
      suggestions:      filterMentions(this.props.mentionList, value)
    });
  }

  render() {
    const { InlineToolbar, LinkButton, MentionSuggestions } = this.PluginComponents;

    const _hasError = evalPredicate(this.props.hasError) || !!this.props.errorMessage;
    const _isReadOnly = evalPredicate(this.props.readOnly);
    const _isDisabled = evalPredicate(this.props.isDisabled);

    const _cls = cx('ck', 'rich-text', {
      'rich-text--error':          _hasError,
      'rich-text--read-only':      _isReadOnly,
      'rich-text--disabled':       _isDisabled, // note that for this component, isDisabled and readOnly are functionally the same thing
      'rich-text--multiline':      this.props.isMultiline,
      'rich-text--fluid':           this.props.isFluid,
      'rich-text--focus':          this.props.hasFocus,
      'rich-text--has-error-icon': this.props.hasErrorIcon,
      'rich-text--inverted':       this.context.isInverted,
    });

    const _editor = (
      <div className="rich-text__editor-wrapper" onClick={this.handleFocus} onBlur={this.handleBlur}>
        <Editor
          editorState={this.state.editorState}
          readOnly={_isReadOnly || _isDisabled}
          onChange={this.handleChange}
          handleKeyCommand={this.handleKeyCommand}
          placeholder={this.props.placeholder}
          onBlur={this.props.handleBlur}
          plugins={this.plugins}
          ref={(el) => {
            this.editor = el;
          }}
        />
        <InlineToolbar>
          {(externalProps) => (
            <div>
              <BoldButton {...externalProps} />
              <ItalicButton {...externalProps} />
              <UnderlineButton {...externalProps} />
              <LinkButton {...externalProps} />
            </div>
          )}
        </InlineToolbar>
        <If condition={!!this.props.useMentions}>
          <MentionSuggestions
            open={this.state.mentionIsOpen}
            onOpenChange={this.handleOpenChange}
            className="suggestions"
            onSearchChange={this.onSuggestionSearch}
            suggestions={this.state.suggestions}
            entryComponent={SuggestionItem}
            />
        </If>
        <div className="rich-text__help-icon">
          <Tooltip
            tooltip={`Select some text and use the popup toolbar to apply basic styling. ${
              this.props.isMultiline ? 'Use the return key to add line breaks.' : ''
            }`}
            position={positions.LEFT}
          >
            <Icon name={icons.TEXT} size={sizes.X5} />
          </Tooltip>
        </div>
      </div>
    );

    return (
      <div className={_cls} style={this.props.style}>
        <If condition={!!this.props.label}>
          <PrivateLabel text={this.props.label} />
        </If>
        <Choose>
          <When condition={this.props.hasErrorIcon}>
            <div className="error-wrapper">
              {_editor}
              <ErrorIcon
                errorMessage={this.props.errorMessage}
                errorMessagePosition={this.props.errorMessagePosition}
              />
            </div>
          </When>
          <Otherwise>
            <div className="error-wrapper">
              <Tooltip
                tooltip={this.props.errorMessage}
                color={colors.ERROR}
                position={this.props.errorMessagePosition}
              >
                {_editor}
              </Tooltip>
            </div>
          </Otherwise>
        </Choose>
      </div>
    );
  }
}

RichText.contextTypes = {
  isInverted: PropTypes.bool,
};

RichText.propTypes = {
  label:                PropTypes.string,
  id:                   PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  value:                PropTypes.string,
  placeholder:          PropTypes.string,
  isFluid:              PropTypes.bool,
  isMultiline:          PropTypes.bool,
  hasError:             PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  hasErrorIcon:         PropTypes.bool,
  errorMessage:         PropTypes.string,
  errorMessagePosition: PropTypes.oneOf(positions.ALL),
  readOnly:             PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  isDisabled:           PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  onChange:             PropTypes.func,
  onBlur:               PropTypes.func,
  onFocus:              PropTypes.func,
  useMentions:          PropTypes.bool,
  mentionList:             PropTypes.arrayOf(
    PropTypes.shape({
      name:     PropTypes.string,
      id:       PropTypes.string,
      fullText: PropTypes.string,
    })
  ),
};

RichText.defaultProps = {
  label:                null,
  id:                   undefined,
  value:                '',
  placeholder:          null,
  isFluid:              true,
  isMultiline:          true,
  hasError:             false,
  hasErrorIcon:         true,
  errorMessage:         undefined,
  errorMessagePosition: positions.TOP_LEFT,
  readOnly:             false,
  isDisabled:           false,
  onChange:             _.noop,
  onBlur:               _.noop,
  onFocus:              _.noop,
  useMentions:          false,
  mentionList:          [],
};

export default whitelistStyles()(RichText);
