const buttonStates = {
  DEFAULT: 'default',
  LOADING: 'loading',
  ERROR:   'error',
  SUCCESS: 'success',
};

buttonStates.ALL = _.values(buttonStates);

export default buttonStates;
