/**
 * holds the components to show up the 'library asset select' window, having controls for listing and filtering assets
 */
import React from 'react';
import _ from 'lodash';
import { createComponent, PropTypes } from 'wayin-react';
import Layout from 'components/core/layout';
import Panel from 'components/abstractions/panel';
import TextMenu from 'components/core/text-menu';
import Paging from 'components/core/paging';
import { SingleSelect } from 'components/core/dropdown';
import LoadingState from 'components/abstractions/loading-state';
import AssetList from './asset-list';
import { positions, sizes, widths, textAlignments, directions, documentTypes, sortOptions } from 'enums';
import BigSearch from 'components/abstractions/big-search';
import * as FileUtils from 'components/core/asset-uploader/file-utils';

/**
 * filter scope values
 */
const FILTER_SCOPE = {
  ALL:        'all',
  EXPERIENCE: 'experience',
  LIBRARY:    'library',
};

const SEARCH_DELAY = 500;
const ITEMS_PER_PAGE = 50;

/**
 * function will load the asset list using the passed params object
 * @param params - params to be sent for filtering the asset list
 * @param onDataReceived - function to be called when data is received
 * @param onError - function to be called when error happens
 */
const loadAssets = (params, onDataReceived, onError, CKConfig) => {
  let xhr = new XMLHttpRequest(),
    fd = new FormData();

  if (params && typeof params === 'object') {
    for (const key in params) {
      fd.append(key, params[key]);
    }
  }

  xhr.open(CKConfig.assetUploader.postMethod, CKConfig.assetUrls.assetListUrl, true);
  xhr.addEventListener(
    'load',
    evt => {
      if (evt.currentTarget.readyState == 4 && evt.currentTarget.status == 200) {
        const response = JSON.parse(evt.target.responseText);
        if (response && response.status && response.status == 'ok') {
          onDataReceived.call(this, response.content, response.page + 1, response.count);
        } else {
          onError.call(this, response.errorMessage);
        }
      } else {
        onError.call(this, 'IO error loading meta data');
      }
    },
    false
  );
  xhr.addEventListener(
    'error',
    evt => {
      onError.call(this, evt.currentTarget.responseText);
    },
    false
  );
  xhr.send(fd);
};

/**
 * Will build out the necessary params we need to send for filtering the assets list. Will set the state when assets are loaded. Will also set the 'isFetching' accordingly.
 * @param p - available props
 * @param f - filters object having 'scope' and 'category'
 */
const filterAssets = (p, f, page) => {
  const fileType = FileUtils.getFileTypeFromDocumentType(p.documentTypes);
  const params = {
    classRef_filter:     f.category || '',
    libAndContainer:     f.scope == FILTER_SCOPE.ALL,
    containerRef_filter:
      f.scope == FILTER_SCOPE.LIBRARY && p.libraryRef ? p.libraryRef : p.containerRef || '',
    typeRef_filter: fileType,
    offset:         (page - 1) * ITEMS_PER_PAGE,
    max:            ITEMS_PER_PAGE,
    sort:           p.sort,
    order:          p.order,
    search:         f.search.commaSeparated || '',
  };
  //load in the initial list of assets
  p.setIsFetching(true);
  p.setCurrentPage(page);
  loadAssets(
    params,
    (assetList, apiPage, itemsTotal) => {
      p.setAssetList(assetList);
      p.setCurrentPage(apiPage);
      p.setItemsTotal(itemsTotal);
      p.setIsFetching(false);
    },
    errorMsg => {
      p.setCurrentPage(0);
      p.setItemsTotal(0);
      p.setIsFetching(false);
    },
    p.CKConfig,
  );
};

const debouncedFilterAssets = _.debounce(filterAssets, SEARCH_DELAY);

/**
 * asset selector component, displaying filter controls and list of assets in a Modal window
 */
const LibrarySelect = createComponent({
  displayName: 'LibrarySelect',

  propTypes: {
    libraryRef:      PropTypes.string,
    containerRef:    PropTypes.string,
    onAssetSelected: PropTypes.func.isRequired,
    documentTypes:   PropTypes.array,
    sort:            PropTypes.string,
    order:           PropTypes.oneOf(['ASC', 'DESC']),
    assetCategories: PropTypes.arrayOf(
      //should contain the list of asset categories to show in the 'category filter' dropdown
      PropTypes.shape({
        text:  PropTypes.string,
        value: PropTypes.string,
      })
    ),
    isInModal: PropTypes.bool,
    CKConfig:  PropTypes.object,
    displayFilterScope: PropTypes.bool,
  },
  defaultProps: {
    libraryRef:      '',
    containerRef:    '',
    documentTypes:   [documentTypes],
    sort:            sortOptions.LAST_UPDATED,
    order:           'DESC',
    assetCategories: [],
    isInModal:       false,
    CKConfig:        {},
    displayFilterScope: true,
  },
  lifecycles: {
    componentDidMount() {
      filterAssets(this.props, this.props.filters, 1);
    },
  },
  state: {
    isFetching:  false,
    currentPage: 1,
    itemsTotal:  0,
    assetList:   {
      value:   [],
      updater: 'setAssetList',
    },
    filters: {
      value: {
        category: '',
        scope:    FILTER_SCOPE.ALL,
        search:   {
          commaSeparated: '',
          original:       '',
        },
      },
      updater: 'setFilters',
    },
  },
  // Would move to mapDispatchToProps
  handlers: {
    onCategoryChange: p => (e, v) => {
      const f = Object.assign({}, p.filters, { category: v.value });
      p.setFilters(f); //this isn't happening right away, so updated props are not available to be passed to 'filterAssets', that's why having 'f'
      filterAssets(p, f, 1);
    },
    onScopeChange: p => (e, v) => {
      const f = Object.assign({}, p.filters, { scope: v.value });
      p.setFilters(f);
      filterAssets(p, f, 1);
    },
    onPageChange: p => (e, v) => {
      if (v.value) {
        filterAssets(p, p.filters, v.value);
      } else {
        p.setCurrentPage(null);
      }
    },
    onActiveSearchChange: p => (e, v) => {
      const commaSeparated = v.value.split(/,+\ *|\ +/).filter(term => term !== '').join(','); // Backend call accepts a comma separated string
      const f = Object.assign({}, p.filters, { search: { commaSeparated, original: v.value } });
      p.setFilters(f);
      debouncedFilterAssets(p, f, 1);
    },
    onSearchClear: p => (e, v) => {
      const f = Object.assign({}, p.filters, { search: { commaSeparated: '', original: '' } });
      p.setFilters(f);
      debouncedFilterAssets(p, f, 1);
    }
  },
  contextTypes: {
    isInverted: PropTypes.bool,
  },
  render(props) {
    const {
      assetList,
      onCategoryChange,
      assetCategories,
      onScopeChange,
      onAssetSelected,
      onActiveSearchChange,
      onSearchClear,
      isFetching,
      filters,
      containerRef,
      onPageChange,
      currentPage,
      itemsTotal,
      isInModal,
      CKConfig,
      displayFilterScope
    } = props;
    const isPopulated = !!filters.search.original;

    return (
      <Panel hasBorder={false} padding={{ horizontal: sizes.X1, vertical: sizes.X1 }}>
        <div className="library-select">
          <If condition={isFetching}>
            <div className="library-select-loading">
              <Layout isFluidHeight columnStyles={{ verticalAlign: positions.verticalAlign.MIDDLE }}>
                <LoadingState header="Loading" height={sizes.L} spinnerSize={sizes.M} />
              </Layout>
            </div>
          </If>
          <Layout columnStyles={{ textAlign: textAlignments.LEFT }} borders={directions.H}>
            <Layout.Row columns={widths.THREE} style={{ padding: '0 0 10px 0' }}>
              <Layout.Column
                columnWidth={widths.SIX}
                textAlign={textAlignments.LEFT}
                verticalAlign={positions.verticalAlign.MIDDLE}
              >
                <SingleSelect
                  value={filters.category}
                  onChange={onCategoryChange}
                  options={assetCategories}
                  placeholder="Filter by Category..."
                  search={false}
                  isDisabled={false}
                  hasError={false}
                  isFluid={true}
                  showClearIcon={true}
                />
              </Layout.Column>
              <Layout.Column columnWidth={isInModal ? widths.FIVE : widths.SIX} textAlign={textAlignments.LEFT} verticalAlign={positions.verticalAlign.MIDDLE}>
                <If condition={displayFilterScope && containerRef.length > 0}>
                  <TextMenu
                    isInverted={props.isInverted}
                    items={[
                      { text: 'all', value: FILTER_SCOPE.ALL },
                      { text: 'library', value: FILTER_SCOPE.LIBRARY },
                      { text: 'experience', value: FILTER_SCOPE.EXPERIENCE },
                    ]}
                    activeValue={filters.scope}
                    float={positions.LEFT}
                    onItemClick={onScopeChange}
                    size={sizes.X4}
                  />
                </If>
              </Layout.Column>
              <Layout.Column columnWidth={widths.FOUR} textAlign={textAlignments.RIGHT} verticalAlign={positions.verticalAlign.MIDDLE}>
                <BigSearch
                  onChange={onActiveSearchChange}
                  placeholder="Search assets..."
                />
              </Layout.Column>
            </Layout.Row>
            <Layout.Row style={{ padding: '5px 0px 10px 0px' }}>
              <AssetList list={assetList} onAssetSelected={onAssetSelected} isCompact={isInModal} userTimeZone={CKConfig.userTimeZone}/>
            </Layout.Row>
            <Layout.Row style={{ padding: '10px 0px 5px 0px' }}>
              <Paging
                itemsTotal={itemsTotal}
                currentPage={currentPage}
                itemsPerPage={ITEMS_PER_PAGE}
                isDisabled={false}
                onChange={onPageChange}
              />
            </Layout.Row>
          </Layout>
        </div>
      </Panel>
    );
  },
});

export default LibrarySelect;
