/**
 * holds the components to show up the 'library asset select' window, having controls for listing and filtering assets
 */
import React from 'react';
import { createComponent, PropTypes } from 'wayin-react';
import Modal from 'components/core/modal';
import LibrarySelect from './library-select';
import { documentTypes } from 'enums';

/**
 * asset selector component, displaying filter controls and list of assets in a Modal window
 */
const LibrarySelectModal = createComponent({
  displayName: 'LibrarySelectModal',

  propTypes: {
    trigger:         PropTypes.oneOfType([PropTypes.element, PropTypes.string]).isRequired,
    libraryRef:      PropTypes.string,
    containerRef:    PropTypes.string,
    onAssetSelected: PropTypes.func.isRequired,
    documentTypes:   PropTypes.array,
    sort:            PropTypes.string,
    order:           PropTypes.oneOf(['ASC', 'DESC']),
    assetCategories: PropTypes.arrayOf(
      //should contain the list of asset categories to show in the 'category filter' dropdown
      PropTypes.shape({
        text:  PropTypes.string,
        value: PropTypes.string,
      })
    ),
  },
  defaultProps: {
    libraryRef:      '',
    containerRef:    '',
    documentTypes:   [documentTypes],
    sort:            undefined,
    order:           undefined,
    assetCategories: [],
  },
  render(props) {
    const {
      trigger,
      ...librarySelectProps
    } = props;

    return (
      <Modal
        trigger={trigger}
        hasCloseIcon
        isSolid
        isInverted={false}
        isFullscreen={false}
      >
        <LibrarySelect {...librarySelectProps} isInModal={true} />
      </Modal>
    );
  },
});

export default LibrarySelectModal;
