import React from 'react';
import { createComponent, PropTypes } from 'wayin-react';

//  our custom components
import { SingleSelect } from 'components/core/dropdown';
import Input from 'components/core/input';
import Icon from 'components/core/icon';
import Layout from 'components/core/layout';
import Label from 'components/core/label';
import Tooltip from 'components/core/tooltip';
import { mapHandlers, whitelistStyles } from 'components/core/hoc';
import { compose } from 'recompose';

import validators from '../../../validators';

import { positions, widths, sizes, icons } from 'enums';

const renderCTADropdown = props => {
  const internalErrorMessage = props.errorMessage || validators.Messages.InvalidURLMessage;
  return (
    <div className="ck-cta-dropdown" style={props.style}>
      <If condition={props.label}>
        <Label text={props.label} size={sizes.X4} />
      </If>
      <Layout columns={props.isStacked ? widths.ONE : null}>
        <Layout.Column columnWidth={props.isStacked ? null : widths.SIX}>
          <Tooltip tooltip="This text will appear on your CTA button." isInline={false}>
            <Input
              value={props.buttonText}
              placeholder={'[Link Text]'}
              onChange={props.onButtonTextChange}
              id={props.id}
              innerRef={props.innerRef}
            />
          </Tooltip>
        </Layout.Column>
        <Layout.Column columnWidth={props.isStacked ? null : widths.TEN}>
          <Choose>
            <When condition={props.isInputVisible}>
              <Input
                icon={<Icon name={icons.DOWN} onClick={props.handleInputIconClick} />}
                autoFocus={props.isInputAutoFocus}
                onChange={props.handleInputChange}
                onKeyPress={props.handleEnter}
                value={(_.get(props.value, 'length') && props.value) || 'http://'}
                id={props.id}
                onBlur={props.handleOnBlur}
                hasError={!props.isValidURL}
                errorMessage={!props.isValidURL ? internalErrorMessage : ''}
                errorMessagePosition={props.errorMessagePosition}
              />
            </When>
            <Otherwise>
              <div ref={props.setDropdownRef}>
                <SingleSelect
                  search
                  noResultsMessage={null}
                  defaultOpen={props.isDropdownDefaultOpen}
                  placeholder={'Select CTA destination'}
                  onChange={props.handleChange}
                  options={props.dropdownOptions}
                  value={props.value}
                  id={props.id}
                  showClearIcon={props.shouldShowClearIcon}
                  errorMessage={props.errorMessage}
                  errorMessagePosition={props.errorMessagePosition}
                  onSearchChange={props.handleDropdownSearch}
                />
              </div>
            </Otherwise>
          </Choose>
        </Layout.Column>
      </Layout>
    </div>
  );
};

const isURL = url => validators.Regexes.PRURL_REGEX.test(url);

const isValidURL = url =>
  // requires "http://" or "https://"
  validators.Regexes.URL_REGEX.test(url);

const addURL = p => {
  const url = p.value;

  if (isValidURL(url)) {
    const exists = _.find(p.dropdownOptions, { value: url });
    if (!exists) {
      p.setDropdownOptions(p.dropdownOptions.concat([{ text: url, value: url }]));
    }
    p.hideInput();
    p.onChange(undefined, { value: url, id: p.id });
  }
};

const CTADropdown = createComponent({
  displayName: 'CTADropdown',
  propTypes:   {
    label:   PropTypes.string,
    options: PropTypes.arrayOf(
      PropTypes.shape({
        text:  PropTypes.string,
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      })
    ).isRequired,
    value:                PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    onChange:             PropTypes.func.isRequired,
    onButtonTextChange:   PropTypes.func.isRequired,
    buttonText:           PropTypes.string,
    // validatable
    onBlur:               PropTypes.func, // could be exposed if/when we need it to be
    errorMessage:         PropTypes.string,
    errorMessagePosition: PropTypes.oneOf(positions.ALL),
    isStacked:            PropTypes.bool,
    shouldShowClearIcon:  PropTypes.bool,
    autoFocusInput:       PropTypes.bool,
    innerRef:             PropTypes.func,
  },
  defaultProps: {
    value:                '',
    buttonText:           '',
    label:                '',
    onBlur:               null,
    errorMessage:         '',
    errorMessagePosition: positions.TOP_LEFT,
    isStacked:            false,
    shouldShowClearIcon:  true,
    autoFocusInput:       true,
    innerRef:             undefined,
  },
  refs:       'dropdownRef',
  lifecycles: {
    UNSAFE_componentWillReceiveProps(nextProps) {
      const { setDropdownOptions } = nextProps;

      if (nextProps.options !== this.props.options) {
        setDropdownOptions(nextProps.options);
      }
    },
    componentDidUpdate() {
      if (this.props.isDropdownDefaultOpen) {
        this.props.refs.dropdownRef.querySelector('.ui.dropdown input').focus();
        this.props.dropdownDefaultClose();
      }
    },
  },
  handlers: {
    handleOnBlur: p => (e, { value, id }) => {
      p.setIsValidURL(isValidURL(value));
      !!p.onBlur && p.onBlur(e, { value, id });
    },
    handleDropdownSearch: p => (e, { value, id }) => {
      //when a user starts typing directly (or uses copy-paste to enter a url) into the dropdown, we need to switch the control to 'text input'
      if (!!value && (isValidURL(value) || value.indexOf('http://') === 0 || value.indexOf('https://') === 0)){
        p.showInput();
        p.onChange(e, { value, id });
        !p.isValidURL && p.setIsValidURL(true);
      }
    },
    handleChange: p => (e, { value, id }) => {
      if (value === 'url') {
        p.showInput();
        p.onChange(e, { value: 'http://', id });
      }
      p.dropdownDefaultClose();

      // don't pass 'url' option to onChange
      if (value !== 'url') {
        p.onChange(e, { value, id });
      }
    },
    handleInputChange: p => (e, { value, id }) => {
      p.onChange(e, { value, id });

      !p.isValidURL && isValidURL(value) && p.setIsValidURL(true);
    },
    handleEnter: p => e => {
      if (e.key === 'Enter') {
        addURL(p);
      }
    },
    handleInputIconClick: p => (e, props) => {
      if (isURL(p.value)) {
        addURL(p);
      } else {
        p.hideInput();
      }
      p.onChange(undefined, { value: '' });
      p.dropdownDefaultOpen();
    },
  },
  state: {
    dropdownOptions: {
      value:   p => p.options,
      updater: 'setDropdownOptions',
    },
    isInputVisible: {
      value: p => _.get(p.value, 'length') && !_.find(p.options, { value: p.value }),
      updater(updater) {
        return {
          showInput: () => updater(true),
          hideInput: () => updater(false),
        };
      },
    },
    isDropdownDefaultOpen: {
      value: false,
      updater(updater) {
        return {
          dropdownDefaultOpen:  () => updater(true),
          dropdownDefaultClose: () => updater(false),
        };
      },
    },
    isValidURL: true,

    //WYN-6050 [Victor] Setting the value of the autofocus on the INPUT element only.
    //On initial render this will set it to what ever it was declared on the parent component and it can ONLY be set from False to True when the User adds a valid URL and triggers the Input element.
    //This is to prevent the "screen scroll to input" problem but it also allows for the focus on input once it shows up so that users have a better experience in typing in the input once the select value has been set.
    isInputAutoFocus: {
      value: p => {
        return p.autoFocusInput;
      },
      updater: 'setAutoFocus',
    }
  },
  render(props) {
    return renderCTADropdown(props);
  },
});

export default compose(mapHandlers({ onButtonTextChange: [] }), whitelistStyles())(CTADropdown);
