import moment from "moment";
import momentLocalizer from "react-widgets-moment";

function init() {
  moment.updateLocale("en", {
    weekdaysMin: ["S", "M", "T", "W", "T", "F", "S", "S"],
    longDateFormat: {
      LT: "h:mm a",
      LTS: "h:mm:ss A",
      L: "DD/MM/YYYY",
      LL: "D MMMM YYYY",
      LLL: "LL LT",
      LLLL: "dddd, MMMM Do YYYY LT"
    },
    week: {
      dow: 1
    }
  });
  moment.updateLocale("en-US", {
    weekdaysMin: ["S", "M", "T", "W", "T", "F", "S", "S"],
    longDateFormat: {
      LT: "h:mm a",
      LTS: "h:mm:ss A",
      L: "MM/DD/YYYY",
      LL: "MMMM D YYYY",
      LLL: "LL LT",
      LLLL: "dddd, MMMM Do YYYY LT"
    },
    week: {
      dow: 1
    }
  });
  moment.locale("en");
  momentLocalizer(moment);
}

export default {
  init
};
