const directions = {
  V:   'vertically',
  H:   'horizontally',
  VH:  'vertically horizontally',
  HV:  'horizontally vertically',
  ALL: 'all',
};

directions.COMPLETE = _.values(directions);
directions.BASIC = [directions.V, directions.H, directions.ALL];

export default directions;
