const statuses = {
  OK:           'ok',
  WARNING:      'warning',
  ERROR:        'error',
  STARTED:      'started',
  STOPPED:      'stopped',
  PAUSED:       'paused',
  AUTHORIZED:   'authorized',
  UNAUTHORIZED: 'unauthorized',
  DRAFT:        'draft',
  READY:        'ready',
  SCHEDULED:    'scheduled',
  PUBLISHED:    'published',
  EXPIRED:      'expired',
  ARCHIVED:     'archived',
  ENABLED:      'enabled',
  DISABLED:     'disabled',
  VALID:        'valid',
  INVALID:      'invalid',
  FAILED:       'failed',
  EMBARGO:      'embargo',
  REVIEW:       'review',
  ACTIVE:       'active',
  INACTIVE:     'inactive',
};

statuses.ALL = _.values(statuses);
statuses.notice = {
  OK:      'ok',
  WARNING: 'warning',
  ERROR:   'error',
};
statuses.activity = {
  STARTED: 'started',
  STOPPED: 'stopped',
  PAUSED:  'paused',
};
statuses.authorization = {
  AUTHORIZED:   'authorized',
  UNAUTHORIZED: 'unauthorized',
};
statuses.publish = {
  DRAFT:     'draft',
  READY:     'ready',
  SCHEDULED: 'scheduled',
  PUBLISHED: 'published',
  EXPIRED:   'expired',
  ARCHIVED:  'archived',
};

statuses.notice.ALL = _.values(statuses.notice);
statuses.activity.ALL = _.values(statuses.activity);
statuses.authorization.ALL = _.values(statuses.authorization);
statuses.publish.ALL = _.values(statuses.publish);

export default statuses;
