import React from 'react';

export default function cloneChildrenWithDefaultProps(props, propWhitelist) {
  const defaultProps = propWhitelist !== undefined ?
                       _.pick(props, propWhitelist) :
                       props;
                        
  let childProps;
  const children = React.Children.toArray(props.children);
  return _.map(children, (child, index) => {
    childProps = _.assign({}, defaultProps, child.props);
    return React.cloneElement(child, childProps)
  });
}