/**
  Helper function to get a forms errors list
**/
const getFormErrors = (form = {}) => {
  //  extract the 'errors' prop for each fieldId
  const errors = _.reduce(
    _.keys(form),
    (memo, fieldId) => {
      memo[fieldId] = _.get(form, [fieldId, 'errors'], []);
      return memo;
    },
    {}
  );
  return errors;
};

/**
  Helper function to get a form's field values
**/
const getFormValues = (form = {}) => {
  //  extract the 'value' prop for each fieldId
  const formValues = _.reduce(
    //  we don't need to worry about the 'form' level prop as it is reserved for form level validation errors
    _.keys(_.omit(form, 'form')),
    (memo, fieldId) => {
      memo[fieldId] = _.get(form, [fieldId, 'value']);
      return memo;
    },
    {}
  );
  return formValues;
};

/**
  Helper function to get a form's field validation contexts
**/
const getFormFieldContexts = (form = {}) => {
  //  extract the 'value' prop for each fieldId
  const formFieldContexts = _.reduce(
    //  we don't need to worry about the 'form' level prop as it is reserved for form level validation errors
    _.keys(_.omit(form, 'form')),
    (memo, fieldId) => {
      memo[fieldId] = _.get(form, [fieldId, 'validationContext']);
      return memo;
    },
    {}
  );
  return formFieldContexts;
};

/**
*  Helper function to get field validators.
*
*/
const getFieldValidators = (form, fieldId) => _.get(form, [fieldId, 'validators'], {});

/**
*  Helper to get form validators
*
*/
const getFormValidators = form => _.get(form, ['form', 'validators'], {});

/**
  Helper function to get a field validation context
**/
const getFieldContext = (form = {}, fieldId) => {
  return _.get(form, [fieldId, 'validationContext']);
};

/**
  Helper function to get the derived 'isValid' value using the form's errors
**/
const isFormValid = (form = {}) => {
  const errors = getFormErrors(form);
  return _.find(_.values(errors), value => !_.isEmpty(value)) === undefined;
};

/**
  Helper function to get the derived 'isDirty' value using the form's fields
**/
const isFormDirty = (form = {}) => {
  const foundDirty = _.find(_.omit(form, 'errors'), ['isDirty', true]);
  return foundDirty !== undefined;
};

export { getFormErrors, getFormValues, getFieldValidators, getFormValidators, getFieldContext, getFormFieldContexts, isFormValid, isFormDirty };
