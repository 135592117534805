import { delay, takeEvery, race, all, put, call, take } from 'redux-saga/effects';
import _ from 'lodash';
import { Actions } from './animating-button-duck';

// Keep an internal list of registered buttons keyed by the startAction for easier lookup
let registeredButtons = {};

function* registerButtonHandler({ payload }) {
  const { id } = payload;
  const actions = _.isArray(payload.actions) ? payload.actions : [payload.actions];
  _.forEach(actions, ({ start, success, failure }) => {
    const actionType = getActionType(start);
    registeredButtons[actionType] = { id, success, failure };
  });
}

function* deregisterButton({ payload }) {
  // There can be multiple registrations for a single id
  registeredButtons = _.omitBy(registeredButtons, action => action.id === payload.id);
}

function* registeredButtonHandler({ type }) {
  // This is the beginning of a new animation flow. Retrieve the animation description
  const registeredButton = registeredButtons[type];

  // Start the animation
  yield put(Actions.startAnimation(registeredButton.id));

  // Wait for either the success or the failure action to be dispatched. We build in a 250ms minimum duration to allow the animation to play out
  const [{ success }] = yield all([
    race({
      success: take(getActionType(registeredButton.success)),
      failure: take(getActionType(registeredButton.failure)),
    }),
    delay(250),
  ]);

  // Dispatch the success/failure actions depending on what occurred
  if (success) {
    yield put(Actions.success(registeredButton.id));
  } else {
    yield put(Actions.failure(registeredButton.id));
  }

  // Build in another delay to let the animation play out
  yield delay(500);

  // Finish the animation
  yield put(Actions.finishAnimation(registeredButton.id));
}

function isRegisteredButton({ type }) {
  // only listen on actions that have been registered, otherwise, ignore
  return !_.isUndefined(registeredButtons[type]);
}

function getActionType(action) {
  if (!!action.getType && typeof action.getType === 'function') return action.getType();
  if (typeof action === 'string') return action;
  return '';
}

export default function* animatingButtonSaga() {
  yield all([
    takeEvery(Actions.registerButton.getType(), registerButtonHandler),
    takeEvery(isRegisteredButton, registeredButtonHandler),
    takeEvery(Actions.deregisterButton.getType(), deregisterButton),
  ]);
}
