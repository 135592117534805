import React from 'react';
import { createComponent, PropTypes } from 'wayin-react';
import { whitelistStyles } from 'components/core/hoc';
import classnames from 'classnames';
import { positions, icons, sizes } from 'enums';
import color from 'color';

import { ChromePicker } from 'react-color';
import Label from 'components/core/label';
import Input from 'components/core/input';
import Icon from 'components/core/icon';
import Tooltip from 'components/core/tooltip';

import validators from '../../../validators';

import ColorSwatch from 'components/abstractions/color-swatch';

// Color picker has its own internal 'isColor' validation

const ColorPicker = createComponent({
  displayName: 'ColorPicker',
  PropTypes: {
    value:         PropTypes.string,
    id:            PropTypes.oneOf([PropTypes.string, PropTypes.number]),
    position:      PropTypes.oneOf([positions.TOP_LEFT, positions.TOP_RIGHT, positions.BOTTOM_LEFT, positions.BOTTOM_RIGHT]),
    label:         PropTypes.string,
    showClearIcon: PropTypes.bool,
    onChange:      PropTypes.func.isRequired,
    isCompact:     PropTypes.bool,
    hasInput:      PropTypes.bool,
    onHidePicker:  PropTypes.func,
    onShowPicker:  PropTypes.func,
  },
  defaultProps: {
    value:         undefined,
    id:            undefined,
    position:      positions.BOTTOM_RIGHT,
    showClearIcon: false,
    label:         undefined,
    isCompact:     true,
    hasInput:      true,
    onHidePicker:  _.noop,
    onShowPicker:  _.noop,
  },
  contextTypes: {
    isInverted: PropTypes.bool,
  },
  lifecycles: {
    UNSAFE_componentWillMount() {
      let currentColor;
      if (!!this.props.value && validators.isColor()(this.props.value) === true) {
        const rgb = getRGB(this.props.value);

        currentColor = getColorForCurrentView(this.props.currentView, rgb);
      }
      this.props.setCurrentColor(currentColor);
    },
    UNSAFE_componentWillReceiveProps(nextProps) {
      if (!this.props.isPickerVisible && nextProps.isPickerVisible) {
        document.addEventListener('click', this.props.handleDocumentClick);
        document.addEventListener('keydown', this.props.handleKeyDown);
      } else if (this.props.isPickerVisible && !nextProps.isPickerVisible) {
        document.removeEventListener('click', this.props.handleDocumentClick);
        document.removeEventListener('keydown', this.props.handleKeyDown);
      }

      // only coerce color to new view type if it is a valid color
      // if not a valid color, leave value as is, which is the only case
      // where the input and picker view types could be out of sync
      if (nextProps.isPickerVisible) {
        if (this.props.currentView === 'hex' && nextProps.currentView === 'rgb') {
          const colorString =
            validators.isColor()(nextProps.value) === true
              ? getCleanColor(nextProps.value)
                  .rgb()
                  .string()
              : nextProps.value;
          this.props.setCurrentColor(colorString);
        }
        if (this.props.currentView === 'rgb' && nextProps.currentView === 'hex') {
          const rgbObj =
            !!nextProps.value &&
            !isShortHex(nextProps.value) &&
            validators.isColor()(nextProps.value) === true &&
            getCleanColor(nextProps.value).object();
          const colorString = !!rgbObj ? rgbToHex(rgbObj) : nextProps.value;
          this.props.setCurrentColor(colorString);
        }
      }

      if (this.props.value !== nextProps.value) {
        this.props.setCurrentColor(nextProps.value);
      }
    },
    componentWillUnmount() {
      document.removeEventListener('click', this.props.handleDocumentClick);
      document.removeEventListener('keydown', this.props.handleKeyDown);
    },
  },
  state: {
    isPickerVisible: {
      value: false,
      updater(updater, props) {
        return {
          toggleColorPicker: (e, { value, id }) => {
            props.isPickerVisible? props.onHidePicker(e, { value, id }) : props.onShowPicker(e, { value, id });
            updater(isPickerVisible => !isPickerVisible);
          },
          hideColorPicker: (e, { value, id }) => {
            props.onHidePicker(e, { value, id });
            updater(false);
          },
          showColorPicker: (e, { value, id }) => {
            props.onShowPicker(e, { value, id });
            updater(true);
          },
        };
      },
    },
    currentView: {
      value: p => {
        // to sync input with initial view of picker, if color value does not have transparency, coerce to hex
        const alpha = validators.isColor()(p.value) === true ? getCleanColor(p.value).alpha() : 1;
        return !p.value || !hasTransparency(alpha) ? 'hex' : 'rgb';
      },
      updater: 'setCurrentView',
    },
    currentColor: {
      // current color represents value prop coerced to either hex or rgb depending on picker view
      value: p => p.value,
      updater: 'setCurrentColor',
    },
    internalErrorMessage: {
      value: '',
      updater: 'setInternalErrorMessage',
    },
  },
  refs: ['pickerRef'],
  handlers: {
    handleKeyDown: props => e => {
      // enter key
      if (e.keyCode === 13) {
        props.onChange(e, { value: props.currentColor, id: props.id });
        props.hideColorPicker(e, { value: props.currentColor, id: props.id });
      }
      // escape key
      if (e.keyCode === 27) {
        props.hideColorPicker(e, { value: props.currentColor, id: props.id });
      }
    },
    handleColorSelection: props => (value, event) => {
      const color = getColorForCurrentView(props.currentView, value.rgb);

      //clear internal error if now valid
      if (!!props.internalErrorMessage && validators.isColor()(color) === true) {
        props.setInternalErrorMessage('');
      }

      props.setCurrentColor(color);
      props.onChange(event, { value: color, id: props.id });
    },
    handleColorChange: props => (e, { value, id }) => {
      //clear internal error if now valid
      if (!!props.internalErrorMessage && validators.isColor()(value) === true) {
        props.setInternalErrorMessage('');
      }
      props.setCurrentColor(value);
      props.onChange(e, { value, id });
    },
    handleColorClear: props => (e, { id }) => {
      props.onChange(e, { value: '', id });
      props.setCurrentColor('');
    },
    // this is called when picker view toggle is clicked, and when picker alpha is changed
    handleViewChange: props => view => {
      props.setCurrentView(view);
    },
    handleDocumentClick: props => event => {
      const wasPickerClicked = props.refs.pickerRef.contains(event.target);
      if (wasPickerClicked) return;
      props.hideColorPicker(event, { value: props.currentColor, id: props.id });
    },
    handleBlur: props => (ev, { id, value }) => {
      const internalErrorMessage = validators.isColor()(value) !== true ? 'Enter a valid color' : '';
      props.setInternalErrorMessage(internalErrorMessage);
      props.onBlur && props.onBlur(ev, { id, value });
    },
  },
  render(props) {
    let rgba;

    if (validators.isColor()(props.currentColor) === true) {
      // convert string to rgb object for <ChromePicker>
      if (!!props.currentColor && props.currentColor === 'transparent') {
        rgba = props.currentColor;
      } else if (!!props.currentColor) {
        const rgb = getRGB(props.currentColor);
        rgba = {
          r: rgb.r,
          g: rgb.g,
          b: rgb.b,
          a: rgb.alpha || 1,
        };
      }
    }
    return (
      <div
        className={classnames('ck color-picker', `color-picker--${props.position}`, {
          ['color-picker--inverted']: props.isInverted,
          ['color-picker--has-input']: props.hasInput,
          compact: props.isCompact,
        })}
        ref={props.setPickerRef}
        style={props.style}
      >
        <If condition={props.label}>
          <Label text={props.label} />
        </If>
        <div className="color-picker__positioner">
          <If condition={props.isPickerVisible}>
            <ChromePicker
              color={rgba}
              initialView={props.currentView}
              onChangeComplete={props.handleColorSelection}
              onViewChange={props.handleViewChange}
            />
          </If>
          <Choose>
            <When condition={props.hasInput}>
              <Input
                action={{
                  content: <ColorSwatch value={props.currentColor} onClick={props.toggleColorPicker} />,
                  position: positions.LEFT,
                }}
                value={props.currentColor}
                onChange={props.handleColorChange}
                errorMessage={props.errorMessage || props.internalErrorMessage}
                onBlur={props.handleBlur} // validatable neccessity
                placeholder="transparent"
                id={props.id}
              />
              <If condition={props.value && props.showClearIcon}>
                <Tooltip tooltip="clear current color" position={positions.TOP_LEFT} isCompact>
                  <Icon name={icons.CANCEL} size={sizes.X5} id={props.id} onClick={props.handleColorClear} />
                </Tooltip>
              </If>
            </When>
            <Otherwise>
              <ColorSwatch value={props.currentColor} onClick={props.showColorPicker} hasBorder />
            </Otherwise>
          </Choose>
        </div>
      </div>
    );
  },
});

function getCleanColor(value) {
  return !!value ? color(value.toLowerCase()) : color(value || 'transparent');
}

function componentToHex(component) {
  const hex = component.toString(16);
  return hex.length === 1 ? '0' + hex : hex;
}

function rgbToHex(rgbObj) {
  return '#' + componentToHex(rgbObj.r) + componentToHex(rgbObj.g) + componentToHex(rgbObj.b);
}

function hasTransparency(colorAlpha) {
  return colorAlpha !== 1;
}

function getColorForCurrentView(view, colorObj) {
  let currentColor;

  switch (view) {
    case 'rgb':
      if (colorObj.r === 0 && colorObj.g === 0 && colorObj.b === 0 && (colorObj.alpha === 0 || colorObj.a === 0))
        currentColor = 'transparent';
      else currentColor = `rgba(${colorObj.r}, ${colorObj.g}, ${colorObj.b}, ${colorObj.alpha || colorObj.a})`;
      break;
    case 'hex':
      const hex = rgbToHex(colorObj);
      currentColor = hex;
      break;
  }
  return currentColor;
}

function getRGB(currentColor) {
  return color(currentColor.toLowerCase())
    .rgb()
    .object();
}

function isShortHex(value) {
  // simple length check is all that is needed here
  return !!value && value.length < 5;
}

export default whitelistStyles()(ColorPicker);
