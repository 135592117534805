import { PrivateCheckbox } from '../checkbox';
import { withProps, mapProps, compose, setDisplayName } from 'recompose';

const Radio = compose(
  setDisplayName('Radio'),
  withProps({ _display: 'radio' }),
  mapProps(({ isSelected, ...rest }) => ({
    isChecked: isSelected,
    ...rest,
  }))
)(PrivateCheckbox);

export default Radio;

/*
 label:       PropTypes.string, // Stardust does not provide support for more custom labels. Can only be applied to the right
 description: PropTypes.string,
 value:       PropTypes.string.isRequired,
 onChange:    PropTypes.func.isRequired,
 isSelected:  PropTypes.oneOfType([ PropTypes.bool, PropTypes.func ]),
 isDisabled:  PropTypes.oneOfType([ PropTypes.bool, PropTypes.func ]),
 isCompact:   PropTypes.bool
 */
