import React from 'react';
import { createComponent, PropTypes } from 'wayin-react';

import Tooltip from '../../core/tooltip';
import Icon from '../../core/icon';

import { positions, colors, sizes, icons } from 'enums';

const ErrorIcon = createComponent({
  displayName: 'ErrorIcon',
  propTypes:   {
    errorMessage:         PropTypes.string,
    errorMessagePosition: PropTypes.oneOf(positions.ALL),
  },
  defaultProps: {
    errorMessage:         '',
    errorMessagePosition: positions.TOP,
  },
  render(props) {
    return (
      <Tooltip tooltip={props.errorMessage} color={colors.ERROR} position={props.errorMessagePosition}>
        <Icon name={icons.WARNING} size={sizes.X6} color={colors.ERROR} />
      </Tooltip>
    );
  },
});

export default ErrorIcon;
