const uploadAssetTypes = {
  VIDEO_URL:     'video',
  IMAGE:         'image',
  INLINE_SVG:    'inlinesvg',
  GENERATED_SVG: 'generatedsvg',
  FILE:          'file',
  GIF:           'gif',
};
uploadAssetTypes.ALL = _.values(uploadAssetTypes);

export default uploadAssetTypes;
