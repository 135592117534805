let devices = {
  orientation: {
    MOBILE:              'mobile',
    MOBILE_VERTICALLY:   'mobile vertically',
    TABLET:              'tablet',
    TABLET_VERTICALLY:   'tablet vertically',
    COMPUTER:            'computer',
    COMPUTER_VERTICALLY: 'computer vertically',
  },
  sizes: {
    MOBILE:            'mobile',
    TABLET:            'tablet',
    TABLET_AND_MOBILE: 'tablet mobile',
    COMPUTER_1X:       'computer',
    COMPUTER_2X:       'large screen',
    COMPUTER_3X:       'widescreen',
  }
};

devices.orientation.ALL = _.values(devices.orientation);
devices.sizes.ALL = _.values(devices.sizes);

export default devices;