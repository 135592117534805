import { createSelector } from 'reselect';

import _ from 'lodash';

import {
  getFormErrors,
  getFormValues,
  getFormValidators,
  getFieldValidators,
  getFieldContext,
  getFormFieldContexts,
  isFormDirty,
  isFormValid,
} from './form-utils';

const Selectors = {};

const getForm = (state, formId) => {
  return state.ck.form[formId];
};

const getForms = (state, formIds) => {
  _.reduce(
    formIds,
    (memo, formId) => {
      memo[formId] = state[formId];
      return memo;
    },
    {}
  );
};

Selectors.getFormValues = createSelector([getForm], form => getFormValues(form));

Selectors.getFieldContext = createSelector([getForm, (state, formId, fieldId) => fieldId], (form, fieldId) =>
  getFieldContext(form, fieldId)
);

Selectors.getFormFieldContexts = createSelector([getForm], form => getFormFieldContexts(form));

Selectors.getFormErrors = createSelector([getForm], form => getFormErrors(form));

Selectors.getFieldValidators = createSelector([getForm, (state, formId, fieldId) => fieldId], (form, fieldId) =>
  getFieldValidators(form, fieldId)
);

Selectors.getFormValidators = createSelector([getForm], form => getFormValidators(form));

Selectors.isFormValid = createSelector([getForm], form => isFormValid(form));

Selectors.isFormDirty = createSelector([getForm], form => isFormDirty(form));

Selectors.getFieldValue = createSelector([getForm, (state, formId, fieldId) => fieldId], (form, fieldId) =>
  _.get(form, [fieldId, 'value'])
);

Selectors.getFieldErrors = createSelector([getForm, (state, formId, fieldId) => fieldId], (form, fieldId) =>
  _.get(form, [fieldId, 'errors'], [])
);

Selectors.isFieldValid = createSelector(
  [getForm, (state, formId, fieldId) => fieldId],
  (form, fieldId) => _.get(form, [fieldId, 'isValid']) //  may be derived later
);

Selectors.isFieldDirty = createSelector(
  [getForm, (state, formId, fieldId) => fieldId],
  (form, fieldId) => _.get(form, [fieldId, 'isDirty']) //  may be derived later
);

//  state is always changing so probably not being memoized
Selectors.areFormsValid = createSelector([(state, formIds) => state, (state, formIds) => formIds], (state, formIds) =>
  _.every(formIds, formId => Selectors.isFormValid(state, formId))
);

export default Selectors;
