const promptTypes = {
  INFO:    'info',
  WARNING: 'warning',
  ERROR:   'error',
};

const messageTypes = {
  ...promptTypes,
  SUCCESS: 'success',
};

promptTypes.ALL = _.values(promptTypes);
messageTypes.ALL = _.values(messageTypes);

export { messageTypes, promptTypes };
