const assetServices = {
  ASSET:       'asset',
  BACKGROUND:  'background',
  BAZAARVOICE: 'bazaarvoice',
  BUNDLE:      'bundle',
  EXT:         'ext',
  FACEBOOK:    'facebook',
  FILE:        'file',
  FLICKR:      'flickr',
  GOOGLEPLUS:  'googleplus',
  INSTAGRAM:   'instagram',
  LINKEDIN:    'linkedin',
  LITHIUM:     'lithium',
  MUX:         'mux',
  NEWSCRED:    'newscred',
  PINTEREST:   'pinterest',
  STATIC:      'static',
  TWITTER:     'twitter',
  UGC:         'ugc',
  UGCX:        'ugcx',
  VIMEO:       'vimeo',
  VINE:        'vine',
  VK:          'vk',
  WEB_URL:     'weburl',
  YOUTUBE:     'youtube',
};

assetServices.ALL = _.values(assetServices);

export default assetServices;
