import React from 'react';
import { createComponent, PropTypes } from 'wayin-react';

import { ConfigService } from 'services';

import { positions, sizes, colors, widths, textAlignments, documentTypes as DOCUMENT_TYPES, icons } from 'enums';

import { UploaderMessages } from './file-utils';

import Layout from 'components/core/layout';
import Button from 'components/core/button';
import Label from 'components/core/label';
import LibrarySelect from 'components/abstractions/library-select';

/**
 * view for selecting/uploading a file
 */
const FileSelectView = createComponent({
  displayName: 'FileSelectView',

  propTypes: {
    assetCategories: PropTypes.arrayOf(
      PropTypes.shape({
        text:  PropTypes.string,
        value: PropTypes.string,
      })
    ),
    showUploadButton:    PropTypes.bool,
    showSelectButton:    PropTypes.bool,
    showVideoUrlButton:  PropTypes.bool,
    uploadButtonText:    PropTypes.string,
    selectButtonText:    PropTypes.string,
    videoButtonText:     PropTypes.string,
    dragAndDropText:     PropTypes.string,
    onFileSelected:      PropTypes.func.isRequired,
    onSelectFromLibrary: PropTypes.func.isRequired,
    onShowVideoInput:    PropTypes.func.isRequired,
    libraryRef:          PropTypes.string,
    containerRef:        PropTypes.string,
    documentTypes:       PropTypes.array,
    hasFluidButtons:     PropTypes.bool,
    displayFilterScope:  PropTypes.bool.isRequired,
  },

  defaultProps: {
    showUploadButton:   true,
    showSelectButton:   true,
    showVideoUrlButton: true,
    assetCategories:    [],
    libraryRef:         '',
    containerRef:       '',
    documentTypes:      [DOCUMENT_TYPES.IMAGE],
    uploadButtonText:   '',
    selectButtonText:   '',
    videoButtonText:    '',
    dragAndDropText:    '',
    hasFluidButtons:    false,
  },

  //refs: 'inputRef',

  render(props) {
    const {
        onFileSelected,
        showUploadButton,
        showSelectButton,
        showVideoUrlButton,
        onShowVideoInput,
        assetCategories,
        libraryRef,
        containerRef,
        documentTypes,
        uploadButtonText,
        selectButtonText /*, setInputRef*/,
        videoButtonText,
        dragAndDropText,
        displayFilterScope
      } = props,
      isVideoOnly = showVideoUrlButton && !showSelectButton && !showUploadButton;
    let inputRef;
    const browseFile = () => {
      inputRef.click();
    };
    const ConsoleKitConfig = ConfigService.values();

    return (
      <div className="upload-view file-select-view">
        <div className="upload-view__inner">
          <Layout
            isFluidHeight
            padding={{
              horizontal: 'small',
            }}
            columnStyles={{
              textAlign:     textAlignments.CENTER,
              verticalAlign: positions.verticalAlign.MIDDLE,
            }}
            style={{ margin: 0 }}
          >
            {/* displays upload/select/video url buttons */}
            <Layout.Row columns={widths.TWO}>
              <Layout.Column columnWidth={widths.NINE}>
                {/* file input will be hidden as we want a fully custom button for it */}
                <input
                  ref={input => (inputRef = input)}
                  type="file"
                  style={{ display: 'none' }}
                  onChange={onFileSelected}
                />
                <Label
                  icon={isVideoOnly ? icons.EDIT : icons.UPLOAD}
                  text={
                    dragAndDropText ||
                    (isVideoOnly ? UploaderMessages.ENTER_VIDEO_URL : UploaderMessages.DRAG_AND_DROP_FILES_LABEL)
                  }
                  size={sizes.X5}
                  color={colors.BASE}
                />
              </Layout.Column>
              <Layout.Column columnWidth={widths.SEVEN}>
                <Button.Group isFluid={props.hasFluidButtons} layout="vertical">
                  {/* upload button, shows for image/media modes */}
                  <If condition={showUploadButton}>
                    <Button.Primary
                      id="uploadButton"
                      text={uploadButtonText || UploaderMessages.UPLOAD_NEW}
                      size={sizes.X1}
                      onClick={browseFile}
                      isFluid={props.hasFluidButtons}
                    />
                  </If>
                  {/* library select button, shows for image/media modes */}
                  <If condition={showSelectButton}>
                    <LibrarySelect.Modal
                      trigger={
                        <Button.Primary
                          onClick={_.noop}
                          text={selectButtonText || UploaderMessages.SELECT_FROM_LIBRARY}
                          size={sizes.X1}
                        />
                      }
                      onAssetSelected={props.onSelectFromLibrary}
                      assetCategories={assetCategories}
                      libraryRef={libraryRef}
                      containerRef={containerRef}
                      documentTypes={documentTypes}
                      CKConfig={ConsoleKitConfig}
                      displayFilterScope={displayFilterScope}
                    />
                  </If>
                  {/* video url button, shows for video/media modes */}
                  <If condition={showVideoUrlButton}>
                    <Button.Primary
                      id="videoButton"
                      text={videoButtonText || UploaderMessages.VIDEO_URL}
                      size={sizes.X1}
                      onClick={onShowVideoInput}
                      isFluid={props.hasFluidButtons}
                    />
                  </If>
                </Button.Group>
              </Layout.Column>
            </Layout.Row>
          </Layout>
        </div>
      </div>
    );
  },
});

export default FileSelectView;
