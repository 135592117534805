import React from 'react';
import { createComponent, PropTypes } from 'wayin-react';
import { toUIProps } from 'helpers';
import { positions, colors, directions } from 'enums';
import classnames from 'classnames';
import { removeProps, whitelistStyles, mapHandlers } from 'components/core/hoc';
import { compose } from 'recompose';

import { Menu as UIMenu } from 'semantic-ui-react';
import Tooltip from 'components/core/tooltip';

const UNSUPPORTED_PROPS = [
  'as',
  'active',
  'className',
  'disabled',
  'icon',
  'link',
  'position', // unsupported until we have a use case for this
];

const ADDED_PROPS = [
  'id',
  'isActive',
  'isHeader',
  'isIconOnly',
  'isDisabled',
  'isDivided',
  'fitted',
  'dataTitle',
  'value',
  'tooltip',
  'tooltipPosition',
  'tooltipIsInline',
  'tooltipIsCompact',
  'tooltipIsMultiline',
];

const MODIFIED_PROPS = [
  'fitted',
  'color', // allows us to pass it as a classname vs. a prop to avoid semantic-ui-react warnings
  'style',
  // 'position',
];

const propMap = {
  isActive:   'active',
  isHeader:   'header',
  isIconOnly: 'icon',
  isDisabled: 'disabled',
};

const MenuItem = createComponent({
  displayName: 'MenuItem',
  propTypes:   {
    children: PropTypes.node,
    content:  PropTypes.node, // shorthand instead of children, children overrides; mutually exclusive

    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    name:  PropTypes.string, // will be used for content if neither children nor content props are defined

    /** Style as the currently chosen item. */
    isActive:   PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
    isHeader:   PropTypes.bool,
    isIconOnly: PropTypes.bool,
    isDisabled: PropTypes.bool,
    isDivided:  PropTypes.bool,
    color:      PropTypes.oneOf(colors.CORE),

    fitted:  PropTypes.oneOf(directions.BASIC),
    // position: PropTypes.oneOf( positions.LR ), // 'left' is default
    onClick: PropTypes.func,

    // from tooltipify
    tooltip:            PropTypes.string,
    tooltipPosition:    PropTypes.oneOf(positions.ALL),
    tooltipIsInline:    PropTypes.bool,
    tooltipIsCompact:   PropTypes.bool,
    tooltipIsMultiline: PropTypes.bool,
  },
  defaultProps: {
    children:   null, // mutually exlusive w/ content
    content:    undefined, // shows warning with null value; mutually exclusive w/ children
    value:      null,
    name:       null,
    isActive:   false,
    isHeader:   false,
    isIconOnly: false,
    isDisabled: false,
    isDivided:  false,
    color:      colors.ACCENT,
    fitted:     null,
    // position: positions.LEFT,
    onClick:    null,

    // from tooltipify
    tooltip:            null,
    tooltipPosition:    null,
    tooltipIsInline:    true,
    tooltipIsCompact:   true,
    tooltipIsMultiline: false,
  },
  render(props) {
    const UIProps = toUIProps({ props, propMap, UNSUPPORTED_PROPS, ADDED_PROPS, MODIFIED_PROPS });
    if (props.children === null) delete UIProps.children;
    // if (props.position === positions.RIGHT) {
    //   UIProps.position = props.position;
    // }

    UIProps.className = classnames(props.color, {
      divided:        props.isDivided,
      fitted:         !!props.fitted,
      [props.fitted]: props.fitted === directions.H || props.fitted === directions.V,
    });

    return (
      <Choose>
        <When condition={props.tooltip}>
          <Tooltip
            isCompact={props.tooltipIsCompact}
            isInline={props.tooltipIsInline}
            position={props.tooltipPosition}
            tooltip={props.tooltip}
            isMultiLine={props.tooltipIsMultiline}
          >
            <div className={classnames('ck', 'menu-item', { divided: props.isDivided })} style={props.style}>
              <If condition={props.isDivided}>
                <hr className="menu-item-divider" />
              </If>
              <UIMenu.Item {...UIProps} />
            </div>
          </Tooltip>
        </When>
        <When condition={!props.tooltip}>
          <div className={classnames('ck', 'menu-item', { divided: props.isDivided })} style={props.style}>
            <If condition={props.isDivided}>
              <hr className="menu-item-divider" />
            </If>
            <UIMenu.Item {...UIProps} />
          </div>
        </When>
      </Choose>
    );
  },
});

export const PrivateMenuItem = mapHandlers({ click: [] })(MenuItem);

export default compose(
  removeProps(['color', 'content', 'isHeader']),
  whitelistStyles()
)(PrivateMenuItem);
