import _ from 'lodash';

import colors from './colors';
import icons from './icons';

const timelineEvents = {
  ACTIVATE:     'activate',
  APPROVE:      'approve',
  ARCHIVE:      'archive',
  ASK:          'ask',
  ASSIGN:       'assign',
  C:            'c',
  CLEAN:        'clean',
  CLONE:        'clone',
  CM:           'cm',
  COMMENT:      'comment',
  CONFIRM:      'confirm',
  CONNECT:      'connect',
  CREATE:       'create',
  CT:           'ct',
  CU:           'cu',
  DANGER:       'danger',
  DELETE:       'delete',
  DENY:         'deny',
  DISABLE:      'disable',
  DOWNLOAD:     'download',
  E:            'e',
  EDIT:         'edit',
  ENABLE:       'enable',
  ERROR:        'error',
  EXPORT:       'export',
  FILL:         'fill',
  G:            'g',
  GRANT:        'grant',
  I:            'i',
  INFO:         'info',
  INVALIDATE:   'invalidate',
  JOIN:         'join',
  LK:           'lk',
  LOCKING:      'locking',
  LOGIN:        'login',
  PRIMARY:      'primary',
  PUBLISH:      'publish',
  R:            'r',
  RE:           're',
  RESELECT:     'reselect',
  RESET:        'reset',
  S:            's',
  SAVE:         'save',
  SELECT:       'select',
  SEND:         'send',
  SHUFFLE:      'shuffle',
  START:        'start',
  STOP:         'stop',
  SUBSTITUTE:   'substitute',
  SUCCESS:      'success',
  SWITCH:       'switch',
  UNAPPROVE:    'unapprove',
  UNINVALIDATE: 'uninvalidate',
  UPDATE:       'update',
  UPLOAD:       'upload',
  V:            'v',
  VO:           'vo',
  WARNING:      'warning',
};

const timelineBadgeColors = {
  [timelineEvents.ACTIVATE]:     colors.ACTION,
  [timelineEvents.APPROVE]:      colors.ACTION,
  [timelineEvents.ARCHIVE]:      colors.ACTION,
  [timelineEvents.ASK]:          colors.ACCENT,
  [timelineEvents.ASSIGN]:       colors.ACTION,
  [timelineEvents.C]:            colors.BASE,
  [timelineEvents.CLEAN]:        colors.BASE,
  [timelineEvents.CLONE]:        colors.ACTION,
  [timelineEvents.CM]:           colors.ACCENT,
  [timelineEvents.COMMENT]:      colors.ACCENT,
  [timelineEvents.CONFIRM]:      colors.ACTION,
  [timelineEvents.CONNECT]:      colors.ACTION,
  [timelineEvents.CREATE]:       colors.ACTION,
  [timelineEvents.CT]:           colors.ACCENT,
  [timelineEvents.CU]:           colors.BASE,
  [timelineEvents.DANGER]:       colors.ERROR,
  [timelineEvents.DELETE]:       colors.WARNING,
  [timelineEvents.DENY]:         colors.WARNING,
  [timelineEvents.DISABLE]:      colors.BASE,
  [timelineEvents.DOWNLOAD]:     colors.ACCENT,
  [timelineEvents.E]:            colors.ACCENT,
  [timelineEvents.EDIT]:         colors.ACCENT,
  [timelineEvents.ENABLE]:       colors.ACTION,
  [timelineEvents.ERROR]:        colors.WARNING,
  [timelineEvents.EXPORT]:       colors.ACTION,
  [timelineEvents.FILL]:         colors.ACCENT,
  [timelineEvents.G]:            colors.ACTION,
  [timelineEvents.GRANT]:        colors.ACTION,
  [timelineEvents.I]:            colors.BASE,
  [timelineEvents.INFO]:         colors.ACCENT,
  [timelineEvents.INVALIDATE]:   colors.WARNING,
  [timelineEvents.JOIN]:         colors.ACTION,
  [timelineEvents.LK]:           colors.ACCENT,
  [timelineEvents.LOCKING]:      colors.WARNING,
  [timelineEvents.LOGIN]:        colors.ACTION,
  [timelineEvents.PRIMARY]:      colors.ACCENT,
  [timelineEvents.PUBLISH]:      colors.ACCENT,
  [timelineEvents.R]:            colors.ACTION,
  [timelineEvents.RE]:           colors.ACTION,
  [timelineEvents.RESELECT]:     colors.BASE,
  [timelineEvents.RESET]:        colors.BASE,
  [timelineEvents.S]:            colors.ACCENT,
  [timelineEvents.SAVE]:         colors.ACTION,
  [timelineEvents.SELECT]:       colors.ACCENT,
  [timelineEvents.SEND]:         colors.ACTION,
  [timelineEvents.SHUFFLE]:      colors.ACCENT,
  [timelineEvents.START]:        colors.ACCENT,
  [timelineEvents.STOP]:         colors.WARNING,
  [timelineEvents.SUBSTITUTE]:   colors.ACCENT,
  [timelineEvents.SUCCESS]:      colors.ACCENT,
  [timelineEvents.SWITCH]:       colors.BASE,
  [timelineEvents.UNAPPROVE]:    colors.WARNING,
  [timelineEvents.UNINVALIDATE]: colors.ACCENT,
  [timelineEvents.UPDATE]:       colors.ACCENT,
  [timelineEvents.UPLOAD]:       colors.ACTION,
  [timelineEvents.V]:            colors.ACCENT,
  [timelineEvents.VO]:           colors.ACCENT,
  [timelineEvents.WARNING]:      colors.WARNING,
};

const timelineIcons = {
  [timelineEvents.ACTIVATE]:     icons.APPROVE,
  [timelineEvents.APPROVE]:      icons.APPROVE,
  [timelineEvents.ARCHIVE]:      icons.ARCHIVE,
  [timelineEvents.ASK]:          icons.QUIZ,
  [timelineEvents.ASSIGN]:       icons.APPROVE,
  [timelineEvents.C]:            icons.BUG,
  [timelineEvents.CLEAN]:        icons.BUG,
  [timelineEvents.CLONE]:        icons.COPY,
  [timelineEvents.CM]:           icons.BUG,
  [timelineEvents.COMMENT]:      icons.COMMENT,
  [timelineEvents.CONFIRM]:      icons.APPROVE,
  [timelineEvents.CONNECT]:      icons.CONNECT,
  [timelineEvents.CREATE]:       icons.BUG,
  [timelineEvents.CT]:           icons.BUG,
  [timelineEvents.CU]:           icons.BUG,
  [timelineEvents.DANGER]:       icons.DANGER,
  [timelineEvents.DELETE]:       icons.CANCEL,
  [timelineEvents.DENY]:         icons.BUG,
  [timelineEvents.DISABLE]:      icons.BUG,
  [timelineEvents.DOWNLOAD]:     icons.DOWNLOAD,
  [timelineEvents.E]:            icons.BUG,
  [timelineEvents.EDIT]:         icons.EDIT,
  [timelineEvents.ENABLE]:       icons.PLAY,
  [timelineEvents.ERROR]:        icons.WARNING,
  [timelineEvents.EXPORT]:       icons.BUG,
  [timelineEvents.FILL]:         icons.RETWEET,
  [timelineEvents.G]:            icons.BUG,
  [timelineEvents.GRANT]:        icons.BUG,
  [timelineEvents.I]:            icons.BUG,
  [timelineEvents.INFO]:         icons.INFO,
  [timelineEvents.INVALIDATE]:   icons.CANCEL,
  [timelineEvents.JOIN]:         icons.BUG,
  [timelineEvents.LK]:           icons.BUG,
  [timelineEvents.LOCKING]:      icons.BUG,
  [timelineEvents.LOGIN]:        icons.BUG,
  [timelineEvents.PRIMARY]:      icons.BUG,
  [timelineEvents.PUBLISH]:      icons.PUBLISH,
  [timelineEvents.R]:            icons.BUG,
  [timelineEvents.RE]:           icons.BUG,
  [timelineEvents.RESELECT]:     icons.RETWEET,
  [timelineEvents.RESET]:        icons.RETWEET,
  [timelineEvents.S]:            icons.BUG,
  [timelineEvents.SAVE]:         icons.APPROVE,
  [timelineEvents.SELECT]:       icons.BUG,
  [timelineEvents.SEND]:         icons.BUG,
  [timelineEvents.SHUFFLE]:      icons.RETWEET,
  [timelineEvents.START]:        icons.BUG,
  [timelineEvents.STOP]:         icons.BUG,
  [timelineEvents.SUBSTITUTE]:   icons.RETWEET,
  [timelineEvents.SUCCESS]:      icons.APPROVE,
  [timelineEvents.SWITCH]:       icons.BUG,
  [timelineEvents.UNAPPROVE]:    icons.CANCEL,
  [timelineEvents.UNINVALIDATE]: icons.APPROVE,
  [timelineEvents.UPDATE]:       icons.EDIT,
  [timelineEvents.UPLOAD]:       icons.UPLOAD,
  [timelineEvents.V]:            icons.BUG,
  [timelineEvents.VO]:           icons.BUG,
  [timelineEvents.WARNING]:      icons.WARNING,
};

timelineEvents.ALL = _.values(timelineEvents);
timelineBadgeColors.ALL = _.values(timelineBadgeColors);
timelineIcons.ALL = _.values(timelineIcons);

export { timelineEvents, timelineBadgeColors, timelineIcons };
