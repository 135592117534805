import _keys from 'lodash/keys';
import _map from 'lodash/map';

const REGEX_SPECIALS = '+*'; //list of characters that need to be escaped for RegExp use

/**
 * HTML entities to unescape
 */
const UNESCAPE_HTML = {
  '&amp;': '&',
  '&lt;':  '<',
  '&gt;':  '>',
  '&#39;': '\'',
  '&#34;': '"',
  '&#64;': '@',
  '&#96;': '`',
  '&#43;': '+',
  '&#61;': '=',
};
const unescapeHtmlRegExp = new RegExp(_keys(UNESCAPE_HTML).join('|'), 'g');

/**
 * HTML entities to escape
 */
const ESCAPE_HTML = {
  '&':  '&amp;',
  '<':  '&lt;',
  '>':  '&gt;',
  '\'': '&#39;',
  '"':  '&#34;',
  '@':  '&#64;',
  '`':  '&#96;',
  '+':  '&#43;',
  '=':  '&#61;',
};

//Gets the list of keys that need to be matched by RegExp.
//It also checks if any of the keys is a special RegExp character that needs to be escaped
const escapeHTMLKeys = _map(_keys(ESCAPE_HTML),  (k) => (REGEX_SPECIALS.indexOf(k) > -1) ? `\\${k}` : k);

const escapeHtmlRegExp = new RegExp(escapeHTMLKeys.join('|'), 'g');


/**
 * Unescapes HTML entities in the given string
 * @param {string} str - the HTML string to be unescaped
 * @returns {string} the unescaped string
 */
const unescapeHTML = (str) => str.replace(unescapeHtmlRegExp, (entity) => UNESCAPE_HTML[entity] || entity);

/**
 * Escapes HTML entities in the given string
 * @param {string} str - the HTML string to be escaped
 * @returns {string} the escaped string
 */
const escapeHTML = (str) => str.replace(escapeHtmlRegExp, (entity) => ESCAPE_HTML[entity] || entity);

export default { escapeHTML, unescapeHTML };
