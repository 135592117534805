import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { buttonStates } from 'enums';
import { Selectors, Actions } from './animating-button-duck';

class AnimatingButton extends React.Component {
  constructor() {
    super();
  }
  UNSAFE_componentWillMount() {
    this.props.registerButton(this.props.id, this.props.actions);
  }
  componentWillUnmount() {
    this.props.deregisterButton(this.props.id);
  }
  render() {
    logger.error('AnimatingButton only accepts a single child', React.Children.count(this.props.children) !== 1);
    return React.cloneElement(this.props.children, {
      buttonState: buttonState(this.props),
    });
  }
}

const actionShape = {
  start: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
  success: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
  failure: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
};
AnimatingButton.propTypes = {
  actions: PropTypes.oneOfType([PropTypes.shape(actionShape), PropTypes.arrayOf(PropTypes.shape(actionShape))])
    .isRequired,
};

function buttonState(props) {
  if (props.isAnimating) return buttonStates.LOADING;
  if (props.isSuccess) return buttonStates.SUCCESS;
  if (props.isFailure) return buttonStates.ERROR;
  return buttonStates.DEFAULT;
}

export default connect(
  () => {
    const id = _.uniqueId('animating-btn');
    const ButtonSelectors = Selectors.makeAnimatingButtonSelectorById(id);
    return state => ({
      isAnimating: ButtonSelectors.isAnimating(state),
      isSuccess: ButtonSelectors.isSuccess(state),
      isFailure: ButtonSelectors.isFailure(state),
      id,
    });
  },
  dispatch => ({
    registerButton(id, actions) {
      dispatch(Actions.registerButton(id, actions));
    },
    deregisterButton(id) {
      dispatch(Actions.deregisterButton(id));
    },
  })
)(AnimatingButton);
