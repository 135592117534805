import React from 'react';
import { Embed } from 'semantic-ui-react';

import { createComponent } from 'wayin-react';
import { getFacebookId } from 'helpers';

const FacebookVideo = createComponent({
  displayName: 'FacebookVideo',

  render({ src, id }) {
    if (!id) {
      id = getFacebookId(src);
    }

    return <Embed active autoplay={false} url={`//www.facebook.com/video/embed?video_id=${id}`} />;
  },
});

export default FacebookVideo;
