import React from 'react';
import { createComponent, PropTypes } from 'wayin-react';
import { toUIProps } from 'helpers';
import { animations } from 'enums';

import { Reveal as UIReveal } from 'semantic-ui-react';
import RevealContent from './reveal-content';

/*
With children:
<Reveal>
  <Reveal.Content whenIsActive>
    <div>I appear when isActive is false</div>
  </Reveal.Content>
  <Reveal.Content>
    <div>I appear when isActive is true</div>
  </Reveal.Content>
</Reveal>

With content
<Reveal isActive content={ content }>
*/

const UNSUPPORTED_PROPS = ['animated', 'as', 'className', 'content', 'disabled', 'instant'];
const ADDED_PROPS = ['animation', 'content'];
const MODIFIED_PROPS = [];
const propMap = {
  isActive: 'active',
};

const Reveal = createComponent({
  displayName: 'Reveal',
  propTypes:   {
    isActive:  PropTypes.bool,
    children:  PropTypes.any, // Reveal.Content
    animation: PropTypes.oneOf(animations.ALL),
    content:   PropTypes.any,
  },
  defaultProps: {
    isActive:  false,
    animation: 'slide down',
    children:  null,
    content:   null,
  },
  render(props) {
    const UIProps = toUIProps({ props, propMap, UNSUPPORTED_PROPS, ADDED_PROPS, MODIFIED_PROPS });

    // Semantic does not currently support all animations that we need through props - KMS - 11/4/16
    UIProps.className = props.animation;

    logger.warn(
      'Children cannot be defined with "content", please choose one or the other, in `Reveal`',
      !_.isEmpty(props.content) && !_.isEmpty(props.children)
    );

    if (!!props.content) {
      UIProps.children = (
        <RevealContent whenIsActive={true}>
          {props.content}
        </RevealContent>
      );
    }

    return <UIReveal {...UIProps} />;
  },
});

export default Reveal;
