import React from 'react';
import { positions } from 'enums';

function _createElement(Component, props) {
  return <Component {...props} />;
}

/**
 * Returns an object with a React component and position value given the
 * following params:
 *
 * @param Component [ComponentClass] type of new component class to instantiate
 * @param propData [String|Object|ComponentInstance] 
 *     String: prop value to assign
 *     Object: object containing position and/or content of prop to assign
 *     ComponentInstance: an instance of Component param already created
 * @param props [String|Object]
 *     String: prop key to which propData value is assigned
 *     Object: prop mapping of keys:values to assign - NEW ADDITION (DA 1/26)
 * @param defaultPosition One of [positions.LEFT,positions.RIGHT]
 *
 * @return [Object] 
 *   {
 *     element: React ComponentInstance
 *     position: One of [positions.LEFT,positions.RIGHT]
 *   }
 */
export default function extractElement(Component, propData, props, defaultPosition = positions.LEFT) {
  let position = defaultPosition;
  let element;
  
  if (!propData) {
    return undefined;
  }

  if (_.isString(propData) && !_.isEmpty(propData)) {
    if (_.isPlainObject(props)) {
      element = _createElement(Component, props);
    } else {
      element = _createElement(Component, { [props]: propData });
    }
  } else if (React.isValidElement(propData)) {
    element = React.cloneElement(propData, {key: props.key});
  } else {
    position = propData.position || position;
    if (_.isString(propData.content) && !_.isEmpty(propData.content)) {
      element = _createElement(Component, { [props]: propData.content });
    } else if (React.isValidElement(propData.content)) {
      element = propData.content;
    } else {
      return undefined;
    }
  }
  return {
    element,
    position
  }
}
