import React from 'react';
import cx from 'classnames';

import { createComponent, PropTypes } from 'wayin-react';
import { whitelistStyles } from 'components/core/hoc';

import { positions, sizes, font, shapes } from 'enums';

import Image from 'components/core/image';

const ContentItem = createComponent({
  displayName: 'ContentItem',
  propTypes: {
    image: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
    topline: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
    header: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
    byline: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
    meta: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
    extra: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
    actions: PropTypes.element,
    verticalAlign: PropTypes.oneOf(positions.verticalAlign.ALL),
    size: PropTypes.oneOf(_.without(sizes.ALL, sizes.X1)),
    font: PropTypes.shape({
      weight: PropTypes.oneOf(font.weights.ALL),
      family: PropTypes.oneOf(font.families.ALL),
      emphasis: PropTypes.oneOf(font.emphasis.ALL),
    }),
    imageStyles: PropTypes.shape({
      isBordered: PropTypes.bool,
      shape: PropTypes.oneOf(shapes.ALL),
      size: PropTypes.oneOf([...sizes.ALL, null]),
    }),
    id: PropTypes.any,
    isDivided: PropTypes.bool,
    value: PropTypes.any,
    onClick: PropTypes.func,
    isBordered: PropTypes.bool,
  },
  defaultProps: {
    image: undefined,
    topline: undefined,
    header: undefined,
    byline: undefined,
    meta: undefined,
    extra: undefined,
    verticalAlign: positions.verticalAlign.MIDDLE,
    size: sizes.M,
    font: defaultFontStyles,
    imageStyles: {
      isBordered: false,
      shape: shapes.ROUNDED,
      size: null,
    },
    value: null,
    onClick: undefined,
    id: undefined,
    isDivided: false,
    isBordered: false,
    actions: undefined,
  },
  contextTypes: {
    isInverted: PropTypes.bool,
  },
  handlers: {
    handleClick: p => e => {
      if (p.onClick) p.onClick(e, { value: p.value, id: p.id });
    },
  },
  render(p) {
    // account for shallow default props
    const _fontStyles = Object.assign({}, defaultFontStyles, p.font);

    const _mainCx = cx({
      'ck content-item': true,
      'content-item--divided': p.isDivided,
      'content-item--inverted': p.isInverted,
      'content-item--clickable': p.onClick,
      [`content-item--align-${p.verticalAlign}`]: p.verticalAlign,
      'content-item--bordered': p.isBordered,
      [`content-item--font-${_fontStyles.family}-${_fontStyles.weight}`]: true,
    });

    const _contentCx = cx({
      'content-item-content': true,
      [`content-item-content--size-${p.size}`]: p.size,
      [`content-item-content--emphasis-${_fontStyles.emphasis}`]: true,
    });

    return (
      <div className={_mainCx} style={p.style} onClick={p.handleClick}>
        <div className="content-item__content-item-inner">
          <Choose>
            <When condition={p.image === null}>{null}</When>
            <When condition={typeof p.image !== 'string'}>
              <div className="content-item__image">{p.image}</div>
            </When>
            <Otherwise>
              <div className="content-item__image">
                <Image
                  src={p.image}
                  isBordered={p.imageStyles.isBordered}
                  size={p.imageStyles.size}
                  isCircular={p.imageStyles.shape === shapes.CIRCULAR}
                  isRounded={p.imageStyles.shape === shapes.ROUNDED}
                />
              </div>
            </Otherwise>
          </Choose>
          <If condition={p.isDivided}>
            <div className="content-item__divider" />
          </If>
          <div className={cx({ 'content-item__right': p.image !== null })}>
            <div className={_contentCx}>
              <If condition={p.topline}>
                <div className="content-item-content__topline">{p.topline}</div>
              </If>
              <If condition={p.header}>
                <div className="content-item-content__header">{p.header}</div>
              </If>
              <If condition={p.byline}>
                <div className="content-item-content__byline">{p.byline}</div>
              </If>
              <If condition={p.meta}>
                <div className="content-item-content__meta">{p.meta}</div>
              </If>
              <If condition={p.extra}>
                <div className="content-item-content__extra">{p.extra}</div>
              </If>
              <If condition={p.actions}>
                <div className="content-item-actions">{p.actions}</div>
              </If>
            </div>
          </div>
        </div>
      </div>
    );
  },
});

const defaultFontStyles = {
  weight: font.weights.REGULAR,
  family: font.families.CUSTOM,
  emphasis: font.emphasis.NORMAL,
};

export const PrivateContentItem = ContentItem;
export default whitelistStyles()(ContentItem);
