import _map from 'lodash/map';
import _fromPairs from 'lodash/fromPairs';
import _pickBy from 'lodash/pickBy';

const serialize = (items) => _map(items, (value, key) => `${key}=${value}`).join('&');

const deserialize = (string) =>
  _fromPairs(
    decodeURI(string)
      .split('&')
      .map((s) => s.split('='))
  );

const clean = (items) => _pickBy(items, (i) => i !== undefined && i !== null);

export default { serialize, deserialize, clean };
