import React from 'react';
import { createComponent } from 'wayin-react';
import { wrapDisplayName } from 'recompose';

const SUPPORTED_STYLES = [
  'padding',
  'paddingTop',
  'paddingRight',
  'paddingBottom',
  'paddingLeft',
  'margin',
  'marginTop',
  'marginRight',
  'marginBottom',
  'marginLeft',
  'fontSize',
  // needed for <Hoverable>
  'transition',
  'WebkitTransition',
  'MozTransition',
  'OTransition',
  'transform',
  'WebkitTransform',
  'MozTransform',
  'msTransform',
  'OTransform',
];

const whitelistStyles = (customSupported = []) => Component =>
  createComponent({
    displayName: wrapDisplayName(Component, 'whitelistStyles'),
    render(props) {
      const passedStyles = _.reduce(
        props.style,
        (acc, value, style) => {
          if (!_.includes(SUPPORTED_STYLES.concat(customSupported), style) && process.env.__DEV__) {
            logger.warn(`Unsupported style override supplied to ${Component.displayName}: ${style}`);
          } else {
            acc[style] = value;
          }
          return acc;
        },
        {}
      );
      const updatedProps = Object.assign({}, props, {
        style: passedStyles,
      });
      return <Component {...updatedProps} />;
    },
  });

export default whitelistStyles;
