import React from 'react';
import { createComponent, PropTypes, Utils } from 'wayin-react';
import classnames from 'classnames';
import { widths, colors, textAlignments, positions, directions } from 'enums';
import { toUIProps } from 'helpers';
import { removeProps, whitelistStyles } from 'components/core/hoc';
import { compose } from 'recompose';
import { GridRow as UIGridRow } from 'semantic-ui-react';
import LayoutColumn, { PrivateLayoutColumn } from './layout-column';

const UNSUPPORTED_PROPS = ['as', 'centered', 'divided', 'className', 'reversed', 'stretched', 'only'];

const ADDED_PROPS = ['borders', 'isCentered', 'isInverted', 'columnStyles', '_parentColumnStyles', '_parentRowStyles'];

const MODIFIED_PROPS = ['color'];

const propMap = {};

const LayoutRow = createComponent({
  displayName: 'LayoutRow',
  propTypes:   {
    children:   PropTypes.node,
    borders:    PropTypes.oneOf([directions.H]),
    isCentered: PropTypes.bool,
    columns:    PropTypes.oneOf([...widths.ALL, 'equal']),

    columnStyles: PropTypes.shape({
      backgroundColor:   PropTypes.oneOf(colors.CORE),
      float:             PropTypes.oneOf(positions.LR),
      textAlign:         PropTypes.oneOf(textAlignments.ALL),
      verticalAlign:     PropTypes.oneOf(positions.alignColumns.ALL),
      isContentCentered: PropTypes.bool,
    }),

    _parentColumnStyles: PropTypes.shape({
      backgroundColor:   PropTypes.oneOf(colors.CORE),
      float:             PropTypes.oneOf(positions.LR),
      textAlign:         PropTypes.oneOf(textAlignments.ALL),
      verticalAlign:     PropTypes.oneOf(positions.alignColumns.ALL),
      isContentCentered: PropTypes.bool,
    }),

    _parentRowStyles: PropTypes.shape({
      isCentered: PropTypes.bool,
    }),
  },
  defaultProps: {
    children:            undefined,
    columns:             null,
    borders:             null,
    isCentered:          undefined,
    columnStyles:        {},
    _parentColumnStyles: {},
    _parentRowStyles:    {},
  },
  contextTypes: {
    isInverted: PropTypes.bool,
  },
  render(props) {
    let UIProps = toUIProps({ props, propMap, ADDED_PROPS, UNSUPPORTED_PROPS, MODIFIED_PROPS });
    delete UIProps.children;

    UIProps = Object.assign(UIProps, getRowStyles(props._parentRowStyles, props));

    const _parentColumnStyles = getColumnStyles(props._parentColumnStyles, props.columnStyles);
    const propChildren = React.Children.toArray(props.children);

    let colProps;
    const children = _.map(propChildren, (child, index) => {
      if (Utils.isType([LayoutColumn, PrivateLayoutColumn], child)) {
        colProps = Object.assign({}, child.props, { _parentColumnStyles, key: index });
        return <PrivateLayoutColumn {...colProps} />;
      } else {
        const colProps = { _parentColumnStyles, key: index };
        return (
          <PrivateLayoutColumn {...colProps}>
            {child}
          </PrivateLayoutColumn>
        );
      }
    });

    const classNames = classnames(_parentColumnStyles.backgroundColor, {
      inverted:               props.isInverted,
      'horizontally divided': props.borders === directions.H,
    });
    return (
      <UIGridRow {...UIProps} className={classNames}>
        {children}
      </UIGridRow>
    );
  },
});

const defaultRowStyles = {
  isCentered: false,
};
function getRowStyles(parent, overrides) {
  const rowStyles = Object.assign({}, defaultRowStyles, parent);
  return {
    centered: getDefaultOrOverrideVal(rowStyles.isCentered, overrides.isCentered),
  };
}

function getColumnStyles(parent, overrides) {
  return Object.assign({}, parent, overrides);
}

function getDefaultOrOverrideVal(defaultVal, overrideVal) {
  return overrideVal === undefined ? defaultVal : overrideVal;
}

export const PrivateLayoutRow = whitelistStyles()(LayoutRow);
export default removeProps(['_parentColumnStyles', '_parentRowStyles'])(PrivateLayoutRow);
