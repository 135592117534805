import React from 'react';
import { createComponent } from 'wayin-react';
import { wrapDisplayName } from 'recompose';

const removeProps = removeKeys => Component =>
  createComponent({
    displayName: wrapDisplayName(Component, 'removeProps'),
    render(props) {
      if (process.env.__DEV__) {
        const propKeys = _.keys(props);
        _.forEach(removeKeys, removeKey => {
          logger.warn(
            `Invalid prop supplied to ${Component.displayName}: ${removeKey}`,
            _.includes(propKeys, removeKey)
          );
        });
      }

      const passedProps = _.omit(props, removeKeys);
      return <Component {...passedProps} />;
    },
  });

export default removeProps;
