import React from 'react';
import { createComponent, PropTypes } from 'wayin-react';
import cx from 'classnames';

const WizardCrumb = createComponent({
  displayName:  'WizardCrumb',
  defaultProps: {
    index:  undefined,
    active: false,
    hidden: false,
  },
  propTypes: {
    index:  PropTypes.number,
    active: PropTypes.bool,
    hidden: PropTypes.bool,
  },
  contextTypes: {
    isInverted: PropTypes.bool,
  },
  render(p) {
    const _cls = cx('wizard-ui-crumb', {
      'wizard-ui-crumb--hidden':   p.hidden,
      'wizard-ui-crumb--active':   p.active,
      'wizard-ui-crumb--inverted': p.isInverted,
    });
    return <div className={_cls}>{p.children}</div>;
  },
});

export default WizardCrumb;
