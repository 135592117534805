import icons from './icons';


const socialIcons = {
  BAZAAR_VOICE: icons.BAZAARVOICE,
  BLOG:         icons.FEED,
  FACEBOOK:     icons.FACEBOOK,
  FLICKR:       icons.FLICKR,
  INSTAGRAM:    icons.INSTAGRAM,
  LITHIUM:      icons.LITHIUM,
  NEWSCRED:     icons.NEWSCRED,
  TWITTER:      icons.TWITTER,
  UGC:          icons.ADVOCACY,
  VIMEO:        icons.VIMEO,
  VINE:         icons.VINE,
  VK:           icons.VK,
  YOUTUBE:      icons.YOUTUBE,
};

const social = {
  ALL_NETWORKS: {
    value:   undefined,
    icon:    undefined,
    display: 'All networks',
  },
  BAZAAR_VOICE: {
    value:   'external.service.bazaarvoice',
    icon:    socialIcons.BAZAARVOICE,
    display: 'Bazaar Voice',
  },
  BLOG: {
    value:   'external.service.blog',
    icon:    socialIcons.FEED,
    display: 'Blog',
  },
  FACEBOOK: {
    value:   'external.service.facebook',
    icon:    socialIcons.FACEBOOK,
    display: 'Facebook',
  },
  FLICKR: {
    value:   'external.service.flickr',
    icon:    socialIcons.FLICKR,
    display: 'Flickr',
  },
  INSTAGRAM: {
    value:   'external.service.instagram',
    icon:    socialIcons.INSTAGRAM,
    display: 'Instagram',
  },
  LITHIUM: {
    value:   'external.service.lithium',
    icon:    socialIcons.LITHIUM,
    display: 'Lithium',
  },
  NEWSCRED: {
    value:   'external.service.newscred',
    icon:    socialIcons.NEWSCRED,
    display: 'NewsCred',
  },
  TWITTER: {
    value:   'external.service.twitter',
    icon:    socialIcons.TWITTER,
    display: 'Twitter',
  },
  UGC: {
    value:   'external.service.ugc',
    icon:    socialIcons.ADVOCACY,
    display: 'User Generated Content',
  },
  VIMEO: {
    value:   'external.service.vimeo',
    icon:    socialIcons.VIMEO,
    display: 'Vimeo',
  },
  VINE: {
    value:   'external.service.vine',
    icon:    socialIcons.VINE,
    display: 'Vine',
  },
  VK: {
    value:   'external.service.vk',
    icon:    socialIcons.VK,
    display: 'VK',
  },
  YOUTUBE: {
    value:   'external.service.youtube',
    icon:    socialIcons.YOUTUBE,
    display: 'YouTube',
  },
};

socialIcons.ALL = _.values(socialIcons);

export {
  social,
  socialIcons
};
