import React from 'react';
import _ from 'lodash';
import { compose } from 'recompose';
import cx from 'classnames';
import plyr from 'plyr';
import Hls from 'hls.js';

import { ConfigService } from 'services';
import { createComponent, PropTypes } from 'wayin-react';
import { whitelistStyles } from 'components/core/hoc';
import Image from 'components/core/image';
import { getMuxId } from 'helpers';

const status = {
  FAILED: 'failed',
  PENDING: 'pending',
  READY: 'ready',
};

const error = {
  NETWORK_ERROR: 'fatal network error encountered, trying to recover',
  MEDIA_ERROR: 'fatal media error encountered, try to recover',
};

const MuxVideo = createComponent({
  displayName: 'MuxVideo',
  propTypes: {
    src: PropTypes.string.isRequired,
    poster: PropTypes.string,
    status: PropTypes.oneOf(_.values(status)),
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  },
  defaultProps: {
    poster: undefined,
    status: status.READY,
    width: undefined,
  },
  state: {
    isMediaReady: {
      value: false,
      updater: 'setIsMediaReady',
    },
    _status: {
      value: p => p.status,
      updater: 'setStatus',
    },
    player: {
      value: undefined,
      updater: 'setPlayer',
    },
  },
  handlers: {
    updatePoster: props => _poster => {
      if (props.player && props.player.length > 0) {
        for (const playerEl of props.player) {
          playerEl.poster = _poster;
        }
      }
    },
    destroyPlayer: props => () => {
      // player may not exist if the video status !== ready
      if (props.player && props.player.length > 0) {
        for (const playerEl of props.player) {
          playerEl.destroy();
        }
        props.setPlayer(undefined);
        if (window.hls) window.hls.destroy();
      }
    },
    createPlayer: props => () => {
      const { refs, src, setIsMediaReady, setStatus, setPlayer, _status } = props;

      const _playerOptions = {
        seekTime: 5,
        controls: [
          // 'airplay', // Airplay (currently Safari only)
          // 'captions', // Toggle captions
          'current-time', // The current time of playback
          // 'download', // Show a download button with a link to either the current source or a custom URL you specify in your options
          // 'duration', // The full duration of the media
          // 'fast-forward', // Fast forward by the seek time (default 10 seconds)
          'fullscreen', // Toggle fullscreen
          'mute', // Toggle mute
          // 'pip', // Picture-in-picture (currently Safari only)
          'play', // Play/pause playback
          'play-large', // The large play button in the center
          'progress', // The progress bar and scrubber for playback and buffering
          // 'restart', // Restart playback
          // 'rewind', // Rewind by the seek time (default 10 seconds)
          // 'settings', // Settings menu
          'volume', // Volume control
        ],
      };

      setPlayer(plyr.setup(`#plyr-player-${getPlayerId(props)}`, _playerOptions));

      if (_status === status.READY) {
        if (!Hls.isSupported()) {
          refs.videoNode.src = src;
          setIsMediaReady(true);
        } else {
          // For more Hls.js options, see https://github.com/dailymotion/hls.js
          const hls = new Hls({
            autoStartLoad: true,
          });
          hls.loadSource(src);
          hls.attachMedia(refs.videoNode);
          window.hls = hls;

          hls.on(Hls.Events.MANIFEST_LOADED, function() {
            setIsMediaReady(true);
          });

          hls.on(Hls.Events.MEDIA_ATTACHED, function() {
            hls.loadSource(src);
          });

          hls.on(Hls.Events.ERROR, function(event, data) {
            const errorType = data.type;
            const errorFatal = data.fatal;

            if (errorFatal) {
              setIsMediaReady(false);
              setStatus(status.FAILED);

              switch (errorType) {
                case Hls.ErrorTypes.NETWORK_ERROR:
                  logger.warn(error.NETWORK_ERROR, true);
                  hls.startLoad();
                  break;
                case Hls.ErrorTypes.MEDIA_ERROR:
                  logger.warn(error.MEDIA_ERROR, true);
                  hls.recoverMediaError();
                  break;
                default:
                  // cannot recover
                  hls.destroy();
                  break;
              }
            }
          });
        }
      }
    },
  },
  lifecycles: {
    UNSAFE_componentWillUpdate(nextProps) {
      // this will ensure the poster gets set when the poster prop changes or when the player has fully initialised
      if (!_.isEqual(nextProps.poster, this.props.poster) || !_.isEqual(nextProps.player, this.props.player)) {
        this.props.updatePoster(nextProps.poster);
      }
    },
    componentDidMount() {
      this.props.createPlayer();
      this.props.updatePoster(this.props.poster);
    },
    componentWillUnmount() {
      this.props.destroyPlayer();
    },
  },
  refs: 'videoNode',
  render(props) {
    const _id = getPlayerId(props);

    const className = cx('ck', 'mux-video', props._status);

    const _style = {
      ...(props.width && { width: props.width }),
    };

    // when a video has been deleted, our asset ref will still look valid and have a status of "ready".
    // we therefore have to render the video component into the page hidden
    // and update it's visibility once we can be sure the media is ready to use.
    const videoClassName = cx('video__wrapper', {
      hidden: !props.isMediaReady,
    });

    return (
      <div className={className}>
        <Choose>
          <When condition={props._status !== status.READY}>
            <Image
              size="fluid"
              src={`${ConfigService.values().assetUrls.staticResourceURL}/static/img/misc/video-${props._status}.svg`}
            />
          </When>
        </Choose>
        <div className={videoClassName} style={_style}>
          <video ref={props.setVideoNode} id={`plyr-player-${_id}`} src={props.src} />
        </div>
      </div>
    );
  },
});

const getPlayerId = props => props.id || getMuxId(props.src);

export default compose(whitelistStyles())(MuxVideo);
