import React from 'react';
import { createComponent, PropTypes } from 'wayin-react';

import { isValidURL } from 'helpers';

import { positions, sizes, widths, textAlignments, icons } from 'enums';

import { UploaderMessages } from './file-utils';

import Button from 'components/core/button';
import Icon from 'components/core/icon';
import Layout from 'components/core/layout';
import TextArea from 'components/core/textarea';
import Video from 'components/core/video';

/**
 * view for entering video url
 */
const VideoURL = createComponent({
  displayName: 'VideoURL',

  propTypes: {
    videoURL:             PropTypes.string,
    onChange:             PropTypes.func.isRequired,
    onEnter:              PropTypes.func.isRequired,
    videoPlaceholderText: PropTypes.string,
  },

  defaultProps: {
    videoURL:             '',
    videoPlaceholderText: '',
  },

  render(props) {
    const { videoURL, onChange, onEnter, videoPlaceholderText } = props,
      isValidVideo = isValidURL(videoURL);
    //video url editor and preview
    return (
      <div className="upload-view file-select-view">
        <div className="upload-view__inner">
          <Layout
            isFluidHeight
            columns={widths.ONE}
            style={{ margin: 0 }}
            columnStyles={{ textAlign: textAlignments.CENTER, verticalAlign: positions.verticalAlign.MIDDLE }}
          >
            {/* video url text area and accept button */}
            <Layout.Row columns={widths.TWO}>
              <Layout.Column columnWidth={widths.TWELVE} style={{ paddingRight: 0 }}>
                <TextArea id="videoUrl" value={videoURL} onChange={onChange} placeholder={videoPlaceholderText} />
              </Layout.Column>
              <Layout.Column columnWidth={widths.FOUR}>
                <Button.Primary text={UploaderMessages.ACTION_ADD_VIDEO_URL} onClick={onEnter} />
              </Layout.Column>
            </Layout.Row>
            {/* video player OR video icon */}
            <Layout.Row columnStyles={{ verticalAlign: positions.alignColumns.MIDDLE }}>
              <Layout.Column style={{ paddingTop: 0 }}>
                <div className="video-player-wrapper">
                  <Layout
                    isFluidHeight
                    columns={widths.ONE}
                    style={{ margin: 0, padding: 0 }}
                    rowStyles={{ margin: 0, padding: 0 }}
                    columnStyles={{ textAlign: textAlignments.CENTER, verticalAlign: positions.verticalAlign.MIDDLE }}
                  >
                    <Layout.Column style={{ margin: 0, padding: 0 }}>
                      <Choose>
                        {/* video preview */}
                        <When condition={isValidVideo}>
                          <Video src={videoURL} width="480px" />
                        </When>
                        {/* video icon for invalid url */}
                        <When condition={!isValidVideo}>
                          <div className="video-url-invalid">
                            <Icon name={icons.VIDEO} size={sizes.X3} />
                          </div>
                        </When>
                      </Choose>
                    </Layout.Column>
                  </Layout>
                </div>
              </Layout.Column>
            </Layout.Row>
          </Layout>
        </div>
      </div>
    );
  },
});

export default VideoURL;
