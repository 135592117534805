import React from 'react';
import { createComponent, PropTypes } from 'wayin-react';
import classnames from 'classnames';
import { extractElement } from 'helpers';
import { positions, colors, icons } from 'enums';
import { whitelistStyles } from 'components/core/hoc';

import { PrivateMenuItem } from 'components/core/menu/menu-item';
import Icon from 'components/core/icon';

// <PopupMenu.Item
//   type='cta'
//   text='Experience >'
//   byline='Create a new'
//   detail='detail text'
//   icon='browser'
//   color={colors.SUPER}
//   onClick={myHandler}
// />

const popupMenuItemTypes = { CTA: 'cta', HEADER: 'header', NONE: 'none' };
popupMenuItemTypes.HEADER_TYPES = [popupMenuItemTypes.CTA, popupMenuItemTypes.HEADER];
popupMenuItemTypes.ALL = _.values(popupMenuItemTypes);

const PopupMenuItem = createComponent({
  displayName: 'PopupMenuItem',
  propTypes:   {
    type:    PropTypes.oneOf(popupMenuItemTypes.ALL),
    icon:    PropTypes.oneOfType([PropTypes.oneOf(icons.ALL), PropTypes.element]),
    text:    PropTypes.string,
    byline:  PropTypes.string,
    detail:  PropTypes.string,
    color:   PropTypes.oneOf(colors.CORE),
    onClick: PropTypes.func,
  },
  defaultProps: {
    type:    popupMenuItemTypes.NONE,
    icon:    null,
    text:    null,
    byline:  null,
    detail:  null,
    color:   undefined,
    onClick: null,
  },
  contextTypes: {
    isInverted: PropTypes.bool,
  },

  render(p) {
    const icon = extractElement(Icon, p.icon, 'name');

    logger.warn(
      'PopupMenuItem: Must contain at least one content property, icon, text, byline, or detail.',
      !p.icon && !p.text && !p.detail && !p.byline
    );

    let color = p.color;
    if (itemIsHeader(p.type) && !p.color) color = colors.ACCENT;
    return (
      <PrivateMenuItem
        color={color}
        isHeader={itemIsHeader(p.type)}
        onClick={p.onClick}
        value={p.value}
        style={p.style}
      >
        <div>
          <If condition={!!icon}>{icon.element}</If>
          <Choose>
            <When condition={itemIsHeader(p.type)}>
              <div className={classnames(`ck-popup-menu-${p.type}`)}>

                <If condition={p.type === popupMenuItemTypes.CTA && !!p.byline}>
                  <div className="ck-popup-menu-byline">{p.byline}</div>
                </If>

                <div className="ck-popup-menu-text">{p.text}</div>

                <If condition={p.type === popupMenuItemTypes.HEADER && !!p.byline}>
                  <div className="ck-popup-menu-byline">{p.byline}</div>
                </If>
                <If condition={p.type === popupMenuItemTypes.HEADER && !!p.detail}>
                  <div className="ck-popup-menu-detail">{p.detail}</div>
                </If>
              </div>
            </When>
            <Otherwise>{p.text}</Otherwise>
          </Choose>
        </div>
      </PrivateMenuItem>
    );
  },
});

function itemIsHeader(type) {
  return _.includes(popupMenuItemTypes.HEADER_TYPES, type);
}

export default whitelistStyles()(PopupMenuItem);
