let _documentTypes = {
  IMAGE:      'image',
  VIDEO:      'video',
  AUDIO:      'audio',
  RIA:        'ria',
  EXE:        'exe',
  HTML:       'html',
  CSV:        'csv',
  TXT:        'txt',
  EXCEL:      'excel',
  WORD:       'word',
  POWERPOINT: 'powerpoint',
  PDF:        'pdf',
  FILE:       'file',
};

_documentTypes.ALL = _.values(_documentTypes);
const documentTypes = _documentTypes;

export default documentTypes;
