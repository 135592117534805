import React from 'react';
import { Embed } from 'semantic-ui-react';

import { createComponent } from 'wayin-react';
import { getVineId } from 'helpers';

const VineVideo = createComponent({
  displayName: 'VineVideo',

  render({ src, id }) {
    if (!id) {
      id = getVineId(src);
    }

    return <Embed active url={`//vine.co/v/${id}/embed/simple`} />;
  },
});

export default VineVideo;
