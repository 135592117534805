import Immutable from 'seamless-immutable';
import _ from 'lodash';
import { createAction, createReducer } from 'redux-act';

const NAMESPACE = 'CK => animating button =>';

const registerButton = createAction(`${NAMESPACE} register button`, (id, actions) => ({ id, actions }));
const deregisterButton = createAction(`${NAMESPACE} deregister button`, id => ({ id }));
const startAnimation = createAction(`${NAMESPACE} start animation`, id => ({ id }));
const finishAnimation = createAction(`${NAMESPACE}  finish animation`, id => ({ id }));
const success = createAction(`${NAMESPACE} success`, id => ({ id }));
const failure = createAction(`${NAMESPACE} failure`, id => ({ id }));

const initialState = Immutable({ byId: {} });

export const reducer = createReducer(on => {
  on(registerButton, (state, { id }) =>
    state.setIn(['byId', id], { isAnimating: false, isSuccess: false, isFailure: false })
  );
  on(deregisterButton, (state, { id }) => state.set('byId', state.byId.without(id)));
  on(startAnimation, (state, { id }) =>
    state.setIn(['byId', id], { isAnimating: true, isSuccess: false, isFailure: false })
  );
  on(success, (state, { id }) => state.setIn(['byId', id], { isAnimating: false, isSuccess: true, isFailure: false }));
  on(failure, (state, { id }) => state.setIn(['byId', id], { isAnimating: false, isSuccess: false, isFailure: true }));
  on(finishAnimation, (state, { id }) =>
    state.setIn(['byId', id], { isAnimating: false, isSuccess: false, isFailure: false })
  );
}, initialState);

export const Actions = {
  registerButton,
  deregisterButton,
  startAnimation,
  finishAnimation,
  success,
  failure,
};

const _scopeState = state => state.ck.animatingButton;
const makeAnimatingButtonSelectorById = id => ({
  isAnimating: state => _.get(_scopeState(state), ['byId', id, 'isAnimating'], false),
  isSuccess:   state => _.get(_scopeState(state), ['byId', id, 'isSuccess'], false),
  isFailure:   state => _.get(_scopeState(state), ['byId', id, 'isFailure'], false),
});

export const Selectors = {
  makeAnimatingButtonSelectorById,
};
