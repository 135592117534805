import React from 'react';
import { createComponent, PropTypes } from 'wayin-react';
import { sizes, icons, positions, font } from 'enums';

import Button from 'components/core/button';
import Reveal from 'components/core/reveal';
import Segment from 'components/core/segment';
//TODO update icon to icons.MINUS when available

const PanelExpandableContent = createComponent({
  displayName: 'PanelExpandableContent',
  propTypes:   {
    expandedTrigger:         PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
    collapsedTrigger:        PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
    expansionPosition:       PropTypes.oneOf([positions.TOP, positions.BOTTOM]),
    isExpanded:              PropTypes.bool,
    children:                PropTypes.node.isRequired,
    shouldTransitionOnMount: PropTypes.bool,
  },
  defaultProps: {
    expandedTrigger:         'Advanced Options',
    collapsedTrigger:        'Advanced Options',
    expansionPosition:       positions.TOP,
    isExpanded:              false,
    shouldTransitionOnMount: false,
    isDisabled:              false,
  },
  lifecycles: {
    UNSAFE_componentWillReceiveProps(nextProps) {
      if (nextProps.isExpanded !== this.props.isExpanded) {
        this.props.setIsExpanded(nextProps.isExpanded);
      }
    },
  },
  state: {
    _isExpanded: {
      value: p => p.isExpanded,
      updater(setIsExpanded, p) {
        return {
          toggle: () => {if(!p.isDisabled)setIsExpanded(!p._isExpanded)},
          setIsExpanded,
        };
      },
    },
  },

  render(props) {
    logger.deprecation(
      `Prop "expandedHeight" has been deprecated and it will not change the height of the component.`,
      !!props.expandedHeight
    );
    return (
      <Segment>
        <If condition={props.expansionPosition === positions.TOP}>
          <Reveal isActive={props._isExpanded}>
            <Reveal.Content isActive={props._isExpanded} shouldCalcHeight whenIsActive={true}>
              {props.children}
            </Reveal.Content>
          </Reveal>
        </If>
        <Choose>
          <When condition={props._isExpanded}>
            <Choose>
              <When condition={typeof props.expandedTrigger !== 'string'}>
                <span onClick={props.toggle}>
                  {props.expandedTrigger}
                </span>
              </When>
              <Otherwise>
                <Button.Secondary
                  isDisabled={props.isDisabled}
                  size={sizes.MEDIUM}
                  text={props.expandedTrigger}
                  icon={{ content: icons.MINUS, position: positions.LEFT }}
                  onClick={props.toggle}
                  font={{
                    emphasis: font.emphasis.NORMAL,
                  }}
                  isCompact
                />
              </Otherwise>
            </Choose>
          </When>
          <When condition={!props._isExpanded}>
            <Choose>
              <When condition={typeof props.collapsedTrigger !== 'string'}>
                <span onClick={props.toggle}>
                  {props.collapsedTrigger}
                </span>
              </When>
              <Otherwise>
                <Button.Secondary
                  isDisabled={props.isDisabled}
                  size={sizes.MEDIUM}
                  text={props.collapsedTrigger}
                  icon={{ content: icons.PLUS, position: positions.LEFT }}
                  onClick={props.toggle}
                  font={{
                    emphasis: font.emphasis.NORMAL,
                  }}
                  isCompact
                />
              </Otherwise>
            </Choose>
          </When>
        </Choose>
        <If condition={props.expansionPosition === positions.BOTTOM}>
          <Reveal isActive={props._isExpanded}>
            <Reveal.Content
              isActive={props._isExpanded}
              shouldCalcHeight
              whenIsActive={true}
              shouldTransitionOnMount={props.shouldTransitionOnMount}
            >
              {props.children}
            </Reveal.Content>
          </Reveal>
        </If>
      </Segment>
    );
  },
});

export default PanelExpandableContent;
