import React from 'react';
import { compose } from 'recompose';
import moment from 'moment-timezone';
import classnames from 'classnames';
import _noop from 'lodash/noop';

import { DateTimePicker as RWDateTimePicker } from 'react-widgets/lib';

import { createComponent, PropTypes } from 'wayin-react';
import { colors, font, icons, positions, sizes } from 'enums';
import { extractElement, toUIProps } from 'helpers';
import evalPredicate from '../../../util/eval-predicate';

import { removeProps, whitelistStyles } from 'components/core/hoc';
import Animate from 'components/abstractions/animate';
import Button from 'components/core/button';
import ErrorIcon from 'components/abstractions/error-icon';
import Label from 'components/core/label';
import Tooltip from 'components/core/tooltip';

//dst constants
const DST_TOOLTIP =
  'The date you have selected is in a different Daylight Savings zone. The date/time has been automatically adjusted for you.';
const DST_LABEL = 'Read about the impact of daylight savings.';
const STRIPPED_FORMAT = 'YYYY-MM-DD HH:mm:ss';

// These are all props we DON'T want to pass to the internal DateTimePicker component.
const ADDED_PROPS = [
  'dstStatus',
  'errorMessage',
  'errorMessagePosition',
  'handleChange',
  'handleClear',
  'handleSelect',
  'hasError',
  'isDisabled',
  'isFluid',
  'isInverted',
  'label',
  'setDstStatus',
  'setTimeZoneAbbr',
  'setWarningToClose',
  'showClearIcon',
  'showDSTInfo',
  'submitFormat',
  'style',
  'timeZone',
  'timeZoneAbbr',
];


const MODIFIED_PROPS = [];
const UNSUPPORTED_PROPS = [];
const propMap = {
};

const DateTimePicker = createComponent({
  displayName: 'DateTimePicker',
  propTypes: {
    date: PropTypes.bool,
    errorMessage: PropTypes.string,
    errorMessagePosition: PropTypes.oneOf(positions.ALL),
    hasError: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    isDisabled: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
    isFluid: PropTypes.bool,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    max: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
    min: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
    onChange: PropTypes.func,
    onSelect: PropTypes.func,
    showClearIcon: PropTypes.bool,
    showDSTInfo: PropTypes.bool,
    softMax: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
    step: PropTypes.number,
    submitFormat: PropTypes.string,
    time: PropTypes.bool,
    timeZone: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
  },
  defaultProps: {
    date: true,
    errorMessage: null,
    errorMessagePosition: positions.TOP_LEFT,
    hasError: false,
    id: undefined,
    isDisabled: false,
    isFluid: true,
    label: null,
    max: null,
    min: null,
    onChange: _noop,
    onSelect: _noop,
    showClearIcon: false,
    showDSTInfo: true,
    softMax: null,
    step: 15,
    submitFormat: 'YYYY-MM-DD[T]HH:mm:ss[Z]',
    time: true,
    timeZone: 'GMT',
    value: null,
  },
  contextTypes: {
    isInverted: PropTypes.bool,
  },

  state: {
    // Set the timezone abbreviation text
    timeZoneAbbr: {
      value: (p) => {
        return getTimezoneAbbr({ dateTime: p.value, timeZone: p.timeZone });
      },
      updater: (updater) => ({
        // update the timezone abbreviation in the datetime input
        // will mean if a date changes daylight savings from the current localised date, this will show the change
        setTimeZoneAbbr: ({ dateTime, timeZone }) => {
          updater(getTimezoneAbbr({ dateTime, timeZone }));
        },
      }),
    },
    // Set the DST status text visibility
    dstStatus: {
      value: false,
      updater: (updater, p) => ({
        // define whether the chosen date time is in the same DST period as the current user date time based on their timeZone
        setDstStatus: ({ dateTime, timeZone }) => {
          if (!!dateTime && !!timeZone) {
            // check if the current date for the user timeZone is in DST

            // note that here we need a slightly different approach to local machine time

            const _strippedLocalMachineDate = moment(new Date()).format(STRIPPED_FORMAT);
            const _adjustedNowDateTime = moment.tz(_strippedLocalMachineDate, timeZone);

            const _strippedSelectedDateDate = moment(dateTime).format(STRIPPED_FORMAT);
            const _adjustedSelectedDateTime = moment.tz(_strippedSelectedDateDate, timeZone);

            // now we can compare the DST status of the adjusted dates
            updater(_adjustedNowDateTime.isDST() !== _adjustedSelectedDateTime.isDST());
          } else updater(false);
        },
      }),
    },
  },

  handlers: {
    // note that the date param here is a date object representing the visible value of the date time picker
    // we now need to convert to UTC/GMT to have a value we can persist to the DB
    // we also overlay that value with the submitFormat
    handleChange: (p) => (date) => {
      // update the DSP status text and timeZone abbreviation based on the changed date value
      // useful when change crosses a DST zone
      p.setDstStatus({ dateTime: date, timeZone: p.timeZone });
      p.setTimeZoneAbbr({ dateTime: date, timeZone: p.timeZone });

      p.onChange(
        {},
        { value: getSubmitValue({ dateTime: date, timeZone: p.timeZone, submitFormat: p.submitFormat }), id: p.id }
      );
    },
    handleSelect: (p) => (date) => {
      p.onSelect(
        {},
        { value: getSubmitValue({ dateTime: date, timeZone: p.timeZone, submitFormat: p.submitFormat }), id: p.id }
      );
    },
    handleClear: (p) => (e) => {
      // update the DSP status text and timeZone abbreviation based on the changed date value
      // useful when change crosses a DST zone
      p.setDstStatus({ dateTime: '', timeZone: p.timeZone });
      p.onChange(e, { value: '', id: p.id });
    },
  },

  lifecycles: {
    componentDidMount() {
      // check the daylight saving status on mount
      this.props.setDstStatus({ dateTime: this.props.value, timeZone: this.props.timeZone });
      this.props.setTimeZoneAbbr({ dateTime: this.props.value, timeZone: this.props.timeZone });
    },
    UNSAFE_componentWillReceiveProps(nextProps) {
      // this shouldn't happen but check if the timezone has changed
      // this mainly helps with Storybook knobs being able to change timezone on the fly
      if (this.props.timeZone !== nextProps.timeZone) {
        this.props.setDstStatus({ dateTime: this.props.value, timeZone: nextProps.timeZone });
        this.props.setTimeZoneAbbr({ dateTime: this.props.value, timeZone: nextProps.timeZone });
      }
    },
  },

  render(props) {
    const RWProps = toUIProps({
      props,
      propMap,
      MODIFIED_PROPS,
      UNSUPPORTED_PROPS,
      ADDED_PROPS,
    });

    const dateTimeLabel = extractElement(Label, props.label, {
      text: props.label,
      isDisabled: props.isDisabled,
    });

    RWProps.onChange = !!props.onChange ? props.handleChange : undefined;
    RWProps.onSelect = !!props.onSelect ? props.handleSelect : undefined;

    logger.warn(
      'DateTimePicker: propTypes "isDisabled" and "hasError" both set to true, this is invalid, please choose one or the other.',
      props.isDisabled && props.hasError
    );

    logger.warn(
      'DateTimePicker: propType "timeZone" is invalid, please update or it will be defined as "GMT"',
      !moment.tz.zone(props.timeZone)
    );

    // RW expects Date objects
    if (!!props.value) {
      if (moment(props.value).isValid()) {
        const _value = userTimeZoneDate({ dateTime: props.value, timeZone: props.timeZone });
        RWProps.value = _value;
      } else {
        logger.warn('DateTimePicker: prop `value` is not a valid date/time format, please update');
        RWProps.value = undefined;
      }
    } else {
      RWProps.value = undefined;
    }

    if (!!RWProps.min) {
      if (moment(props.min).isValid()) {
        const _min = userTimeZoneDate({ dateTime: props.min, timeZone: props.timeZone });
        RWProps.min = _min;
      } else {
        logger.warn('DateTimePicker: prop `min` is not a valid date/time format, please update');
        RWProps.min = undefined;
      }
    } else {
      RWProps.min = undefined;
    }

    if (!!RWProps.max) {
      if (moment(props.max).isValid()) {
        const _max = userTimeZoneDate({ dateTime: props.max, timeZone: props.timeZone });
        RWProps.max = _max;
      } else {
        logger.warn('DateTimePicker: prop `max` is not a valid date/time format, please update');
        RWProps.max = undefined;
      }
    } else {
      RWProps.max = undefined;
    }

    if (!!RWProps.softMax) {
      logger.warn('DateTimePicker: prop `softMax` cannot be defined without `max`, please update', !RWProps.max);

      if (moment(props.softMax).isValid()) {
        const _softMax = userTimeZoneDate({ dateTime: props.softMax, timeZone: props.timeZone });
        RWProps.softMax = _softMax;
        logger.warn(
          'DateTimePicker: prop `softMax` cannot be after `max`, please update',
          moment(RWProps.softMax).isAfter(RWProps.max)
        );
      } else {
        logger.warn('DateTimePicker: prop `softMax` is not a valid date/time format, please update');
        RWProps.softMax = undefined;
      }
    } else {
      RWProps.softMax = undefined;
    }

    if (!!props.value && !props.onChange) {
      // RW DTP logs warning to use defaultValue when value is present and onChange handler is not
      RWProps.defaultValue = RWProps.value;
      RWProps.value = undefined;
    }

    // if a locale format is not displaying as you would expect, have a look at src/config.js - a locale may need to be added
    if (props.date && props.time) {
      // 10 September 1986 8:30 pm
      RWProps.format = props.format || 'LLL';
      RWProps.editFormat = props.editFormat || 'LLL';
    } else if (props.date) {
      // 10 September 1986
      RWProps.format = props.format || 'L';
      RWProps.editFormat = props.editFormat || 'L';
    } else {
      // 8:30 pm
      RWProps.format = props.timeFormat || props.format || 'LT';
      RWProps.editFormat = props.editFormat || 'LT';
    }

    RWProps.footer = false;
    RWProps.dateFormat = 'D';
    RWProps.disabled = props.isDisabled;
    RWProps.finalView = 'month';
    RWProps.initialView = 'month';

    const hasError = evalPredicate(props.hasError) || !!props.errorMessage;
    const isFieldInfoDisplayed = !!props.dstStatus && props.showDSTInfo; // if we have a dst warning AND dstWarning is enabled

    const wrapperClassName = classnames('ck-date-time-picker', {
      inverted: props.isInverted,
      fluid: props.isFluid,
      error: !!hasError,
      hasFieldInfo: !!isFieldInfoDisplayed,
      disabled: props.isDisabled,
    });

    const showClearIcon = props.showClearIcon && props.time && props.date && props.value;

    return (
      <div className={wrapperClassName} style={props.style}>
        <If condition={dateTimeLabel}>
          <div className="date-time-picker__label">{dateTimeLabel.element}</div>
        </If>
        <div className="error-wrapper">
          <If condition={props.time}>
            <div className={classnames('timezone-positioner', { 'clear-action-wrapper': showClearIcon })}>
              <RWDateTimePicker {...RWProps} />
              <If condition={showClearIcon}>
                <span className="clear-action">
                  <Button.Secondary
                    size={sizes.X5}
                    color={props.isInverted ? colors.WHITE : colors.BASE}
                    icon={{ content: icons.CANCEL, position: positions.LEFT }}
                    onClick={props.handleClear}
                    float={positions.RIGHT}
                    isCompact
                  />
                </span>
              </If>
              <span
                className={classnames('ck-time-zone', {
                  'has-calendar': props.date,
                  'has-clear-action': showClearIcon,
                })}
              >
                <Tooltip tooltip={`${props.timeZone || 'Europe/London'} time`}>
                  <Label
                    text={props.timeZoneAbbr}
                    style={{ padding: 0 }}
                    size={sizes.X5}
                    font={{
                      weight: font.weights.MEDIUM,
                    }}
                  />
                </Tooltip>
              </span>
            </div>
          </If>
          <If condition={!props.time}>
            <RWDateTimePicker {...RWProps} />
          </If>

          <div className="error-content">
            <ErrorIcon errorMessagePosition={props.errorMessagePosition} errorMessage={props.errorMessage} />
          </div>
        </div>

        <Animate.OnTransition
          transitionCondition={isFieldInfoDisplayed}
          truthyAnimation="fadeIn"
          falseyAnimation="fadeOut"
          runOnMount={false}
          duration={500}
          delay={250}
        >
          <div className="field-info">
            <Tooltip tooltip={DST_TOOLTIP} position={positions.TOP_LEFT}>
              <Label text={DST_LABEL} icon={icons.INFO} hasPadding />
            </Tooltip>
          </div>
        </Animate.OnTransition>
      </div>
    );
  },
});

// submitDateValue will return a sanitized and converted to UTC date
// This is used in the handleSelect and handleChange event handlers
const getSubmitValue = ({ dateTime, timeZone, submitFormat }) => {
  // convert the date the control gives us into GMT
  // note that the DTP always gives us a date object in the BROWSER LOCATION
  const _GMTFromBrowserDate = moment(dateTime).utc().format(submitFormat);
  const _StrippedGMTFromBrowserDate = moment(_GMTFromBrowserDate).format(STRIPPED_FORMAT);

  // now we have a GMT date based on the browser date, we have to account for the selected timeZone prop
  return moment.tz(_StrippedGMTFromBrowserDate, timeZone).utc().format(submitFormat);
};

// convert the passed in dateTime to the passed in timeZone
const convertToUserTZ = ({ dateTime, timeZone }) => {
  const GMTDateTime = moment.tz(dateTime, 'GMT');
  return moment(GMTDateTime.tz(timeZone).format('YYYY-MM-DDTHH:mm:ss')).toDate();
};

// Always returns a validated timeZone name or GMT
// If the passed in timezone is null, invalid or 'Coordinated Universal Time', fall back to GMT
const defaultTZName = ({ dateTime, timeZone }) => {
  const zone = moment.tz.zone(timeZone || 'GMT');

  return {
    dateTime,
    timeZone: !zone || zone.name === 'Coordinated Universal Time' ? 'GMT' : zone.name, // default to GMT for no timeZone, invalid timeZone or Coordinated Universal Time
  };
};

// get the timeZone abbrevation from the passed in dateTime and timeZone
const TZAbbr = ({ dateTime, timeZone }) => moment(dateTime).tz(timeZone).zoneName();

// return the passed in dateTime or, if null, the current date
const defaultDate = ({ dateTime, timeZone }) => ({
  dateTime: dateTime || moment.utc(new Date()).format('YYYY-MM-DD HH:mm:ss'),
  timeZone,
});

// get the userTimeZoneDate from the passed in date and timeZone, first sanitizing for the timeZone
const userTimeZoneDate = compose(convertToUserTZ, defaultTZName);

// get the sanitized timeZone abbreviation for the passed in timeZone
const getTimezoneAbbr = compose(TZAbbr, defaultTZName, defaultDate);

export const PrivateDateTimePicker = DateTimePicker;
export default compose(removeProps(['hasErrorIcon', 'finalView', 'initialView']), whitelistStyles())(DateTimePicker);
