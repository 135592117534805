import React from 'react';
import classnames from 'classnames';
import { createComponent, PropTypes } from 'wayin-react';
import { sizes, positions, animations, colors, font, icons } from 'enums';
import Button from 'components/core/button';
import Reveal from 'components/core/reveal';
import { PrivateHeader } from '../../core/header/header';
import { extractElement } from 'helpers';

const PanelTakeover = createComponent({
  displayName: 'PanelTakeover',
  propTypes: {
    launchText: PropTypes.string,
    launchIcon: PropTypes.oneOfType([
      PropTypes.element, //Icon only
      PropTypes.string,
      PropTypes.shape({
        content: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
        position: PropTypes.oneOf(positions.LR),
      }),
    ]),
    header: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
    children: PropTypes.node.isRequired,
    onClose: PropTypes.func,
    showTakeover: PropTypes.bool,
    onTakeover: PropTypes.func,
    animation: PropTypes.oneOf([animations.SLIDE_LEFT, animations.SLIDE_RIGHT]),
    hideLaunchButton: PropTypes.bool,
    doneButton: PropTypes.element, // <Button.__ />
    hasCancel: PropTypes.bool,
    hasHeaderBorder: PropTypes.bool,
  },
  defaultProps: {
    launchText: 'Customize',
    launchIcon: icons.SETTINGS,
    animation: animations.SLIDE_RIGHT,
    showTakeover: false,
    onTakeover: _.noop,
    hideLaunchButton: false,
    hasCancel: false,
    hasHeaderBorder: false,

    header: undefined,
    doneButton: undefined,
    onClose: undefined,
  },
  state: {
    isExpanded: {
      value: (p) => p.showTakeover,
      updater(setIsExpanded) {
        return {
          takeover: () => {
            setIsExpanded(true);
          },
          collapse: () => {
            setIsExpanded(false);
          },
        };
      },
    },
  },
  lifecycles: {
    UNSAFE_componentWillReceiveProps(nextProps) {
      //  Resetting isExpanded to false when previous 'showTakeover' is true and next 'showTakeover' is false
      if (!nextProps.showTakeover && this.props.showTakeover) {
        this.props.collapse();
      }
    },
  },
  handlers: {
    handleSave: (p) => (e) => {
      p.collapse();
      p.doneButton.props.onClick();
    },
    handleClose: (p) => (e) => {
      p.collapse();
      if (p.onClose) p.onClose();
    },
    handleTakeover: (p) => (e) => {
      p.onTakeover();
      p.takeover();
    },
  },
  render(props) {
    // reassign onClick of doneButton to insure takeover panel gets closed
    let doneButton;
    if (props.doneButton) doneButton = React.cloneElement(props.doneButton, { onClick: props.handleSave });
    const headerClassName = classnames('ck-takeover-panel-heading', { bordered: props.hasHeaderBorder });
    return (
      <div className="ck-takeover-panel">
        <If condition={!props.hideLaunchButton}>
          <Button.Primary text={props.launchText} onClick={props.handleTakeover} icon={props.launchIcon} />
        </If>
        <Reveal animation={props.animation} isActive={props.showTakeover || props.isExpanded}>
          <Reveal.Content whenIsActive={true}>
            <Choose>
              <When condition={React.isValidElement(props.header)}>
                {props.header}
              </When>
              <Otherwise>
                <If condition={props.header}>
                  <div className={headerClassName}>
                    <PrivateHeader
                      color={colors.ACCENT}
                      text={props.header}
                      font={{
                        family: font.families.CUSTOM,
                        weight: font.weights.LIGHT,
                        emphasis: font.emphasis.NORMAL,
                      }}
                      size={sizes.X7}
                    />

                    <Button.Secondary
                      icon={icons.CANCEL}
                      float={positions.RIGHT}
                      onClick={props.handleClose}
                      size={sizes.X7}
                      isCompact
                    />
                  </div>
                </If>
              </Otherwise>
            </Choose>

            {props.children}
            <div className="ck-takeover-panel-footer">
              <Button.Group float={positions.RIGHT} style={{ marginRight: '40px' }}>
                <If condition={props.hasCancel}>
                  <Button.Secondary text="Cancel" onClick={props.handleClose} />
                </If>
                <If condition={props.doneButton} >
                  {doneButton}
                </If>
              </Button.Group>
            </div>
          </Reveal.Content>
        </Reveal>
      </div>
    );
  },
});

export default PanelTakeover;