import React from 'react';

import { createComponent, PropTypes } from 'wayin-react';
import { whitelistStyles } from 'components/core/hoc';
import {
  isYoutubeVideo,
  isVimeoVideo,
  isInstagramVideo,
  isVineVideo,
  isTwitterVideo,
  isFacebookVideo,
  isMuxVideo,
} from 'helpers';
import { videoServices } from 'enums';
import YoutubeVideo from './youtube-video';
import InstagramVideo from './instagram-video';
import VineVideo from './vine-video';
import VideoGif from './video-gif';
import TwitterVideo from './twitter-video';
import VimeoVideo from './vimeo-video';
import FacebookVideo from './facebook-video';
import MuxVideo from './mux-video';

const Video = createComponent({
  displayName: 'Video',

  defaultProps: {
    src:      null,
    service:  null,
    id:       null,
    type:     null,
    width:    undefined,
    settings: {},
    status:   undefined,
    poster:   undefined,
  },

  propTypes: {
    src:      PropTypes.string,
    service:  PropTypes.string,
    id:       PropTypes.string,
    type:     PropTypes.string,
    width:    PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    settings: PropTypes.object,
    status:   PropTypes.string,
    poster:   PropTypes.string,

    customVideoCheck: () => ({ src, service, id }) => {
      if (service || id) {
        if (src) {
          return new Error('Cannot use src and service props at the same time');
        }

        if (!(service && id)) {
          return new Error('Cannot use service without id');
        }
      } else if (!src) {
        return new Error('Video needs to be called with service or src');
      }
    },
  },

  render({ src, service, type, id, width, style, settings, status, poster }) {
    const videoStyle = {
      ...style,
      width,
    };

    logger.warn(
      'Video: setting a "status" or "poster" attribute for non-mux videos is not currently supported.',
      (!!status || !!poster) && (service !== videoServices.MUX && !isMuxVideo(src))
    );

    return (
      <div style={videoStyle}>
        <Choose>
          <When condition={service === videoServices.YOUTUBE || isYoutubeVideo(src)}>
            <YoutubeVideo src={src} id={id} />
          </When>
          <When condition={service === videoServices.VIMEO || isVimeoVideo(src)}>
            <VimeoVideo src={src} id={id} />
          </When>
          <When condition={service === videoServices.INSTAGRAM || isInstagramVideo(src)}>
            <InstagramVideo src={src} id={id} />
          </When>
          <When condition={service === videoServices.VINE || isVineVideo(src)}>
            <VineVideo src={src} id={id} />
          </When>
          <When condition={service === videoServices.TWITTER || isTwitterVideo(src)}>
            <TwitterVideo src={src} id={id} />
          </When>
          <When condition={service === videoServices.FACEBOOK || isFacebookVideo(src)}>
            <FacebookVideo src={src} id={id} />
          </When>
          <When condition={service === videoServices.MUX || isMuxVideo(src)}>
            <MuxVideo src={src} id={id} width={width} settings={settings} status={status} poster={poster} />
          </When>
          <When condition={type === videoServices.GIF}>
            <VideoGif src={src} width={width} />
          </When>
          <Otherwise>
            <video controls src={src} width={width} />
          </Otherwise>
        </Choose>
      </div>
    );
  },
});

export default whitelistStyles(['width'])(Video);
