import React from 'react';
import { createComponent, PropTypes } from 'wayin-react';
import classnames from 'classnames';
import { toUIProps } from 'helpers';
import { font } from 'enums';

import { Header as UIHeader } from 'semantic-ui-react';

const UNSUPPORTED_PROPS = ['as', 'className'];

const MAPPED_PROPS = [];
const ADDED_PROPS = ['font'];
const MODIFIED_PROPS = [];

const HeaderContent = createComponent({
  displayName: 'HeaderContent',
  parent:      'Header',
  propTypes:   {
    children: PropTypes.node,
    font:     PropTypes.shape({
      emphasis: PropTypes.oneOf(font.emphasis.ALL),
    }),
  },
  defaultProps: {
    children: null,
    font:     {
      emphasis: font.emphasis.CAPS,
    },
  },

  render(props) {
    const UIProps = toUIProps({
               props,
      propMap: MAPPED_PROPS,
               UNSUPPORTED_PROPS,
               ADDED_PROPS,
               MODIFIED_PROPS,
    });

    UIProps.className = classnames({
      'font-caps': props.font.emphasis === font.emphasis.CAPS,
    });

    return (
      <UIHeader.Content {...UIProps}>
        {props.children}
      </UIHeader.Content>
    );
  },
});

export default HeaderContent;
