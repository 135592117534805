import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import cx from 'classnames';

import Animate from '../animate';

import Button from 'components/core/button';
import Icon from 'components/core/icon';

import WizardCrumb from './wizard-crumb';

import { icons, sizes } from 'enums';

class Wizard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeStepIndex: 0,
      isNextDisabled: true,
      isPreviousDisabled: true,
      crumbPositions: [],
      movingBack: false,
    };
    this.moveTo = this.moveTo.bind(this);
    this.moveFirst = this.moveFirst.bind(this);
    this.moveLast = this.moveLast.bind(this);
    this.moveNext = this.moveNext.bind(this);
    this.movePrevious = this.movePrevious.bind(this);
    this.getCrumbPositions = this.getCrumbPositions.bind(this);
    this.handleWindowResize = _.debounce(this.handleWindowResize.bind(this), 200);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleWindowResize);
  }

  componentDidMount() {
    this.moveTo(0);
    this.getCrumbPositions();
    window.addEventListener('resize', this.handleWindowResize);
  }

  handleWindowResize() {
    this.getCrumbPositions();
  }

  getCrumbPositions() {
    this.setState(() => ({
      crumbPositions: this.props.crumbs === true ? _.map(this.crumbWrapper.children, (c) => c.offsetLeft) : [],
    }));
  }

  // are we allowed to move forward?
  canProceed(movingBack) {
    this.setState(() => ({
      movingBack,
    }));

    return true;
  }

  // this should allow us to do async validation later...in theory
  abstractCanProceedToPromise(movingBack) {
    return Promise.resolve(this.canProceed(movingBack));
  }

  // update the currently active step on state
  updateActiveStep(step = 0) {
    this.setState(() => ({
      activeStepIndex: step,
    }));
  }

  // update the navigation button state
  updateNavState(step) {
    this.setState(() => ({
      isNextDisabled: step === this.props.steps.length - 1,
      isPreviousDisabled: step === 0,
    }));
  }

  // move to a passed step.
  move(to, movingBack) {
    this.abstractCanProceedToPromise(movingBack).then((proceed = true) => {
      if (proceed) {
        this.updateActiveStep(to);
        this.updateNavState(to);
      }
    });
  }

  moveTo(to) {
    this.move(to, to < this.state.activeStepIndex);
  }

  moveFirst() {
    this.move(0, true);
  }

  moveLast() {
    this.move(this.props.steps.length - 1);
  }

  moveNext() {
    this.move(this.state.activeStepIndex + 1);
  }

  movePrevious() {
    this.move(this.state.activeStepIndex - 1, true);
  }

  // build the animation config based on the direction of travel
  getAnimationConfig() {
    return {
      enterAnimation: {
        animation: `transition.slide${this.state.movingBack ? 'Left' : 'Right'}BigIn`,
        duration: 250,
        delay: 300,
      },
      leaveAnimation: {
        animation: `transition.slide${this.state.movingBack ? 'Right' : 'Left'}BigOut`,
        duration: 250,
      },
    };
  }

  render() {
    const { activeStepIndex, isPreviousDisabled, isNextDisabled } = this.state;
    const { steps, showNav } = this.props;
    const activeStep = steps[activeStepIndex];

    const _cls = cx('ck wizard-ui', {
      hasNav: showNav,
      hasToolbar: this.props.toolbar,
      inverted: this.context.isInverted,
    });

    const { enterAnimation, leaveAnimation } = this.getAnimationConfig();

    return (
      <div className={_cls}>
        <If condition={this.props.toolbar}>
          <div className="wizard-ui-toolbar">{this.props.toolbar}</div>
        </If>
        <div className="wizard-ui-body">
          <If condition={showNav}>
            <div className="wizard-ui-navigation wizard-ui-navigation__button-previous">
              <Button.Secondary
                isCompact
                onClick={this.movePrevious}
                isDisabled={isPreviousDisabled}
                icon={<Icon name={icons.ARROW_PREVIOUS} size={sizes.X8} />}
              />
            </div>
          </If>
          <div className="wizard-ui-step__wrapper">
            <Animate.OnMountChange
              className="wizard-ui-step__inner"
              onMountAnimation={enterAnimation}
              onUnmountAnimation={leaveAnimation}
            >
              {React.cloneElement(
                activeStep({
                  moveTo: this.moveTo,
                  moveNext: this.moveNext,
                  movePrevious: this.movePrevious,
                  moveFirst: this.moveFirst,
                  moveLast: this.moveLast,
                }),
                { key: activeStepIndex }
              )}
            </Animate.OnMountChange>
          </div>
          <If condition={showNav}>
            <div className="wizard-ui-navigation wizard-ui-navigation__button-next">
              <Button.Secondary
                isCompact
                onClick={this.moveNext}
                isDisabled={isNextDisabled}
                icon={<Icon name={icons.ARROW_NEXT} size={sizes.X8} />}
              />
            </div>
          </If>
        </div>
        <Choose>
          <When condition={React.isValidElement(this.props.crumbs)}>
            {React.cloneElement(this.props.crumbs, { activeStepIndex })}
          </When>
          <When condition={!!this.props.crumbs}>
            <div className="wizard-ui-crumbs">
              <div
                className="wizard-ui-crumbs__crumb-current"
                style={{
                  left: this.state.crumbPositions[activeStepIndex] || 0,
                }}
              >
                <Icon name={icons.PACMAN} size={sizes.X6} />
              </div>
              <div
                className="wizard-ui-crumbs__inner"
                ref={(crumbWrapper) => {
                  this.crumbWrapper = crumbWrapper;
                }}
              >
                <For each="step" of={steps} index="idx">
                  <WizardCrumb key={idx} index={idx} active={activeStepIndex === idx} hidden={activeStepIndex >= idx} />
                </For>
              </div>
            </div>
          </When>
        </Choose>
      </div>
    );
  }
}

Wizard.displayName = 'Wizard';
Wizard.propTypes = {
  showNav: PropTypes.bool,
  steps: PropTypes.arrayOf(PropTypes.func).isRequired,
  toolbar: PropTypes.element,
  crumbs: PropTypes.oneOfType([PropTypes.element, PropTypes.bool]),
};

Wizard.defaultProps = {
  showNav: true,
  crumbs: true,
};

Wizard.contextTypes = {
  isInverted: PropTypes.bool,
};

export default Wizard;

/*
 TODO:
 - can we have an invalid class on the Wizard.Step so we can style there too?
 */
