import React from 'react';
import { Embed } from 'semantic-ui-react';

import { createComponent } from 'wayin-react';
import { getVimeoId } from 'helpers';

const VimeoVideo = createComponent({
  displayName: 'VimeoVideo',

  render({ src, id }) {
    if (!id) {
      id = getVimeoId(src);
    }

    return <Embed id={id} source="vimeo" active autoplay={false} />;
  },
});

export default VimeoVideo;
