import React from "react";
import PropTypes from "prop-types";

import { createComponent, PropTypes as CKPropTypes } from "wayin-react";
import { withContext } from "recompose";

const InvertedProvider = createComponent({
  displayName: "InvertedProvider",
  propTypes: {
    isInverted: CKPropTypes.bool.isRequired
  },
  render(props) {
    return React.Children.only(props.children);
  }
});

export default withContext(
  // Intentionally using React's PropTypes here because won't get run through our factory
  { isInverted: PropTypes.bool.isRequired },
  ({ isInverted }) => ({ isInverted })
)(InvertedProvider);
